import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as BurstActions from './burst.actions';
import { SiteService } from '../../../../shared/service/filter/site.service';
import { LineService } from '../../../../shared/service/line/line.service';
import * as oeeAppReducer from '../../../oee.reducer';
import { ErrorMessageService } from '../../../../shared/service/error-message.service';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import * as AppActions from '../../../app/actions';
import { BurstService } from './burst.service';
import { LineCRUDInterface } from '../../../../shared/component/filter/filter.class';
import { IBurst } from '../../../../view/settings/opc-tag-automation-settings/burst/burst.model';

@Injectable()
export class BurstEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: BurstService,
    private readonly siteService: SiteService,
    private readonly lineService: LineService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly errorMessageService: ErrorMessageService,
  ) {}

  getDataForDatatable = createEffect(() =>
    this.actions$.pipe(
      ofType(BurstActions.ActionTypes.BURST_DATA_LOADING),
      switchMap((payload: BurstActions.BurstLoading) => {
        let httpParams: HttpParams = new HttpParams()
          .append('sort', 'id,DESC')
          .append('limit', String(payload.params.perPage || 1000))
          .append('page', String(payload.params.page));

        if (payload.params.siteIds && payload.params.siteIds.length > 0) {
          httpParams = httpParams.append('siteId', payload.params.siteIds.join(','));
        }

        if (payload.params.lineIds && payload.params.lineIds.length > 0) {
          httpParams = httpParams.append('lineId', payload.params.lineIds.join(','));
        }

        const searchParamObject = {
          $and: [
            {
              $or: [
                {
                  oldDataInMinutes: {
                    $cont: `${payload.params?.searchText ?? ''}`,
                  },
                },
              ],
            },
          ],
        };

        httpParams = httpParams.set('s', JSON.stringify(searchParamObject));

        if (payload.params.orderBy) {
          httpParams = httpParams.set('sort', `${payload.params.orderBy},${payload.params.orderDesc}`);
        }

        return this.service.getData(httpParams).pipe(
          switchMap((response) => {
            return of(new BurstActions.BurstLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new BurstActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new BurstActions.FetchError(errorRes));
      }),
    ),
  );

  getOne = createEffect(() =>
    this.actions$.pipe(
      ofType(BurstActions.ActionTypes.GET_BURST),
      switchMap((objectData: BurstActions.GetBurst) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getOne(objectData.recordId, new HttpParams()).pipe(
          switchMap((response: BaseOneResponseInterface<IBurst>) => {
            return of(new BurstActions.GetBurstCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new BurstActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new BurstActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  addOne = createEffect(() =>
    this.actions$.pipe(
      ofType(BurstActions.ActionTypes.ADD_BURST),
      switchMap((objectData: BurstActions.AddBurst) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addOne(objectData.burst).pipe(
          switchMap((response: BaseOneResponseInterface<IBurst>) => {
            const actions = [
              new BurstActions.AddBurstCompleted(response),
              ...(objectData.hideLoaderAfterSuccess ? [new AppActions.HideLoader()] : []),
            ];

            return of(...actions);
          }),
          catchError((error) => {
            return of(new BurstActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new BurstActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  editOne = createEffect(() =>
    this.actions$.pipe(
      ofType(BurstActions.ActionTypes.EDIT_BURST),
      switchMap((objectData: BurstActions.EditBurst) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editOne(objectData.recordId, objectData.record).pipe(
          switchMap((response: BaseOneResponseInterface<IBurst>) => {
            const actions = [
              new BurstActions.EditBurstCompleted(response),
              ...(objectData.hideLoaderAfterSuccess ? [new AppActions.HideLoader()] : []),
            ];

            return of(...actions);
          }),
          catchError((error) => {
            return of(new BurstActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new BurstActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  deleteOnes = createEffect(() =>
    this.actions$.pipe(
      ofType(BurstActions.ActionTypes.DELETE_BURST),
      switchMap((objectData: BurstActions.DeleteBurst) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.deleteOnes(objectData.payload).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            this.errorMessageService.getTranslatedErrorMessage(response.data);
            return of(
              new BurstActions.DeleteBurstCompleted(objectData.payload.length > 1, response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new BurstActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new BurstActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getSiteData = createEffect(() =>
    this.actions$.pipe(
      ofType(BurstActions.ActionTypes.SITES_DATA_LOADING),
      switchMap((objectData: BurstActions.SiteDataLoading) => {
        const httpParams: HttpParams = new HttpParams().set('limit', 1000);

        return from(this.siteService.getData(httpParams)).pipe(
          switchMap((response) => {
            return of(new BurstActions.SiteDataLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new BurstActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new BurstActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getLines = createEffect(() =>
    this.actions$.pipe(
      ofType(BurstActions.ActionTypes.LINES_DATA_LOADING),
      switchMap((objectData: BurstActions.LineDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const search = {
          statusId: 1,
          siteId: objectData.siteId,
        };
        const httpParams = new HttpParams()
          .set('s', JSON.stringify(search))
          .set('limit', '1000')
          .set('join', 'lineTypeName');

        return this.lineService.getLines(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<Partial<LineCRUDInterface>>) => {
            return of(new BurstActions.LineDataCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new BurstActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new BurstActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
