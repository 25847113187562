import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { combineLatest, forkJoin, Observable } from 'rxjs';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { exhaustMap, map } from 'rxjs/operators';
import { LineCRUDInterface, SiteCRUDInterface } from '../../../../shared/component/filter/filter.class';
import { SiteService } from '../../../../shared/service/filter/site.service';
import { LineService } from '../../../../shared/service/line/line.service';
import { IChangeStationActivityRow } from '../../../../view/settings/opc-tag-automation-settings/change-station-activity/change-station-activity.model';
import { ResponseArrayInterface } from '../../../../shared/model/interface/generic-api-response.model';
import { LineStationGetOneCRUDDataInterface } from '../../../line-station/line-station.model';
import { LineStationService } from '../../../../shared/service/line-station/line-station.service';

@Injectable({
  providedIn: 'root',
})
export class ChangeStationActivityService {
  private readonly routes = {
    changeStationActivity: `${this.baseUrl}/opc-tag-automation-settings/change-stationactivity`,
  };

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly siteService: SiteService,
    private readonly lineService: LineService,
    private readonly stationService: LineStationService,
  ) {}

  public getData(params: HttpParams): Observable<GetManyResponseInterface<IChangeStationActivityRow[]>> {
    return this.http.get<GetManyResponseInterface<IChangeStationActivityRow[]>>(this.routes.changeStationActivity, {
      params,
    });
  }
  public getOneWithDropdowns(
    recordId: number,
    params?: HttpParams,
  ): Observable<{
    changeStationActivityRecord: BaseOneResponseInterface<IChangeStationActivityRow>;
    sites: SiteCRUDInterface[];
    lines: GetManyResponseInterface<LineCRUDInterface>;
    stations: ResponseArrayInterface<LineStationGetOneCRUDDataInterface>;
  }> {
    return combineLatest([
      this.http.get<BaseOneResponseInterface<IChangeStationActivityRow>>(
        `${this.routes.changeStationActivity}/${recordId}`,
        {
          params,
        },
      ),
    ]).pipe(
      exhaustMap(([changeStationActivityRecord]) => {
        const siteHttpParams: HttpParams = new HttpParams().set('limit', 1000);

        const lineSearch = {
          statusId: 1,
          siteId: changeStationActivityRecord['data']['siteId'],
        };
        const lineHttpParams = new HttpParams()
          .set('s', JSON.stringify(lineSearch))
          .set('limit', '1000')
          .set('join', 'lineTypeName');

        return combineLatest([
          this.siteService.getData(siteHttpParams),
          this.lineService.getLines(lineHttpParams),
          this.stationService.getLineStationData(changeStationActivityRecord['data']['lineId']),
        ]).pipe(
          map(([siteRecords, lineRecords, stationsRecords]) => {
            return {
              changeStationActivityRecord: changeStationActivityRecord,
              sites: siteRecords,
              lines: lineRecords,
              stations: stationsRecords,
            };
          }),
        );
      }),
    );
  }

  public getOne(
    recordId: number,
    params?: HttpParams,
  ): Observable<BaseOneResponseInterface<IChangeStationActivityRow>> {
    return this.http.get<BaseOneResponseInterface<IChangeStationActivityRow>>(
      `${this.routes.changeStationActivity}/${recordId}`,
      {
        params,
      },
    );
  }

  public addOne(record: IChangeStationActivityRow): Observable<BaseOneResponseInterface<IChangeStationActivityRow>> {
    return this.http.post<BaseOneResponseInterface<IChangeStationActivityRow>>(
      `${this.routes.changeStationActivity}`,
      record,
    );
  }

  public editOne(
    recordId: number,
    record: IChangeStationActivityRow,
  ): Observable<BaseOneResponseInterface<IChangeStationActivityRow>> {
    return this.http.patch<BaseOneResponseInterface<IChangeStationActivityRow>>(
      `${this.routes.changeStationActivity}/${recordId}`,
      record,
    );
  }

  public deleteOnes(recordIds: number[]): Observable<BulkResponseDataInterface> {
    if (recordIds.length > 1) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        body: {
          changeStationActivityIds: recordIds,
        },
      };
      return this.http.delete<BulkResponseDataInterface>(
        `${this.routes.changeStationActivity}/bulk/delete`,
        httpOptions,
      );
    }
    return this.http.delete<BulkResponseDataInterface>(`${this.routes.changeStationActivity}/${recordIds[0]}`);
  }
}
