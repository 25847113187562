import { UserSettingsStateInterface } from './users.model';
import * as Actions from './users.actions';

export const userSettingState: UserSettingsStateInterface = {
  userDataLoading: false,
  userDataLoaded: false,
  userDataForDatatableLoading: false,
  userDataForDatatableLoaded: false,
  usersSiteDataLoading: false,
  usersSiteDataLoaded: false,
  usersLineDataLoading: false,
  usersLineDataLoaded: false,
  usersAnonymousCardDataLoading: false,
  usersAnonymousCardDataLoaded: false,
  languageDataLoading: false,
  languageDataLoaded: false,
  siteData: [],
  lineData: [],
  anonymousCardData: null,
  userData: null,
  usersData: null,
  languageData: null,
  fileDownloadStatus: false,
  excelUploadResult: [],
  isUploadExcelLoading: false,
  isUploadExcelLoaded: false,
  addUserLoading: false,
  addUserLoaded: false,
  editUserLoading: false,
  editUserLoaded: false,
  bulkEditUserLoading: false,
  bulkEditUserLoaded: false,
  filters: null,
  tableSettings: [],
  getOngoingUserCheckinsAndActivityInLineLoading: false,
  getOngoingUserCheckinsAndActivityInLineLoaded: false,
};

export function userSettingReducer(
  state: UserSettingsStateInterface = userSettingState,
  action: Actions.UserSettingsActions,
): UserSettingsStateInterface {
  switch (action.type) {
    case Actions.USERS_DATA_LOADING:
      return {
        ...state,
        ...{
          userDataForDatatableLoading: false,
          userDataForDatatableLoaded: false,
          userDataLoading: true,
          userDataLoaded: false,
        },
      };
    case Actions.USERS_DATA_LOADED:
      return {
        ...state,
        ...{
          userData: action.payload,
          userDataLoading: false,
          userDataLoaded: true,
        },
      };
    case Actions.USERS_DATA_FOR_DATATABLE_LOADING:
      return {
        ...state,
        ...{
          userDataForDatatableLoading: true,
          userDataForDatatableLoaded: false,
        },
      };
    case Actions.USERS_DATA_FOR_DATATABLE_LOADED:
      return {
        ...state,
        ...{
          usersData: action.payload,
          userDataForDatatableLoading: false,
          userDataForDatatableLoaded: true,
        },
      };
    case Actions.SET_TABLE_SETTINGS:
      return {
        ...state,
        userDataForDatatableLoading: false,
        userDataForDatatableLoaded: true,
        tableSettings: action.payload,
      };
    case Actions.USERS_SITES_DATA_LOADING:
      return {
        ...state,
        ...{
          usersSiteDataLoading: true,
          usersSiteDataLoaded: false,
        },
      };
    case Actions.USERS_SITES_DATA_LOADED:
      return {
        ...state,
        ...{
          siteData: action.payload,
          usersSiteDataLoading: false,
          usersSiteDataLoaded: true,
        },
      };
    case Actions.USERS_LINES_DATA_LOADING:
      return {
        ...state,
        ...{
          userDataForDatatableLoading: false,
          userDataForDatatableLoaded: false,
          usersLineDataLoading: true,
          usersLineDataLoaded: false,
        },
      };
    case Actions.USERS_LINES_DATA_LOADED:
      return {
        ...state,
        ...{
          lineData: action.payload,
          usersLineDataLoading: false,
          usersLineDataLoaded: true,
        },
      };
    case Actions.DOWNLOAD_USERS_EXCEL:
      return {
        ...state,
        ...{
          fileDownloadStatus: true,
        },
      };
    case Actions.DOWNLOAD_USERS_EXCEL_COMPLETED:
      return {
        ...state,
        ...{
          fileDownloadStatus: false,
        },
      };
    case Actions.UPLOAD_USER_EXCEL:
      return {
        ...state,
        ...{
          isUploadExcelLoading: true,
          isUploadExcelLoaded: false,
        },
      };
    case Actions.UPLOAD_USER_EXCEL_COMPLETED:
      return {
        ...state,
        ...{
          excelUploadResult: action.payload,
          isUploadExcelLoading: false,
          isUploadExcelLoaded: true,
        },
      };
    case Actions.ADD_USER:
      return {
        ...state,
        addUserLoading: true,
        addUserLoaded: false,
      };
    case Actions.ADD_USER_COMPLETED:
      return {
        ...state,
        addUserLoading: false,
        addUserLoaded: true,
      };
    case Actions.EDIT_USER:
      return {
        ...state,
        editUserLoading: true,
        editUserLoaded: false,
      };
    case Actions.EDIT_USER_COMPLETED:
      return {
        ...state,
        editUserLoading: false,
        editUserLoaded: true,
      };
    case Actions.BULK_EDIT_USER:
      return {
        ...state,
        bulkEditUserLoading: true,
        bulkEditUserLoaded: false,
      };
    case Actions.BULK_EDIT_USER_COMPLETED:
      return {
        ...state,
        bulkEditUserLoading: false,
        bulkEditUserLoaded: true,
      };
    case Actions.SET_SELECTED_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };
    case Actions.GET_LANGUAGES:
      return {
        ...state,
        ...{
          languageDataLoading: true,
          languageDataLoaded: false,
        },
      };
    case Actions.GET_LANGUAGES_COMPLETED:
      return {
        ...state,
        ...{
          languageData: action.payload,
          languageDataLoading: false,
          languageDataLoaded: true,
        },
      };
    case Actions.GET_ONGOING_USER_CHECKINS_AND_ACTIVITY_IN_LINE_LOADING:
      return {
        ...state,
        getOngoingUserCheckinsAndActivityInLineLoading: true,
        getOngoingUserCheckinsAndActivityInLineLoaded: false,
      };
    case Actions.GET_ONGOING_USER_CHECKINS_AND_ACTIVITY_IN_LINE_LOADED:
      return {
        ...state,
        getOngoingUserCheckinsAndActivityInLineLoading: false,
        getOngoingUserCheckinsAndActivityInLineLoaded: true,
      };
    default:
      return state;
  }
}
