import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as TagDefinitionActions from './tag-definition.actions';
import { SiteService } from '../../../../shared/service/filter/site.service';
import { LineService } from '../../../../shared/service/line/line.service';
import * as oeeAppReducer from '../../../oee.reducer';
import { ErrorMessageService } from '../../../../shared/service/error-message.service';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import * as AppActions from '../../../app/actions';
import { TagDefinitionService } from './tag-definition.service';
import { LineCRUDInterface, SiteCRUDInterface } from '../../../../shared/component/filter/filter.class';
import { ITagDefinition } from '../../../../view/settings/opc-tag-automation-settings/tag-definitions/tag-definitions.model';
import { ResponseArrayInterface } from '../../../../shared/model/interface/generic-api-response.model';
import { LineStationGetOneCRUDDataInterface } from '../../../line-station/line-station.model';
import { LineStationService } from '../../../../shared/service/line-station/line-station.service';
import { IChangeStationActivityRow } from '../../../../view/settings/opc-tag-automation-settings/change-station-activity/change-station-activity.model';

@Injectable()
export class TagDefinitionEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: TagDefinitionService,
    private readonly siteService: SiteService,
    private readonly lineService: LineService,
    private readonly stationService: LineStationService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly errorMessageService: ErrorMessageService,
  ) {}

  getDataForDatatable = createEffect(() =>
    this.actions$.pipe(
      ofType(TagDefinitionActions.ActionTypes.TAG_DEFINITIONS_DATA_LOADING),
      switchMap((payload: TagDefinitionActions.TagDefinitionsLoading) => {
        let httpParams: HttpParams = new HttpParams()
          .append('sort', 'id,DESC')
          .append('limit', String(payload.params.perPage || 1000))
          .append('page', String(payload.params.page));

        if (payload.params.siteIds && payload.params.siteIds.length > 0) {
          httpParams = httpParams.append('siteId', payload.params.siteIds.join(','));
        }

        if (payload.params.lineIds && payload.params.lineIds.length > 0) {
          httpParams = httpParams.append('lineId', payload.params.lineIds.join(','));
        }

        const searchParamObject = {
          $and: [
            {
              $or: [
                {
                  tagName: {
                    $cont: `${payload.params?.searchText ?? ''}`,
                  },
                },
                {
                  description: {
                    $cont: `${payload.params?.searchText ?? ''}`,
                  },
                },
                {
                  valueOperator: {
                    $cont: `${payload.params?.searchText ?? ''}`,
                  },
                },
                {
                  tagType: {
                    $cont: `${payload.params?.searchText ?? ''}`,
                  },
                },
                {
                  overriddenState: {
                    $cont: `${payload.params?.searchText ?? ''}`,
                  },
                },
              ],
            },
          ],
        };

        httpParams = httpParams.set('s', JSON.stringify(searchParamObject));

        if (payload.params.orderBy) {
          httpParams = httpParams.set('sort', `${payload.params.orderBy},${payload.params.orderDesc}`);
        }

        return this.service.getData(httpParams).pipe(
          switchMap((response) => {
            return of(new TagDefinitionActions.TagDefinitionsLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new TagDefinitionActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new TagDefinitionActions.FetchError(errorRes));
      }),
    ),
  );

  getOne = createEffect(() =>
    this.actions$.pipe(
      ofType(TagDefinitionActions.ActionTypes.GET_TAG_DEFINITION),
      switchMap((objectData: TagDefinitionActions.GetTagDefinition) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getOneWithDropdowns(objectData.recordId, new HttpParams()).pipe(
          switchMap(
            (response: {
              tagDefinitionRecord: BaseOneResponseInterface<ITagDefinition>;
              lines: GetManyResponseInterface<LineCRUDInterface>;
              sites: SiteCRUDInterface[];
              stations: ResponseArrayInterface<LineStationGetOneCRUDDataInterface>;
            }) => {
              return of(
                new TagDefinitionActions.SiteDataLoaded(response.sites),
                new TagDefinitionActions.LineDataCompleted(response.lines),
                new TagDefinitionActions.StationsDataCompleted(response.stations),
                new TagDefinitionActions.GetTagDefinitionCompleted(response.tagDefinitionRecord),
                new AppActions.HideLoader(),
              );
            },
          ),
        );
      }),
      catchError((error) => {
        return of(new TagDefinitionActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  addOne = createEffect(() =>
    this.actions$.pipe(
      ofType(TagDefinitionActions.ActionTypes.ADD_TAG_DEFINITION),
      switchMap((objectData: TagDefinitionActions.AddTagDefinition) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addOne(objectData.tagDefinition).pipe(
          switchMap((response: BaseOneResponseInterface<ITagDefinition>) => {
            const actions = [
              new TagDefinitionActions.AddTagDefinitionCompleted(response),
              ...(objectData.hideLoaderAfterSuccess ? [new AppActions.HideLoader()] : []),
            ];

            return of(...actions);
          }),
          catchError((error) => {
            return of(new TagDefinitionActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new TagDefinitionActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  editOne = createEffect(() =>
    this.actions$.pipe(
      ofType(TagDefinitionActions.ActionTypes.EDIT_TAG_DEFINITION),
      switchMap((objectData: TagDefinitionActions.EditTagDefinition) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editOne(objectData.recordId, objectData.record).pipe(
          switchMap((response: BaseOneResponseInterface<ITagDefinition>) => {
            const actions = [
              new TagDefinitionActions.EditTagDefinitionCompleted(response),
              ...(objectData.hideLoaderAfterSuccess ? [new AppActions.HideLoader()] : []),
            ];

            return of(...actions);
          }),
          catchError((error) => {
            return of(new TagDefinitionActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new TagDefinitionActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  deleteOnes = createEffect(() =>
    this.actions$.pipe(
      ofType(TagDefinitionActions.ActionTypes.DELETE_TAG_DEFINITIONS),
      switchMap((objectData: TagDefinitionActions.DeleteTagDefinitions) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.deleteOnes(objectData.payload).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            this.errorMessageService.getTranslatedErrorMessage(response.data);
            return of(
              new TagDefinitionActions.DeleteTagDefinitionsCompleted(objectData.payload.length > 1, response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new TagDefinitionActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new TagDefinitionActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getSiteData = createEffect(() =>
    this.actions$.pipe(
      ofType(TagDefinitionActions.ActionTypes.SITES_DATA_LOADING),
      switchMap((objectData: TagDefinitionActions.SiteDataLoading) => {
        const httpParams: HttpParams = new HttpParams().set('limit', 1000);

        return from(this.siteService.getData(httpParams)).pipe(
          switchMap((response) => {
            return of(new TagDefinitionActions.SiteDataLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new TagDefinitionActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new TagDefinitionActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getLines = createEffect(() =>
    this.actions$.pipe(
      ofType(TagDefinitionActions.ActionTypes.LINES_DATA_LOADING),
      switchMap((objectData: TagDefinitionActions.LineDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const search = {
          statusId: 1,
          siteId: objectData.siteId,
        };
        const httpParams = new HttpParams()
          .set('s', JSON.stringify(search))
          .set('limit', '1000')
          .set('join', 'lineTypeName');

        return this.lineService.getLines(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<Partial<LineCRUDInterface>>) => {
            return of(new TagDefinitionActions.LineDataCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new TagDefinitionActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new TagDefinitionActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
  getStations = createEffect(() =>
    this.actions$.pipe(
      ofType(TagDefinitionActions.ActionTypes.STATIONS_DATA_LOADING),
      switchMap((objectData: TagDefinitionActions.StationsDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.stationService.getLineStationData(objectData.lineId)).pipe(
          switchMap((response: ResponseArrayInterface<LineStationGetOneCRUDDataInterface>) => {
            return of(new TagDefinitionActions.StationsDataCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new TagDefinitionActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new TagDefinitionActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
