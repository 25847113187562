import {
  ISelectGeneric,
  ScwMatRule,
  ScwMatSelectRule,
} from '../../../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { ScwMatInputRule } from '../../../../shared/component/scw-mat-ui/scw-mat-input/scw-mat-input.model';
import { IFormRule, ISelectForm } from '../../../../shared/model/interface/scw-form.model';
import { IDropdownOptionsWithStringId } from '../home-page-display-settings.model';
import {
  ActualWorkOrderDurationTypes,
  CountTypeTypes,
  DataSourceTypes,
  EHomeMetrics,
  EPropertyType,
  HomeMetricIdTypes,
  LayoutTypes,
  ShiftSplitTypes,
  TargetWorkOrderDurationTypes,
  TimeSpanTypes,
  UnitOfTimeTypes,
} from '../home-page-display-settings.constants';
import { IHomeMetricSet } from '../../../../store/settings/home-page-display-settings/home-page-display-settings.model';
import {
  IBaseSensorInfo,
  ITemperatureSensorInfo,
} from '../../../home/home-metrics/home-mini-metrics/home-mini-metrics.model';

export interface IConfirmedMetrics {
  home: IHomeMetricSet;
  productionReview: IHomeMetricSet;
  isUsedInProductionReview: boolean;
}

export interface IHomeMetricSetFormByPages {
  productionReview: IHomeMetricSetForm;
  home: IHomeMetricSetForm;
  isUsedInProductionReview: {
    isEnabled: boolean;
    value: boolean;
    rules?: ScwMatInputRule[];
  };
}

export interface IHomeMetricSetForm {
  size: {
    isEnabled: boolean;
    value: [{ id: number; name: string }];
    rules?: ScwMatSelectRule[];
  };
  page: {
    isEnabled: boolean;
    value: IDropdownOptionsWithStringId;
    rules?: ScwMatSelectRule[];
  };
  metric: IHomeMetricSetItemFormRule[];
}

export interface IHomeMetricSetFormRule extends IFormRule {
  size: ScwMatRule[];
  page: ScwMatRule[];
  metric: ScwMatRule[];
  homeMetricSetProperties: ScwMatRule[];
}

export interface IHomeMetricSetItemFormRule {
  isEnabled: boolean;
  value: IDropdownOptionsWithStringId[];
  rules?: ScwMatSelectRule[];
  homeMetricSetProperties?: IMetaRule;
}

export interface IMetaRule {
  layout?: ISelectForm<ISelectGeneric<LayoutTypes, string>>;
  dataSource?: ISelectForm<ISelectGeneric<DataSourceTypes, string>>;
  countType?: ISelectForm<ISelectGeneric<CountTypeTypes, string>>;
  unitOfTime?: ISelectForm<ISelectGeneric<UnitOfTimeTypes, string>>;
  timeSpan?: ISelectForm<ISelectGeneric<TimeSpanTypes, string>>;
  actualWorkOrderDuration?: ISelectForm<ISelectGeneric<ActualWorkOrderDurationTypes, string>>;
  targetWorkOrderDuration?: ISelectForm<ISelectGeneric<TargetWorkOrderDurationTypes, string>>;
  shiftSplit?: ISelectForm<ISelectGeneric<ShiftSplitTypes, string>>;
  unitOfCount?: ISelectForm<ISelectGeneric<number, string>>;
  metricLayout?: ISelectForm<ISelectGeneric<EOeeMetric, string>>;
}

export interface IMetaDropdown {
  [EHomeMetrics.SPEED]: ISpeedMetricPreDefined;
  [EHomeMetrics.WORK_ORDER_DURATION]: IWorkOrderDurationMetricPreDefined;
  [EHomeMetrics.COUNT]: ICountMetricPreDefined;
  [EHomeMetrics.ACTIVITIES_TOTAL_DURATION]: IActivitiesTotalDurationMetricPreDefined;
  [EHomeMetrics.ACTIVITY_DURATION]: IActivityDurationMetricPreDefined;
  [EHomeMetrics.SHIFT_OEE]: IOeeMetricPreDefined;
  [EHomeMetrics.WORK_ORDER_OEE]: IOeeMetricPreDefined;
  [EHomeMetrics.WORK_ORDER_OEE_SIX_HOURS]: IOeeMetricPreDefined;
  [EHomeMetrics.WORK_ORDER_OEE_TWELVE_HOURS]: IOeeMetricPreDefined;
}

export interface ISpeedMetricPreDefined {
  layout: { id: LayoutTypes; name: string }[];
  dataSource: { id: DataSourceTypes; name: string }[];
  countType: { id: CountTypeTypes; name: string }[];
  unitOfTime: { id: UnitOfTimeTypes; name: string }[];
  timeSpan: { id: TimeSpanTypes; name: string }[];
  unitOfCount: IUnitOfCount[];
}

export interface IWorkOrderDurationMetricPreDefined {
  actualWorkOrderDuration: { id: ActualWorkOrderDurationTypes; name: string }[];
  targetWorkOrderDuration: { id: TargetWorkOrderDurationTypes; name: string }[];
}

export interface ICountMetricPreDefined {
  layout: { id: LayoutTypes; name: string }[];
  dataSource: { id: DataSourceTypes; name: string }[];
  countType: { id: CountTypeTypes; name: string }[];
  timeSpan: { id: TimeSpanTypes; name: string }[];
  unitOfCount: { id: number; name: string }[];
}

export interface IActivitiesTotalDurationMetricPreDefined {
  unitOfTime: { id: UnitOfTimeTypes; name: string }[];
}

export interface IActivityDurationMetricPreDefined {
  shiftSplit: { id: ShiftSplitTypes; name: string }[];
}

export enum EOeeMetric {
  OEE = 'oee',
  A = 'a',
  P = 'p',
  Q = 'q',
}

export interface IOeeMetricPreDefined {
  metricLayout: ISelectGeneric<EOeeMetric, string>[];
}

export interface IOrders {
  home: number;
  productionReview: number;
}

export interface IProperties {
  home: string[];
  productionReview: string[];
}

export type IMeta =
  | ISpeedMeta
  | ICountMeta
  | IWorkOrderDurationMeta
  | IActivityDurationMeta
  | IOEEMetricMeta
  | IOEEMetricMultiselectMeta
  | IMiniMetricMeta[];

export interface ISpeedMeta {
  layout?: LayoutTypes;
  dataSource?: DataSourceTypes;
  countType?: CountTypeTypes;
  unitOfTime?: UnitOfTimeTypes;
  timeSpan?: TimeSpanTypes;
  unitOfCount?: string;
}

export interface IOEEMetricMeta {
  metricLayout: EOeeMetric;
}

export interface IOEEMetricMultiselectMeta {
  metricLayout: EOeeMetric[];
}

export interface IWorkOrderDurationMeta {
  actualWorkOrderDuration?: ActualWorkOrderDurationTypes;
  targetWorkOrderDuration?: TargetWorkOrderDurationTypes;
}

export interface IActivityDurationMeta {
  shiftSplit?: ShiftSplitTypes;
}

export interface ICountMeta {
  layout?: LayoutTypes;
  dataSource?: DataSourceTypes;
  countType?: CountTypeTypes;
  timeSpan?: TimeSpanTypes;
  unitOfCount?: string;
}

export interface IActivitiesTotalDuration {
  unitOfTime?: UnitOfTimeTypes;
}

export interface IHomeMetricSetPropertiesAndFormValues {
  metric: HomeMetricIdTypes;
  propertyType: EPropertyType;
  properties: string[];
  formValue: IMetaRule;
}

export interface IUnitOfCount {
  id: number;
  name: string;
}

export interface ISelectedUnitOfCountIds {
  home: string;
  productionReview: string;
}

export interface IMiniMetricMeta {
  lineId: number;
  miniMetrics: IMiniMetricDetails;
  isTemperatureEnabled: boolean;
  isHumidityEnabled: boolean;
}

export interface IMiniMetricDetails {
  humidity: IBaseSensorInfo[];
  temperature: ITemperatureSensorInfo[];
}
