import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { combineLatest, forkJoin, Observable } from 'rxjs';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { exhaustMap, map } from 'rxjs/operators';
import {
  ActivityCRUDInterface,
  LineCRUDInterface,
  SiteCRUDInterface,
  TaskFilterCRUDInterface,
} from '../../../../shared/component/filter/filter.class';
import { SiteService } from '../../../../shared/service/filter/site.service';
import { LineService } from '../../../../shared/service/line/line.service';
import { ActivityService } from '../../../../shared/service/filter/activity.service';
import { TaskService } from '../../../../shared/service/filter/task.service';
import * as _ from 'lodash';
import { IChangeActivityRow } from '../../../../view/settings/opc-tag-automation-settings/change-activity/change-activity.model';

@Injectable({
  providedIn: 'root',
})
export class ChangeActivityService {
  private readonly routes = {
    changeActivity: `${this.baseUrl}/opc-tag-automation-settings/change-activity`,
  };

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly siteService: SiteService,
    private readonly lineService: LineService,
    private readonly activityService: ActivityService,
    private readonly tasksService: TaskService,
  ) {}

  public getData(params: HttpParams): Observable<GetManyResponseInterface<IChangeActivityRow[]>> {
    return this.http.get<GetManyResponseInterface<IChangeActivityRow[]>>(this.routes.changeActivity, {
      params,
    });
  }
  public getOneWithDropdowns(
    recordId: number,
    params?: HttpParams,
  ): Observable<{
    changeActivityRecord: BaseOneResponseInterface<IChangeActivityRow>;
    sites: SiteCRUDInterface[];
    lines: GetManyResponseInterface<LineCRUDInterface>;
    activities: ActivityCRUDInterface[];
    tasks: TaskFilterCRUDInterface[];
    extendedTasks: TaskFilterCRUDInterface[];
  }> {
    return combineLatest([
      this.http.get<BaseOneResponseInterface<IChangeActivityRow>>(`${this.routes.changeActivity}/${recordId}`, {
        params,
      }),
    ]).pipe(
      exhaustMap(([changeActivityRecord]) => {
        const search = {
          statusId: 1,
          siteId: changeActivityRecord['data']['siteId'],
        };
        const lineHttpParams = new HttpParams()
          .set('s', JSON.stringify(search))
          .set('limit', '1000')
          .set('join', 'lineTypeName');

        const siteHttpParams: HttpParams = new HttpParams().set('limit', 1000);

        const searchTask = {
          statusId: 1,
          activityId: changeActivityRecord.data.defaultTargetActivityId,
        };

        const tasksHttpParams = new HttpParams().set('s', JSON.stringify(searchTask)).set('limit', '1000');

        const searchExtendedTask = {
          statusId: 1,
          activityId: changeActivityRecord.data.extendedTargetActivityId,
        };

        const extendedTasksHttpParams = new HttpParams()
          .set('s', JSON.stringify(searchExtendedTask))
          .set('limit', '1000');

        return combineLatest([
          this.lineService.getLines(lineHttpParams),
          this.siteService.getData(siteHttpParams),
          this.tasksService.getData(tasksHttpParams),
          this.tasksService.getData(extendedTasksHttpParams),
        ]).pipe(
          exhaustMap(([lineRecords, siteRecords, tasksRecords, extendedTasksRecords]) => {
            const selectedLine = _.find(lineRecords.data, { id: changeActivityRecord.data.lineId });
            const activitiesSearch = {
              active: 1,
              id: { $in: selectedLine.activityIds.split(',') ?? [] },
            };

            const activitiesHttpParams = new HttpParams()
              .set('s', JSON.stringify(activitiesSearch))
              .set('limit', '1000');

            const observables = {
              activities: this.activityService.getData(activitiesHttpParams),
            };

            return forkJoin(observables).pipe(
              map(
                (resolvedObservables: {
                  changeActivityRecord: BaseOneResponseInterface<IChangeActivityRow>;
                  sites: SiteCRUDInterface[];
                  lines: GetManyResponseInterface<LineCRUDInterface>;
                  activities: ActivityCRUDInterface[];
                  tasks: TaskFilterCRUDInterface[];
                  extendedTasks: TaskFilterCRUDInterface[];
                }) => {
                  return {
                    changeActivityRecord: changeActivityRecord,
                    sites: siteRecords,
                    lines: lineRecords,
                    tasks: tasksRecords,
                    extendedTasks: extendedTasksRecords,
                    activities: resolvedObservables.activities,
                  };
                },
              ),
            );
          }),
        );
      }),
    );
  }

  public getOne(recordId: number, params?: HttpParams): Observable<BaseOneResponseInterface<IChangeActivityRow>> {
    return this.http.get<BaseOneResponseInterface<IChangeActivityRow>>(`${this.routes.changeActivity}/${recordId}`, {
      params,
    });
  }

  public addOne(record: IChangeActivityRow): Observable<BaseOneResponseInterface<IChangeActivityRow>> {
    return this.http.post<BaseOneResponseInterface<IChangeActivityRow>>(`${this.routes.changeActivity}`, record);
  }

  public editOne(
    recordId: number,
    record: IChangeActivityRow,
  ): Observable<BaseOneResponseInterface<IChangeActivityRow>> {
    return this.http.patch<BaseOneResponseInterface<IChangeActivityRow>>(
      `${this.routes.changeActivity}/${recordId}`,
      record,
    );
  }

  public deleteOnes(recordIds: number[]): Observable<BulkResponseDataInterface> {
    if (recordIds.length > 1) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        body: {
          changeActivityIds: recordIds,
        },
      };
      return this.http.delete<BulkResponseDataInterface>(`${this.routes.changeActivity}/bulk/delete`, httpOptions);
    }
    return this.http.delete<BulkResponseDataInterface>(`${this.routes.changeActivity}/${recordIds[0]}`);
  }
}
