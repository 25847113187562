<div class="device-status-container row px-3 pt-3 pb-2">
  <div class="container main-device-container">
    <div class="row">
      <div class="col-10">
        <span class="f-w-600">{{ data.deviceName ?? ('sensorStatusIndicator.otherSensors.label' | translate) }}</span>
      </div>
      <div class="col-2 station-toggle-button-col">
        <a href="javascript:" *ngIf="isSensorHasStation" (click)="onExtendOrCollapseStationsClick($event)">
          <i class="fas fa-angle-down" aria-hidden="true" *ngIf="!isStationsExtended"></i>
          <i class="fas fa-angle-up" aria-hidden="true" *ngIf="isStationsExtended"></i>
          <span class="visually-hidden">
            {{ isStationsExtended ? 'Collapse stations' : 'Extend stations' }}
          </span>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div [ngClass]="data.deviceStatus === 'online' ? 'online-status' : 'offline-status'">
          <i *ngIf="data.deviceStatus === 'online'" class="fas fa-check" aria-hidden="true"></i>
          <i *ngIf="data.deviceStatus !== 'online' && data.deviceName" class="fas fa-times" aria-hidden="true"></i>
          {{ data.deviceStatus ? ('sensorStatusIndicator.deviceStatus.' + data.deviceStatus | translate) : '' }}
        </div>
      </div>
    </div>
  </div>

  <ul class="d-block sensor-list">
    <li *ngFor="let sensor of data.sensorDetails" class="sensor-list-item">
      <span class="sensor-info-line">
        <span *ngIf="sensor.effectiveSensorData; else placeholder">
          <span class="icon">
            <span class="line">
              <span class="circle" [ngClass]="{ offline: data.deviceStatus !== 'online' && data.deviceName }"></span>
            </span>
          </span>
        </span>
        <ng-template #placeholder>
          <span class="icon-placeholder"></span>
        </ng-template>
        <span class="f-w-600">
          {{
            sensor.parentSensorType
              ? ('sensorTypes.sensor_type_' + sensor.parentSensorType | translate)
              : ('sensorTypes.sensor_type_' + sensor.sensorType | translate)
          }}
        </span>
        <span> - </span>
        <span>{{ sensor.sensorIdWithoutMac }}</span>
        <span> - </span>
        <span>{{ sensor.lastCommDate ?? ('sensorStatusIndicator.sensor.noMessageReceived' | translate) }}</span>
        <span *ngIf="sensor.sensorType === SensorTypeEnum.SUB_SENSOR">
          ({{ 'sensorTypes.sensor_type_' + SensorTypeEnum.SUB_SENSOR | translate }})
        </span>
        <div
          class="container stations-container"
          *ngIf="sensor.effectiveSensorData?.stations || sensor.stations?.length > 0"
          [ngClass]="isStationsExtended ? 'stations-extended' : 'stations-collapsed'"
        >
          <div class="row">
            <div class="col-12">
              <span
                class="station-badge"
                *ngFor="
                  let station of sensor.effectiveSensorData?.stations?.length > 0
                    ? sensor.effectiveSensorData.stations
                    : sensor.stations
                "
              >
                <i class="fa fa-th-large icon-font-size"></i>&nbsp;
                {{ station.name }}
              </span>
            </div>
          </div>
        </div>
      </span>
    </li>
  </ul>
</div>
