import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, switchMap, take } from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
import * as ObjectActions from './activity-history.actions';
import * as AppActions from '../app/actions';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import {
  ActivityAddOrEditResponseInterface,
  FixedAndCurrentVersionInterface,
  ICurrentSiteLineInformation,
  OverlappedActivitiesDataInterface,
  WorkorderNumbersDataInterface,
  WorkOrderResponseDataInterface,
} from './activity-history.model';
import * as LineActions from '../line/actions';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../oee.reducer';
import { User } from '../user/model';
import { ResponseInterface } from '../../shared/model/interface/generic-api-response.model';
import { IActivityHistory } from '../../shared/service/activity-history/activity-history.model';
import { ActivityHistoryService } from '../../shared/service/activity-history/activity-history.service';
import {
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../shared/model/interface/crud-response-interface.model';
import { FieldTypes } from '../../shared/component/filter/advanced-filter/advanced-filter.model';
import { AdvancedFilterService } from '../../shared/component/filter/advanced-filter/advanced-filter.service';
import * as _ from 'lodash';
import { EntityTranslatorService } from '../../shared/service/entity-translator/entity-translator.service';
import { HelperService } from '../../shared/service/helper.service';

@Injectable()
export class ActivityHistoryEffects {
  private currentSite: number;
  private currentLine: number;
  private userLanguage$: string = '';
  private getForTextSearch: boolean = false;

  constructor(
    private actions$: Actions<ObjectActions.ActivityHistoryActions>,
    private http: HttpClient,
    private store: Store<OeeAppState>,
    private readonly service: ActivityHistoryService,
    private readonly advancedFilterService: AdvancedFilterService,
    @Inject('API_BASE_URL') private baseUrl: string,
    private readonly translatorService: EntityTranslatorService,
  ) {}

  private ACTIVITY_HISTORY_URL = {
    GET: {
      GET_ACTIVITY_HISTORY: `${this.baseUrl}/work-orders`,
      ACTIVITY_EVENTS: `${this.baseUrl}/activity-histories`,
      GET_WORK_ORDER_GOOD_COUNT: `${this.baseUrl}/work-orders/get-work-order-good-count`,
    },
    POST: {
      SAVE_UPDATE_OR_DELETE_ACTIVITY_EVENT_DATA: 'activity-histories',
      UPDATE_BULK_ACTIVITY_EVENT_DATA: 'activity-histories/bulk/edit',
      CREATE_BULK_ACTIVITY_EVENT_DATA: 'activity-histories/bulk/create',
    },
  };
  private options: { params: HttpParams };

  private getRequestParameters(
    objectData: ObjectActions.GetActivityEvents | ObjectActions.GetActivityEventMissingData,
    current: { lineId: number; siteId: number },
    isForMissingData?: boolean,
  ) {
    let queryParams: HttpParams = new HttpParams();

    const missingDataConditions = [
      {
        $and: [
          { 'activity.activityType': { $ne: 'runTime' } },
          { $or: [{ taskId: { $or: { $eq: '', $isnull: true } } }, { 'task.isMissingData': { $eq: 1 } }] },
        ],
      },
      { activityId: { $or: { $isnull: true, $eq: '' } } },
      { start: { $or: { $isnull: true } } },
      { end: { $or: { $isnull: true } } },
      {
        $and: [
          { 'activity.activityType': { $ne: 'idleTime' } },
          { $or: [{ 'workOrder.woNumber': { $eq: '' } }, { workOrderId: { $isnull: true } }] },
        ],
      },
    ];

    this.store
      .select('user')
      .pipe(take(1))
      .subscribe((state: User) => {
        this.userLanguage$ = state.language;
      });

    const missingDataConditionsWithSearch = [
      {
        $or: [
          { 'workOrder.woNumber': { $cont: objectData.search } },
          { 'activity.name': { $cont: objectData.search } },
          { 'task.title': { $cont: objectData.search } },
          { 'product.description': { $cont: objectData.search } },
        ],
      },
      {
        $or: [
          {
            $and: [
              { 'activity.activityType': { $ne: 'runTime' } },
              { $or: [{ taskId: { $or: { $eq: '', $isnull: true } } }, { 'task.isMissingData': { $eq: 1 } }] },
            ],
          },
          { activityId: { $or: { $isnull: true, $eq: '' } } },
          { start: { $or: { $isnull: true } } },
          { end: { $or: { $isnull: true } } },
          {
            $and: [
              { 'activity.activityType': { $ne: 'idleTime' } },
              { $or: [{ 'workOrder.woNumber': { $eq: '' } }, { workOrderId: { $isnull: true } }] },
            ],
          },
        ],
      },
      {
        $and: isForMissingData
          ? [{ 'task.meta': { $excl: '"withoutWorkOrder": 1' } }, { taskId: { $or: { $eq: '', $isnull: true } } }]
          : undefined,
      },
      { lineId: { $eq: current.lineId } },
      { siteId: { $eq: current.siteId } },
    ];

    const lineViewConditions = [
      {
        $or: [
          { 'workOrder.woNumber': { $cont: objectData.search } },
          { 'activity.name': { $cont: objectData.search } },
          {
            $and: [
              { 'activity.activityType': { $eq: 'runTime' } },
              { 'product.description': { $cont: objectData.search } },
            ],
          },
          {
            $and: [{ 'activity.activityType': { $ne: 'runTime' } }, { 'task.title': { $cont: objectData.search } }],
          },
        ],
      },
      { lineId: { $eq: current.lineId } },
      { siteId: { $eq: current.siteId } },
    ];

    const searchDataQueryOptions = {
      $and: [
        { $or: isForMissingData ? missingDataConditions : undefined },
        {
          $and: isForMissingData
            ? [
                {
                  $or: [
                    { 'task.isMissingData': { $eq: 1 } },
                    {
                      $and: [
                        { 'task.meta': { $excl: '"withoutWorkOrder": 1' } },
                        { taskId: { $or: { $eq: '', $isnull: true } } },
                      ],
                    },
                  ],
                },
              ]
            : undefined,
        },
        ...(objectData.workOrderId
          ? [{ workOrderId: { $eq: objectData.workOrderId } }]
          : [{ lineId: { $eq: current.lineId } }, { siteId: { $eq: current.siteId } }]),
      ],
    };

    if (!_.isNil(objectData.search) && objectData.search !== '') {
      queryParams = queryParams.append('searchText', String(objectData.search));
    }

    queryParams = queryParams
      .set('page', String(objectData.page))
      .set('limit', String(objectData.pageSize))
      .append('join', 'activity')
      .append('join', 'workOrder')
      .append('join', 'workOrder.product')
      .append('join', 'task')
      .set('sort', 'start,DESC')
      .set('s', JSON.stringify(searchDataQueryOptions));

    if (objectData.workOrderId) {
      queryParams = queryParams.append('join', 'workOrder.site').append('join', 'line||title');
    }

    if (objectData.orderBy !== undefined && objectData.orderBy !== null) {
      queryParams = queryParams.set('sort', `${objectData.orderBy},${objectData.orderDesc ? 'DESC' : 'ASC'}`);
    }

    const manualSearchQueryOptions: { [key: string]: any } = {
      $and: isForMissingData ? missingDataConditionsWithSearch : lineViewConditions,
    };

    if (objectData.search) {
      queryParams = queryParams.set('s', JSON.stringify(manualSearchQueryOptions));
    }

    if (objectData.advancedFilter && objectData.advancedFilter.filters.length !== 0) {
      const advancedFilter = objectData.advancedFilter.filters;

      for (const filter of advancedFilter) {
        switch (filter.type) {
          case FieldTypes.predefined:
            manualSearchQueryOptions['$and'].push(
              this.advancedFilterService.generateQuery(
                filter.path,
                filter.type,
                filter.operator.name,
                filter.operator.type,
                objectData.advancedFilter.target,
                _.get(filter.value, `[0][${filter.searchBy}]`, ''),
              ),
            );

            break;
          default:
            if (filter.path === 'task.title') {
              manualSearchQueryOptions['$and'].push({
                $or: [
                  {
                    $and: [
                      { 'activity.activityType': { $eq: 'runTime' } },
                      this.advancedFilterService.generateQuery(
                        'product.description',
                        filter.type,
                        filter.operator.name,
                        filter.operator.type,
                        objectData.advancedFilter.target,
                        filter.value,
                      ),
                    ],
                  },
                  {
                    $and: [
                      { 'activity.activityType': { $ne: 'runTime' } },
                      this.advancedFilterService.generateQuery(
                        filter.path,
                        filter.type,
                        filter.operator.name,
                        filter.operator.type,
                        objectData.advancedFilter.target,
                        filter.value,
                      ),
                    ],
                  },
                ],
              });
            } else {
              manualSearchQueryOptions['$and'].push(
                this.advancedFilterService.generateQuery(
                  filter.path,
                  filter.type,
                  filter.operator.name,
                  filter.operator.type,
                  objectData.advancedFilter.target,
                  filter.value,
                ),
              );
            }

            break;
        }

        if (isForMissingData) {
          manualSearchQueryOptions['$and'].push({
            $and: [
              {
                $or: [
                  { 'task.isMissingData': { $eq: 1 } },
                  {
                    $and: [
                      { 'task.meta': { $excl: '"withoutWorkOrder": 1' } },
                      { taskId: { $or: { $eq: '', $isnull: true } } },
                    ],
                  },
                ],
              },
            ],
          });
        }
      }

      queryParams = queryParams.set('s', JSON.stringify(manualSearchQueryOptions));
    }
    return {
      params: queryParams,
    };
  }

  private getActivityHistorySearchParams(objectData: ObjectActions.GetWorkOrders): string {
    if (!objectData.search) {
      return JSON.stringify({
        $and: [
          {
            siteId: {
              $eq: this.currentSite,
            },
          },
          {
            'activityHistory.line_id': {
              $eq: this.currentLine,
            },
          },
          !objectData.canceled && (objectData.completed || objectData.completed === 0)
            ? {
                completed: objectData.completed === 1 ? { $eq: 1 } : { $isnull: true },
              }
            : {},
          objectData.canceled
            ? {
                canceled: { $eq: 1 },
              }
            : {},
        ],
      });
    }

    return JSON.stringify({
      $and: [
        {
          $or: [
            {
              woNumber: {
                $cont: `${objectData.search}`,
              },
            },
            {
              'products.description': {
                $cont: `${objectData.search}`,
              },
            },
            {
              'products.productId': {
                $cont: `${objectData.search}`,
              },
            },
          ],
        },
        {
          siteId: {
            $eq: this.currentSite,
          },
        },
        {
          'activityHistory.line_id': {
            $eq: this.currentLine,
          },
        },
        objectData.completed || objectData.completed === 0
          ? {
              completed: objectData.completed === 1 ? { $eq: 1 } : { $isnull: true },
            }
          : {},
      ],
    });
  }

  private getCurrentSiteLineInformation(): ICurrentSiteLineInformation {
    const current = { lineId: null, siteId: null };

    const userStore = this.store.select('user').subscribe((state: User) => {
      current.lineId = state.lineId;
      current.siteId = state.siteId;
    });
    userStore.unsubscribe();

    return current;
  }

  getWorkOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ActivityHistoryActionTypes.GetWorkOrders),
      switchMap((objectData: ObjectActions.GetWorkOrders) => {
        this.store
          .select('user')
          .pipe(take(1))
          .subscribe((state) => {
            this.userLanguage$ = state.language;
            this.currentSite = state.siteId;
            this.currentLine = state.lineId;
          });

        let params = new HttpParams();
        params = params
          .set('page', objectData.page.toString())
          .set('limit', objectData.pageSize.toString())
          .set('s', this.getActivityHistorySearchParams(objectData))
          .set('join', 'product||productId,description')
          .set('withDuration', 'true');

        if (objectData.search) {
          params = params.set('s', this.getActivityHistorySearchParams(objectData));
        }

        if (objectData.orderBy) {
          const direction = objectData.orderDesc === 1 ? 'DESC' : 'ASC';
          params = params.set('sort', `${objectData.orderBy},${direction}`);

          if (objectData.orderBy === 'completed') {
            params = params.set('sort', 'workOrderStartDate,ASC');
          }
        }

        if (!_.isNil(objectData.search) && objectData.search !== '') {
          params = params.append('searchText', String(objectData.search));
        }

        return this.http
          .get<WorkOrderResponseDataInterface>(this.ACTIVITY_HISTORY_URL.GET.GET_ACTIVITY_HISTORY, {
            params,
          })
          .pipe(
            switchMap((resData: WorkOrderResponseDataInterface) => {
              resData.data.forEach((workOrderNumbersData: WorkorderNumbersDataInterface) =>
                this.translatorService.translate(workOrderNumbersData),
              );
              return of(new ObjectActions.GetWorkOrdersLoaded(this.service.formatActivityHistoryWorkOrderData(resData)));
            }),
            catchError((errorRes) => {
              return of(new ObjectActions.FetchError(errorRes));
            }),
          );
      }),
    ),
  );

  getActivityEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ActivityHistoryActionTypes.GetActivityEvents),
      switchMap((objectData: ObjectActions.GetActivityEvents) => {
        this.options = this.getRequestParameters(objectData, this.getCurrentSiteLineInformation());
        return this.http
          .get<any>(this.ACTIVITY_HISTORY_URL.GET.ACTIVITY_EVENTS, {
            ...this.options,
          })
          .pipe(
            switchMap((resData) => {
              resData.data.forEach((activityHistory: IActivityHistory) => {
                this.translatorService.translate(activityHistory);
              });

              return of(new ObjectActions.GetActivityEventsLoaded(resData), new AppActions.HideLoader());
            }),
            catchError((errorRes) => {
              return of(new ObjectActions.FetchError(errorRes));
            }),
          );
      }),
    ),
  );

  getActivityEventMissingData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ActivityHistoryActionTypes.GetActivityEventMissingData),
      switchMap((objectData: ObjectActions.GetActivityEventMissingData) => {
        this.getForTextSearch = objectData.search !== '';
        this.options = this.getRequestParameters(objectData, this.getCurrentSiteLineInformation(), true);
        return this.http
          .get<any>(this.ACTIVITY_HISTORY_URL.GET.ACTIVITY_EVENTS, {
            ...this.options,
          })
          .pipe(
            switchMap((resData) => {
              resData.data.forEach((e) => {
                this.translatorService.translate(e);
              });
              return of(
                ...(this.getForTextSearch ? [] : [new LineActions.SetMissingDataCount(resData.total)]),
                new ObjectActions.GetActivityEventMissingDataLoaded(resData),
                new AppActions.HideLoader(),
              );
            }),
            catchError((errorRes) => {
              return of(new ObjectActions.FetchError(errorRes));
            }),
          );
      }),
    ),
  );

  getActivityEventData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ActivityHistoryActionTypes.GetActivityEventData),
      switchMap((objectData: ObjectActions.GetActivityEventData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getActivity(objectData.id).pipe(
          switchMap((response: ResponseInterface<IActivityHistory>) => {
            this.translatorService.translate(response.data);

            return of(
              new ObjectActions.GetActivityEventDataCompleted(response.data),
              new AppActions.HideLoader(),
              new AppActions.HideTopLoader(),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes));
          }),
        );
      }),
    ),
  );

  getWorkOrderGoodCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ActivityHistoryActionTypes.GetWorkOrderGoodCount),
      mergeMap((objectData: ObjectActions.GetWorkOrderGoodCount) => {
        return this.http
          .get<any>(`${this.ACTIVITY_HISTORY_URL.GET.GET_WORK_ORDER_GOOD_COUNT}/${objectData.workOrderId}`, {})
          .pipe(
            switchMap((resData) => {
              return of(
                new ObjectActions.GetWorkOrderGoodCountLoaded({
                  ...resData,
                  workOrderId: objectData.workOrderId,
                }),
                new AppActions.HideTopLoader(),
              );
            }),
            catchError((errorRes) => {
              return of(
                new ObjectActions.FetchError(errorRes),
                new AppActions.HideLoader(),
                new AppActions.HideTopLoader(),
              );
            }),
          );
      }),
    ),
  );

  saveUpdateOrDeleteActivityEventData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ActivityHistoryActionTypes.SaveUpdateOrDeleteActivityEventData),
      switchMap((objectDataReadOnly: ObjectActions.SaveUpdateOrDeleteActivityEventData) => {
        const objectData = HelperService.cloneDeep(objectDataReadOnly);
        this.store.dispatch(new AppActions.ShowLoader());
        const url = `${this.baseUrl}/${this.ACTIVITY_HISTORY_URL.POST.SAVE_UPDATE_OR_DELETE_ACTIVITY_EVENT_DATA}`;
        let httpRequest: Observable<ActivityAddOrEditResponseInterface> = this.http.post<any>(url, objectData.payload);

        if (objectData.payload !== null && objectData.payload.id !== undefined && objectData.payload.id !== null) {
          const id: number = objectData.payload.id;
          delete objectData.payload.id;
          httpRequest = this.http.patch<ActivityAddOrEditResponseInterface>(`${url}/${id}`, objectData.payload);
        } else if (objectData.deleteId !== null) {
          httpRequest = this.http.delete<ActivityAddOrEditResponseInterface>(`${url}/${objectData.deleteId}`);
        }

        return httpRequest.pipe(
          switchMap((response: ActivityAddOrEditResponseInterface) => {
            return of(
              new AppActions.HideLoader(),
              new AppActions.HideTopLoader(),
              new ObjectActions.ClearFormDataStatus(),
              new AppActions.SetActivityListUpdate(true),
              new ObjectActions.SaveOrUpdateActivityEventDataCompleted(response),
            );
          }),
          catchError((errorRes) => {
            return of(
              new ObjectActions.FetchError(
                errorRes,
                ObjectActions.ActivityHistoryActionTypes.SaveUpdateOrDeleteActivityEventDataCompleted,
              ),
              new AppActions.HideLoader(),
              new AppActions.HideTopLoader(),
            );
          }),
        );
      }),
    ),
  );

  $saveOrUpdateBulkActivityEventData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ActivityHistoryActionTypes.SaveOrUpdateBulkActivityEventData),
      switchMap((objectData: ObjectActions.SaveOrUpdateBulkActivityEventData) => {
        this.store.dispatch(new AppActions.ShowLoader());
        let url = `${this.baseUrl}/${this.ACTIVITY_HISTORY_URL.POST.CREATE_BULK_ACTIVITY_EVENT_DATA}`;
        const { isGapActivity, ...requestData } = objectData.data;

        let httpRequest: Observable<ActivityAddOrEditResponseInterface> = this.http.post<any>(url, requestData);

        if (
          objectData.data.activityHistories !== null &&
          objectData.data.activityHistories[0].id !== undefined &&
          objectData.data.activityHistories[0].id !== null &&
          !objectData.data.isGapActivity
        ) {
          url = `${this.baseUrl}/${this.ACTIVITY_HISTORY_URL.POST.UPDATE_BULK_ACTIVITY_EVENT_DATA}`;
          httpRequest = this.http.patch<ActivityAddOrEditResponseInterface>(url, requestData);
        }

        return httpRequest.pipe(
          switchMap((response: ActivityAddOrEditResponseInterface | BulkResponseDataInterface) => {
            return of(
              new ObjectActions.ClearFormDataStatus(),
              new AppActions.SetActivityListUpdate(true),
              new ObjectActions.SaveOrUpdateBulkActivityEventDataCompleted(response as BulkResponseDataInterface),
            );
          }),
          catchError((errorRes) => {
            return of(
              new ObjectActions.FetchError(
                errorRes,
                ObjectActions.ActivityHistoryActionTypes.SaveOrUpdateBulkActivityEventDataCompleted,
              ),
              new AppActions.HideLoader(),
              new AppActions.HideTopLoader(),
            );
          }),
        );
      }),
    ),
  );

  $ValidateActivityOverlap = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ActivityHistoryActionTypes.ValidateActivityOverlap),
      switchMap((objectData: ObjectActions.ValidateActivityOverlap) => {
        return from(this.service.validateActivityOverlap(objectData.payload)).pipe(
          switchMap((response: OverlappedActivitiesDataInterface) => {
            response.data.currentVersion.forEach((currentVersion: FixedAndCurrentVersionInterface) =>
              this.translatorService.translate(currentVersion),
            );
            return of(new AppActions.HideLoader(), new ObjectActions.ValidateActivityOverlapCompleted(response));
          }),
          catchError((err: HttpErrorResponse[]) => {
            return of(
              new AppActions.HideLoader(),
              new ObjectActions.FetchError(err),
              new ObjectActions.ValidateErrorActivityOverlap(),
            );
          }),
        );
      }),
    ),
  );

  $ValidateOngoingActivityOverlap = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ActivityHistoryActionTypes.ValidateOngoingActivityOverlap),
      switchMap((objectData: ObjectActions.ValidateOngoingActivityOverlap) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return from(this.service.validateOngoingActivityOverlap(objectData.lineId, objectData.activityInfo)).pipe(
          switchMap((response: OverlappedActivitiesDataInterface) => {
            return of(new AppActions.HideLoader(), new ObjectActions.ValidateOngoingActivityOverlapCompleted(response));
          }),
          catchError((err: HttpErrorResponse[]) => {
            return of(
              new AppActions.HideLoader(),
              new ObjectActions.FetchError(err),
              new ObjectActions.ValidateErrorOngoingActivityOverlap(),
            );
          }),
        );
      }),
    ),
  );

  $acceptFixedVersionForActivityOverlap = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ActivityHistoryActionTypes.AcceptFixedVersionForActivityOverlap),
      switchMap((objectData: ObjectActions.AcceptFixedVersionForActivityOverlap) => {
        return this.service.overlappingActivitiesActivityHistory(objectData.data).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            return of(
              new ObjectActions.ClearFormDataStatus(),
              new AppActions.SetActivityListUpdate(true),
              new ObjectActions.AcceptFixedVersionForActivityOverlapCompleted(),
              new AppActions.HideLoader(),
              new AppActions.HideTopLoader(),
            );
          }),
          catchError((errorRes) => {
            return of(
              new ObjectActions.FetchError(
                errorRes,
                ObjectActions.ActivityHistoryActionTypes.AcceptFixedVersionForActivityOverlapCompleted,
              ),
              new AppActions.HideLoader(),
              new AppActions.HideTopLoader(),
            );
          }),
        );
      }),
    ),
  );

  bulkDelete = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ActivityHistoryActionTypes.DeleteManyActivityHistoryData),
      switchMap((objectData: ObjectActions.DeleteManyActivityHistoryData) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.deleteManyActivityHistoryData(objectData.data).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            return of(new ObjectActions.DeleteManyActivityHistoryDataCompleted(response), new AppActions.HideLoader());
          }),
          catchError(() => {
            return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
      }),
    ),
  );

  getInProgressWorkOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ActivityHistoryActionTypes.GetInProgressWorkOrders),
      switchMap(() => {
        this.store
          .select('user')
          .pipe(take(1))
          .subscribe((state) => {
            this.currentSite = state.siteId;
            this.currentLine = state.lineId;
          });

        let params = new HttpParams();
        params = params
          .set('page', 1)
          .set(
            's',
            JSON.stringify({
              $and: [
                {
                  siteId: {
                    $eq: this.currentSite,
                  },
                },
                {
                  'activityHistory.line_id': {
                    $eq: this.currentLine,
                  },
                },
                {
                  completed: { $isnull: true },
                },
              ],
            }),
          )
          .set('join', 'product||productId,description')
          .set('withDuration', 'true')
          .set('limit', '1000');

        return this.http
          .get<WorkOrderResponseDataInterface>(this.ACTIVITY_HISTORY_URL.GET.GET_ACTIVITY_HISTORY, {
            params,
          })
          .pipe(
            switchMap((resData) => {
              return of(
                new ObjectActions.GetInProgressWorkOrdersLoaded(resData),
                new AppActions.HideLoader(),
                new AppActions.HideTopLoader(),
              );
            }),
            catchError((errorRes) => {
              return of(
                new ObjectActions.FetchError(errorRes),
                new AppActions.HideLoader(),
                new AppActions.HideTopLoader(),
              );
            }),
          );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
      }),
    ),
  );
}
