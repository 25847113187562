import { Injectable, Type } from '@angular/core';
import { DropdownComponent } from './dropdown/dropdown.component';
import { ActionsSubject, Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../store/oee.reducer';
import { StartLoadFilterSites } from '../../../store/filter/site/site.actions';
import { StartLoadFilterLines } from '../../../store/filter/line/line.actions';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
  AlteredActivityCRUDInterface,
  DepartmentCRUDInterface,
  FilterClass,
  FilterClassInterface,
  FilterDataObjectTypes,
  FloorPlanCRUDInterface,
  IEquipmentObject,
  IOpcTagList,
  IUserObject,
  KeyValueDropdownInterface,
  LineCRUDInterface,
  LineStationCRUDInterface,
  LineTypeNameInterface,
  NotificationRecipientUserCRUDInterface,
  ObservationCRUDInterface,
  ProductDropdownItemInterface,
  ProductFamilyCRUDInterface,
  RootCauseGroupCRUDInterface,
  RootCauseTypeCRUDInterface,
  SensorTypeDataInterface,
  ShiftCRUDInterface,
  SiteCRUDInterface,
  SourceObjectTypes,
  TaskFilterCRUDInterface,
  WorkOrderCRUDInterface,
} from './filter.class';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ResetProductStore, StartLoadFilterProducts } from '../../../store/filter/product/product.actions';
import { FilterDateRangePickerComponent } from './filter-date-range-picker/filter-date-range-picker.component';
import * as ShiftFilterActions from '../../../store/filter/shift/shift.actions';
import { GetShifts, StartLoadFilterShifts } from '../../../store/filter/shift/shift.actions';
import { SearchBoxComponent } from './search-box/search-box.component';
import { StartLoadFilterSensorTypes } from '../../../store/filter/sensor-type/sensor-type.actions';
import {
  EFilterDropdownElements,
  HttpOptionInterface,
  IDependPropertyValue,
  IDropdownData,
} from '../../../store/filter/filter.model';
import {
  ResetIndependentProductStore,
  StartLoadIndependentFilterProducts,
} from '../../../store/filter/no-dependency-product/no-dependency-product.actions';
import { IndependentFilterProductState } from '../../../store/filter/no-dependency-product/no-dependency-product.reducer';
import { ResetDepartmentStore, StartLoadFilterDepartments } from '../../../store/filter/department/department.actions';
import { StartLoadFilterObservations } from '../../../store/filter/observation/observation.actions';
import { StartLoadFilterRootCauseTypes } from '../../../store/filter/root-cause-type/root-cause-type.actions';
import { StartLoadFilterSourceObjects } from '../../../store/filter/check-in-log-source-object/source-object.actions';
import { StartLoadFilterActivities } from '../../../store/filter/activity/activity.actions';
import { FilterActivityState } from '../../../store/filter/activity/activity.reducer';
import { HelperService } from '../../service/helper.service';
import { IConstantLookUp } from '../../../view/settings/users/users.model';
import { UserSettingsService } from '../../../store/settings/users/user.service';
import {
  DropdownOptionInterface,
  IDefaultSelectItem,
  ISelectGeneric,
} from '../scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { ActivityTypes } from '../../model/enum/activity-types';
import { StartLoadFilterStartOfDay } from '../../../store/filter/startOfDay/startOfDay.action';
import { DropdownOptionsInterface } from '../../../view/settings/tasks/tasks.model';
import { StartLoadFilterSensors } from '../../../store/filter/sensor/sensor.actions';
import { SensorCRUDRequestInterface } from '../../../store/filter/sensor/sensor.model';
import * as moment from 'moment';
import { mysqlDateFormat, mysqlTimestampFormatZeroMinAndSec } from '../../helper/date';
import { StartLoadFilterRootCauseGroups } from '../../../store/filter/root-cause-group/root-cause-group.actions';
import { FilterRootCauseGroupState } from '../../../store/filter/root-cause-group/root-cause-group.reducer';
import { sensorTypeTranslations, StatusesEnum } from '../../../../constants';
import { ResetUserStore, StartLoadFilterUsers } from '../../../store/filter/user/user.actions';
import { ResetWorkOrderStore, StartLoadFilterWorkOrders } from '../../../store/filter/work-order/work-order.actions';
import {
  ResetProductFamilyStore,
  StartLoadFilterProductFamily,
} from '../../../store/filter/product-family/product-family.actions';
import { User as IUser } from '../../../store/user/model';
import { FilterWorkOrderState } from '../../../store/filter/work-order/work-order.reducer';
import { ResetEquipmentStore, StartLoadFilterEquipments } from '../../../store/filter/equipment/equipment.actions';
import { FilterEquipmentState } from '../../../store/filter/equipment/equipment.reducer';
import { EquipmentListInterface } from '../../../store/settings/equipment-lists/equipment-lists.model';
import { FilterTaskState } from '../../../store/filter/task/task.reducer';
import { ResetTaskStore, StartLoadFilterTasks } from '../../../store/filter/task/task.actions';
import { ETaskPerformanceAnalysisMode } from '../../../store/reports/task-performance/task-performance.model';
import { FilterShiftState } from '../../../store/filter/shift/shift.reducer';
import * as _ from 'lodash';
import { FilterProductState } from '../../../store/filter/product/product.reducer';
import { EMyReportMetricBreakdown } from '../../../view/reports/my-reports/my-report-metrics/my-report-metrics.model';
import * as FilterMyReportBreakdownActions from '../../../store/filter/my-report-breakdown/my-report-breakdown.actions';
import { IFilterMyReportBreakdownState } from '../../../store/filter/my-report-breakdown/my-report-breakdown.reducer';
import {
  ECommentLogsCommentType,
  ECommentLogsObjectProperty,
  ECommentLogsObjectType,
} from '../../../store/reports/comment-logs/comment-logs.model';
import {
  EProductResourceGroupsResourceType,
  EProductResourceGroupsResourceTypeValue,
} from '../../../store/settings/product-resource-groups/product-resource-groups.model';
import { FilterFloorPlansLoading } from '../../../store/filter/floor-plan/floor-plan.actions';
import { FilterFloorPlanState } from '../../../store/filter/floor-plan/floor-plan.reducer';
import { GenericHelperService } from '../../service/generic.helper.service';
import * as CustomerSegmentFilterActions from '../../../store/filter/customer-segment/customer-segment.actions';
import { TagInterface, TagsObjectTypes } from '../../../store/settings/tags/tags.model';
import { ofType } from '@ngrx/effects';
import * as TagActions from '../../../store/filter/tag/tag.actions';
import { TagsService } from '../../../store/settings/tags/tags.service';
import {
  commentTypeTagTypeRelation,
  ICommentTypeFilterCardOption,
} from '../../../store/comment-types/comment-types.model';
import {
  ResetProductOrProductFamilyStore,
  StartLoadFilterProductOrProductFamily,
} from '../../../store/filter/product-or-product-family/product-or-product-family.actions';
import { FilterProductOrProductFamilyState } from '../../../store/filter/product-or-product-family/product-or-product-family.reducer';
import { IFilterState } from '../../../store/filter/filter.reducer';
import { ResetOpcTagStore, StartLoadFilterOpcTag } from '../../../store/filter/opc-tag/opc-tag.actions';
import { FilterOpcTagState } from '../../../store/filter/opc-tag/opc-tag.reducer';
import * as FilterCustomerActions from '../../../store/filter/customer/customer.actions';
import { FilterCustomerState } from '../../../store/filter/customer/customer.reducer';
import { CustomerInterface } from '../../../view/settings/customer-settings/customers/customers.model';
import { EFloorPlanCategoryForId } from '../../../store/settings/departments-lines-stations/floor-plans/floor-plans.model';
import * as MainActions from 'src/app/store/main/main.actions';
import { ENotificationType } from '../../../store/settings/notification-logs/notification-logs.model';
import { FilterNotificationRecipientUserState } from '../../../store/filter/notification-recipient-user/notification-recipient-user.reducer';
import { FilterNotificationRecipientUsersLoading } from '../../../store/filter/notification-recipient-user/notification-recipient-user.actions';
import { FilterLineStationState } from '../../../store/filter/line-station/line-station.reducer';
import { StartLoadFilterLineStations } from '../../../store/filter/line-station/line-station.actions';
import { OeeAppState } from '../../../store/oee.reducer';
import { FilterJobState } from '../../../store/filter/job/job.reducer';
import { FilterJobsLoading, ResetJobsStore } from '../../../store/filter/job/job.action';
import { EScheduleAdherenceAnalysisMode } from '../../../store/reports/schedule-adherence/schedule-adherence.model';
import { EJobStatus } from '../../../store/settings/jobs/jobs.model';
import { StartLoadFilterIotDeviceReportDeviceTypes } from '../../../store/filter/iot-device-report-device-type/iot-device-report-device-type.actions';
import { SourceObjectFilterState } from '../../../store/filter/check-in-log-source-object/source-object.reducer';
import { ECardAssignmentObjectTypeEnum } from '../../../view/settings/rfid-card-assignments/rfid-card-assignment-list/rfid-card-assignment-list.model';
import { StartLoadFilterIotDevice } from '../../../store/filter/iot-device-filter/iot-device-filter.actions';

export enum ObjectNameProps {
  title = 'title',
  translateTitle = 'translatedTitle',
  productIdDescriptionInfo = 'productIdDescriptionInfo',
  shiftName = 'name',
  departmentName = 'lineType',
  observationName = 'name',
  username = 'username',
  fullName = 'fullName',
  equipmentName = 'equipmentName',
  activityTypeRelation = 'name',
  level = 'name',
  activityTypeName = 'text',
  startOfDay = 'name',
  sensor = 'formattedSensorName',
  productFamily = 'name',
  woNumber = 'woNumber',
  task = 'title',
  woStatus = 'name',
  opcTagName = 'tagName',
  productTransitionCompare = 'name',
  productTransitionDuration = 'name',
  productOrProductFamily = 'name',
  jobName = 'jobName',
  macAddress = 'macAddress',
}

export const dateTypesConstant = {
  dueDate: {
    key: 'dueDate',
    entityName: 'woDueDate',
  },
  releaseDate: {
    key: 'releaseDate',
    entityName: 'releaseDate',
  },
  scheduledStartDate: {
    key: 'scheduledStartDate',
    entityName: 'scheduledRunDate',
  },
  scheduledEndDate: {
    key: 'scheduledEndDate',
    entityName: 'scheduledEndDate',
  },
  actualStartDate: {
    key: 'actualStartDate',
    entityName: 'actualRunDate',
  },
};

export const workOrderStatusConstant = {
  notScheduled: {
    key: 'notScheduled',
    entityName: 'scheduledLine',
    operator: '$isnull',
  },
  scheduled: {
    key: 'scheduled',
    entityName: 'scheduledLine',
    operator: '$notnull',
  },
  inProgress: {
    key: 'inProgress',
    entityName: 'completed',
    operator: '$isnull',
  },
  completed: {
    key: 'completed',
    entityName: 'completed',
    operator: '$notnull',
  },
  schedulerCompleted: {
    key: 'schedulerCompleted',
    entityName: 'completed',
    operator: '$isnull',
  },
};

export const workOrderPropertyConstant = {
  workOrder: {
    key: 'workOrder',
    entityName: 'woNumber',
    operator: '$cont',
  },
  productId: {
    key: 'productId',
    entityName: 'productId',
    operator: '$cont',
  },
  productDescription: {
    key: 'productDescription',
    entityName: 'productDescription',
    operator: '$cont',
  },
  workOrderQuantity: {
    key: 'workOrderQuantity',
    entityName: 'quantityOrdered',
    operator: '$eq',
  },
  scheduledLine: {
    key: 'scheduledLine',
    entityName: 'scheduledLineTitle',
    operator: '$cont',
  },
};

export const schedulerScenarioConstant = {
  no: {
    key: 'no',
    entityName: 'no',
    operator: '$notnull',
    id: 0,
  },
  yes: {
    key: 'yes',
    entityName: 'yes',
    operator: '$notnull',
    id: 1,
  },
  partial: {
    key: 'partial',
    entityName: 'partial',
    operator: '$notnull',
    id: 2,
  },
};

export const assetLogConstant = {
  unavailable: {
    key: 'unavailable',
    entityName: 'unavailable',
    operator: '$notnull',
    id: 0,
  },
  available: {
    key: 'available',
    entityName: 'available',
    operator: '$notnull',
    id: 1,
  },
};

export const laborLogConstant = {
  busy: {
    key: 'busy',
    entityName: 'busy',
    operator: '$notnull',
    id: 0,
  },
  available: {
    key: 'available',
    entityName: 'available',
    operator: '$notnull',
    id: 1,
  },
};

export const kpiCardConstant = {
  home: {
    key: 'home',
    entityName: 'page',
    operator: '$eq',
  },
  productionReview: {
    key: 'productionReview',
    entityName: 'page',
    operator: '$eq',
  },
};

export const countTypeConstant = {
  goodCount: {
    id: 'goodCount',
    entityName: 'goodCount',
    operator: '$eq',
  },
  initialCount: {
    id: 'initialCount',
    entityName: 'initialCount',
    operator: '$eq',
  },
};

export const durationTypesConstant = {
  runTimeDuration: {
    key: 'runTimeDuration',
    entityName: 'runTimeDuration',
    operator: '$eq',
  },
  downTimeDuration: {
    key: 'downTimeDuration',
    entityName: 'downTimeDuration',
    operator: '$eq',
  },
};

export enum DateRangeTypes {
  Today = 'today',
  Yesterday = 'yesterday',
  ThisWeek = 'thisWeek',
  NextSevenDays = 'nextSevenDays',
  LastWeek = 'lastWeek',
  ThisMonth = 'thisMonth',
  NextThirtyDays = 'nextThirtyDays',
  LastMonth = 'lastMonth',
  LastThreeMonths = 'lastThreeMonths',
  ThisYear = 'thisYear',
  LastYear = 'lastYear',
  Custom = 'custom',
}

export enum EProductOrFamilyCompareOptions {
  productToProduct = 'productToProduct',
  productFamilyToProductFamily = 'productFamilyToProductFamily',
  productToProductFamily = 'productToProductFamily',
  productFamilyToProduct = 'productFamilyToProduct',
}

export enum EProductOrFamilyDurationType {
  PreRun = 'preRun',
  PostRun = 'postRun',
  TotalDuration = 'totalDuration',
}

export enum EProductOrFamilyFilterType {
  Product = 'product',
  ProductFamily = 'productFamily',
}

export const automationCenterRuleStatusConstant = {
  active: {
    key: 'active',
    entityName: 'active',
    operator: '$notnull',
    id: 1,
  },
  inactive: {
    key: 'inactive',
    entityName: 'inactive',
    operator: '$notnull',
    id: 2,
  },
  draft: {
    key: 'draft',
    entityName: 'draft',
    operator: '$notnull',
    id: 3,
  },
};

@Injectable({
  providedIn: 'root',
})
export class Site extends FilterClass implements FilterClassInterface {
  public options: HttpOptionInterface = {
    page: 1,
    limit: 500,
  };

  constructor(public store: Store<oeeAppReducer.OeeAppState>, public helperService: HelperService) {
    super();
  }

  public override init(): void {
    this.subscribe = this.store.select('siteFilter').subscribe((state) => {
      if (state.isLoaded === true && state.isLoading === false) {
        this.data = _.cloneDeep(state.data);
        this.dataSubject.next(this.data);
      }
    });
  }

  public getInitialData(): SiteCRUDInterface[] {
    let data;
    this.store
      .select('siteFilter')
      .pipe(take(1))
      .subscribe((state) => {
        data = HelperService.cloneDeep(state.data);
      });
    return data;
  }

  public override getAllData(options?: HttpOptionInterface): void {
    this.store.dispatch(new StartLoadFilterSites({ ...this.options, ...options }, this.apiServer));
  }
}

@Injectable({
  providedIn: 'root',
})
export class RootCauseType extends FilterClass implements FilterClassInterface {
  public options: HttpOptionInterface = {
    page: 1,
    limit: 500,
  };

  constructor(public store: Store<oeeAppReducer.OeeAppState>) {
    super();
  }

  public override init(): void {
    this.subscribe = this.store.select('rootCauseTypeFilter').subscribe((state) => {
      if (state.isLoaded === true && state.isLoading === false) {
        this.data = HelperService.cloneDeep(state.data);
        this.dataSubject.next(this.data);
      }
    });
  }

  public getInitialData(): RootCauseTypeCRUDInterface[] {
    let data;
    this.store
      .select('rootCauseTypeFilter')
      .pipe(take(1))
      .subscribe((state) => {
        data = HelperService.cloneDeep(state.data);
      });
    return data;
  }

  public override getAllData(options?: HttpOptionInterface): void {
    this.store.dispatch(new StartLoadFilterRootCauseTypes({ ...this.options, ...options }));
  }
}

@Injectable({
  providedIn: 'root',
})
export class RootCauseGroupFilter extends FilterClass implements FilterClassInterface {
  public options: HttpOptionInterface = {
    page: 1,
    limit: 500,
  };

  constructor(public store: Store<oeeAppReducer.OeeAppState>) {
    super();
  }

  public override init(): void {
    this.subscribe = this.store.select('rootCauseGroupFilter').subscribe((state: FilterRootCauseGroupState) => {
      if (state.data.length === 0 && state.isLoaded === false && state.isLoading === false) {
        this.store.dispatch(new StartLoadFilterRootCauseGroups(HelperService.cloneDeep(this.options)));
      }
      if (state.isLoaded && !state.isLoading) {
        this.data = HelperService.cloneDeep(state.data);
        this.dataSubject.next(this.data);
      }
    });
  }

  public getInitialData(): RootCauseGroupCRUDInterface[] {
    let data;
    this.store
      .select('rootCauseGroupFilter')
      .pipe(take(1))
      .subscribe((state) => {
        data = HelperService.cloneDeep(state.data);
      });

    return data;
  }

  public override getFilterData(options: HttpOptionInterface): void {
    this.options = { ...this.options, ...options };
    this.store.dispatch(new StartLoadFilterRootCauseGroups(this.options));
  }
}

@Injectable({
  providedIn: 'root',
})
export class Line extends FilterClass implements FilterClassInterface {
  public override objectNameProp: string = ObjectNameProps.title;
  public options: HttpOptionInterface = {
    page: 1,
    limit: 500,
  };

  constructor(public store: Store<oeeAppReducer.OeeAppState>) {
    super();
  }

  public static lineCompareFunction(line1: LineCRUDInterface, line2: LineCRUDInterface): number {
    return `${_.get(line1, 'typeName')}${line1.title}`.localeCompare(
      `${_.get(line2, 'typeName')}${line2.title}`,
      undefined,
      {
        numeric: true,
        sensitivity: 'base',
      },
    );
  }

  public override init(): void {
    this.subscribe = this.store.select('lineFilter').subscribe((state) => {
      if (state.isLoaded && state.isLoading === false) {
        this.data = _.cloneDeep(state.data).sort(Line.lineCompareFunction);
        this.dataSubject.next(this.data);
      }
    });
  }

  public getInitialData(): LineCRUDInterface[] {
    let data;
    this.store
      .select('lineFilter')
      .pipe(take(1))
      .subscribe((state) => {
        data = _.cloneDeep(state.data).sort(Line.lineCompareFunction);
      });
    return data;
  }

  public override getAllData(options?: HttpOptionInterface): void {
    this.store.dispatch(new StartLoadFilterLines({ ...this.options, ...options }));
  }
}

@Injectable({
  providedIn: 'root',
})
export class LineActive extends FilterClass implements FilterClassInterface {
  public override objectNameProp: string = ObjectNameProps.title;
  public options: HttpOptionInterface = {
    page: 1,
    limit: 500,
    s: JSON.stringify({ statusId: { $eq: StatusesEnum.ACTIVE } }),
  };

  constructor(public store: Store<oeeAppReducer.OeeAppState>, private readonly helperService: HelperService) {
    super();
  }

  public override init(): void {
    this.store.dispatch(new StartLoadFilterLines(HelperService.cloneDeep(this.options)));

    this.subscribe = this.store.select('lineFilter').subscribe((state) => {
      if (state.isLoaded && !state.isLoading) {
        this.data = _.cloneDeep(state.data).sort(this.helperService.dropdownOptionCompareFunction);
        this.dataSubject.next(this.data);
      }
    });
  }

  public getInitialData(): LineCRUDInterface[] {
    let data;

    this.store
      .select('lineFilter')
      .pipe(take(1))
      .subscribe((state) => {
        data = HelperService.cloneDeep(state.data);
      });

    return data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class Department extends FilterClass implements FilterClassInterface {
  public options: HttpOptionInterface = {
    page: 1,
    limit: 100,
    sort: 'lineTypeName.order,ASC',
    groupBy: 'lineType',
  };
  public override data: DepartmentCRUDInterface[] = [];
  public override objectNameProp: string = ObjectNameProps.departmentName;

  constructor(public store: Store<oeeAppReducer.OeeAppState>) {
    super();
  }

  public override init(): void {
    this.dataSubject.next([]);
    this.subscribe = this.store.select('departmentFilter').subscribe((state) => {
      if (state.isLoaded && state.isLoading === false) {
        this.data = _.cloneDeep(state.data).sort((a, b) => {
          return `${a.lineType}`.localeCompare(`${b.lineType}`, undefined, {
            numeric: true,
            sensitivity: 'base',
          });
        });
        this.dataSubject.next(this.data);
      }
    });
  }

  public getInitialData(): LineTypeNameInterface[] {
    let data: LineTypeNameInterface[] = [];
    this.store
      .select('departmentFilter')
      .pipe(take(1))
      .subscribe((state) => {
        data = HelperService.cloneDeep(state.data);
      });
    return data;
  }

  public override getFilterData(options: HttpOptionInterface): void {
    this.options = { ...this.options, ...options };
    this.store.dispatch(new StartLoadFilterDepartments(HelperService.cloneDeep(this.options)));
  }

  public override getAllData(options?: HttpOptionInterface): void {
    this.store.dispatch(new StartLoadFilterDepartments({ ...this.options, ...options }));
  }

  public override destroy(): void {
    this.data = [];

    this.store.dispatch(new ResetDepartmentStore());
  }
}

@Injectable({
  providedIn: 'root',
})
export class Observation extends FilterClass implements FilterClassInterface {
  public override objectNameProp: string = ObjectNameProps.observationName;
  public options: HttpOptionInterface = {
    page: 1,
    limit: 500,
    filter: [
      {
        prop: 'id',
        condition: '$in',
        query: [1, 2].toString(),
      },
    ],
  };

  constructor(public store: Store<oeeAppReducer.OeeAppState>) {
    super();
  }

  public override init(): void {
    this.store.dispatch(new StartLoadFilterObservations(HelperService.cloneDeep(this.options)));
    this.subscribe = this.store.select('observationFilter').subscribe((state) => {
      if (state.isLoaded && state.isLoading === false) {
        this.data = HelperService.cloneDeep(state.data);
        this.dataSubject.next(this.data);
      }
    });
  }

  public getInitialData(): ObservationCRUDInterface[] {
    let data: ObservationCRUDInterface[] = [];
    this.store
      .select('observationFilter')
      .pipe(take(1))
      .subscribe((state) => {
        data = HelperService.cloneDeep(state.data);
      });
    return data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class Product extends FilterClass implements FilterClassInterface {
  public options: HttpOptionInterface = {
    page: 1,
    limit: 100,
    sort: 'id,DESC',
  };
  public override data: ProductDropdownItemInterface[] = [];
  public override objectNameProp: string = ObjectNameProps.productIdDescriptionInfo;

  constructor(public store: Store<oeeAppReducer.OeeAppState>) {
    super();
  }

  public override init(): void {
    this.dataSubject.next([]);
    this.subscribe = this.store.select('productFilter').subscribe((state: FilterProductState) => {
      if (state.isLoaded && state.isLoading === false) {
        const newData: ProductDropdownItemInterface[] = [];
        for (const data of state.data) {
          newData.push({
            ...data,
            productIdDescriptionInfo: `${data.productId} - ${data.description}`,
          });
        }
        this.data = newData;
        this.dataSubject.next({
          data: this.data,
          totalCount: state.totalCount,
          allowNewRequest: state.allowNewRequest,
        });
      }
    });
  }

  public getInitialData(): ProductDropdownItemInterface[] {
    let data;
    this.store
      .select('productFilter')
      .pipe(take(1))
      .subscribe((state) => {
        const newData: ProductDropdownItemInterface[] = [];
        for (const data of state.data) {
          newData.push({
            ...data,
            productIdDescriptionInfo: `${data.productId} - ${data.description}`,
          });
        }
        data = newData;
      });
    return data;
  }

  public override getFilterData(options: HttpOptionInterface, isFetchMoreRequest?: boolean): void {
    this.options = { ...this.options, ...options };
    this.store.dispatch(new StartLoadFilterProducts(...HelperService.argumentClone(this.options, isFetchMoreRequest)));
  }

  public override destroy(): void {
    this.store.dispatch(new ResetProductStore());
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProductInput extends FilterClass implements FilterClassInterface {
  public options: HttpOptionInterface = {
    page: 1,
    limit: 100,
    sort: 'id,DESC',
  };
  public override data: ProductDropdownItemInterface[] = [];
  public override objectNameProp: string = ObjectNameProps.productIdDescriptionInfo;

  constructor(public store: Store<oeeAppReducer.OeeAppState>) {
    super();
  }

  public override init(): void {
    this.options = {
      page: 1,
      limit: 100,
      sort: 'id,DESC',
    };
    this.store.dispatch(new StartLoadIndependentFilterProducts(HelperService.cloneDeep(this.options)));
    this.subscribe = this.store.select('independentProductFilter').subscribe((state: IndependentFilterProductState) => {
      if (state.isLoaded && state.isLoading === false) {
        const newData: ProductDropdownItemInterface[] = [];
        for (const data of state.data) {
          newData.push({
            ...data,
            productIdDescriptionInfo: `${data.productId} - ${data.description}`,
          });
        }

        this.data = newData;
        this.dataSubject.next(newData);
      }
    });
  }

  public override getDataWithInitialData(initialData: FilterDataObjectTypes[]): FilterDataObjectTypes[] {
    if (!this.options.hasOwnProperty('filter') || this.options.filter.length === 0) {
      let newData = initialData.concat(this.data);
      newData = newData.filter(
        (obj: ProductDropdownItemInterface, pos: number, arr: ProductDropdownItemInterface[]) => {
          return arr.map((mapObj: ProductDropdownItemInterface) => mapObj.id).indexOf(obj.id) === pos;
        },
      );
      return newData;
    }

    return this.data;
  }

  public getInitialData(): ProductDropdownItemInterface[] {
    this.store
      .select('independentProductFilter')
      .pipe(take(1))
      .subscribe((state: IndependentFilterProductState) => {
        const newData: ProductDropdownItemInterface[] = [];
        for (const data of state.data) {
          newData.push({
            ...data,
            productIdDescriptionInfo: `${data.productId} - ${data.description}`,
          });
        }

        return newData;
      });
    return undefined;
  }

  public override getFilterData(options: HttpOptionInterface): void {
    this.options = { ...this.options, ...options };
    this.store.dispatch(new StartLoadIndependentFilterProducts(HelperService.cloneDeep(this.options)));
  }

  public override destroy(): void {
    this.store.dispatch(new ResetIndependentProductStore());
  }
}

@Injectable({
  providedIn: 'root',
})
export class SensorType extends FilterClass implements FilterClassInterface {
  public override objectNameProp: string = ObjectNameProps.translateTitle;
  public override data: SensorTypeDataInterface[];

  constructor(public store: Store<oeeAppReducer.OeeAppState>, public translate: TranslateService) {
    super();
  }

  public override init(): void {
    this.store.select('sensorTypeFilter').subscribe((state) => {
      if (state.data.length === 0 && state.isLoaded === false && state.isLoading === false) {
        this.store.dispatch(new StartLoadFilterSensorTypes());
      }
      if (state.isLoaded && state.isLoading === false) {
        const newData: SensorTypeDataInterface[] = [];
        for (const sensorTypeData of state.data) {
          newData.push({
            ...sensorTypeData,
            translatedTitle: this.translate.instant(`sensorTypes.${sensorTypeData.title}`),
          });
        }
        this.data = newData;
        this.dataSubject.next(this.data);
      }
    });
  }

  public getInitialData(): SensorTypeDataInterface[] {
    this.store
      .select('sensorTypeFilter')
      .pipe(take(1))
      .subscribe((state) => {
        const newData: SensorTypeDataInterface[] = [];
        for (const sensorTypeData of state.data) {
          newData.push({
            ...sensorTypeData,
            translatedTitle: this.translate.instant(`sensorTypes.${sensorTypeData.title}`),
          });
        }
        return newData;
      });
    return undefined;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProductMultiplierSensorType extends FilterClass implements FilterClassInterface {
  public override objectNameProp: string = ObjectNameProps.translateTitle;
  public override data: SensorTypeDataInterface[] = [];

  constructor(public store: Store<oeeAppReducer.OeeAppState>, public translate: TranslateService) {
    super();
  }

  public override init(): void {
    this.store.select('sensorTypeFilter').subscribe((state) => {
      if (state.data.length === 0 && state.isLoaded === false && state.isLoading === false) {
        this.store.dispatch(new StartLoadFilterSensorTypes());
      }
      if (state.isLoaded && state.isLoading === false) {
        const newData: SensorTypeDataInterface[] = [];
        for (const sensorTypeData of state.data) {
          if (sensorTypeData.type !== 'card_reader') {
            newData.push({
              ...sensorTypeData,
              translatedTitle: this.translate.instant(`sensorTypes.${sensorTypeData.title}`),
            });
          }
        }
        this.data = newData;
        this.dataSubject.next(this.data);
      }
    });
  }

  public getInitialData(): SensorTypeDataInterface[] {
    this.store
      .select('sensorTypeFilter')
      .pipe(take(1))
      .subscribe((state) => {
        const newData: SensorTypeDataInterface[] = [];
        for (const sensorTypeData of state.data) {
          if (sensorTypeData.type !== 'card_reader') {
            newData.push({
              ...sensorTypeData,
              translatedTitle: this.translate.instant(`sensorTypes.${sensorTypeData.title}`),
            });
          }
        }
        return newData;
      });
    return undefined;
  }
}

@Injectable({
  providedIn: 'root',
})
export class DateType extends FilterClass implements FilterClassInterface {
  public override data = [
    { key: dateTypesConstant.dueDate.key, name: undefined },
    { key: dateTypesConstant.releaseDate.key, name: undefined },
    { key: dateTypesConstant.scheduledStartDate.key, name: undefined },
    { key: dateTypesConstant.scheduledEndDate.key, name: undefined },
    { key: dateTypesConstant.actualStartDate.key, name: undefined },
  ];
  public override dataSubject: BehaviorSubject<any>;

  constructor(public translate: TranslateService) {
    super();
    this.data.forEach((dateType) => {
      dateType.name = this.translate.instant(`filterCard.dateType.${dateType.key}`);
    });
    this.dataSubject = new BehaviorSubject<object>(this.data);
  }

  public getInitialData(): KeyValueDropdownInterface[] {
    return this.data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class WorkOrderStatus extends FilterClass implements FilterClassInterface {
  public override data = [
    { key: workOrderStatusConstant.notScheduled.key, name: undefined },
    { key: workOrderStatusConstant.scheduled.key, name: undefined },
    { key: workOrderStatusConstant.inProgress.key, name: undefined },
    { key: workOrderStatusConstant.completed.key, name: undefined },
  ];
  public override dataSubject: BehaviorSubject<any>;

  constructor(public translate: TranslateService) {
    super();
    this.data.forEach((dateType) => {
      dateType.name = this.translate.instant(`filterCard.workOrderStatus.${dateType.key}`);
    });
    this.dataSubject = new BehaviorSubject<object>(this.data);
  }

  public getInitialData(): KeyValueDropdownInterface[] {
    return this.data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class SchedulerScenarioPlanned extends FilterClass implements FilterClassInterface {
  public override data = [
    { key: schedulerScenarioConstant.no.key, name: undefined, id: schedulerScenarioConstant.no.id },
    { key: schedulerScenarioConstant.yes.key, name: undefined, id: schedulerScenarioConstant.yes.id },
    { key: schedulerScenarioConstant.partial.key, name: undefined, id: schedulerScenarioConstant.partial.id },
  ];
  public override dataSubject: BehaviorSubject<any>;

  constructor(public translate: TranslateService) {
    super();
    this.data.forEach((dateType) => {
      dateType.name = this.translate.instant(`filterCard.schedulerPlanned.${dateType.key}`);
    });
    this.dataSubject = new BehaviorSubject<object>(this.data);
  }

  public getInitialData(): KeyValueDropdownInterface[] {
    return this.data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class SchedulerScenarioDeployed extends FilterClass implements FilterClassInterface {
  public override data = [
    { key: schedulerScenarioConstant.no.key, name: undefined, id: schedulerScenarioConstant.no.id },
    { key: schedulerScenarioConstant.yes.key, name: undefined, id: schedulerScenarioConstant.yes.id },
    { key: schedulerScenarioConstant.partial.key, name: undefined, id: schedulerScenarioConstant.partial.id },
  ];
  public override dataSubject: BehaviorSubject<any>;

  constructor(public translate: TranslateService) {
    super();
    this.data.forEach((dateType) => {
      dateType.name = this.translate.instant(`filterCard.schedulerDeployed.${dateType.key}`);
    });
    this.dataSubject = new BehaviorSubject<object>(this.data);
  }

  public getInitialData(): KeyValueDropdownInterface[] {
    return this.data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AssetLogStatus extends FilterClass implements FilterClassInterface {
  public override data = [
    { key: assetLogConstant.unavailable.key, name: undefined, id: assetLogConstant.unavailable.id },
    { key: assetLogConstant.available.key, name: undefined, id: assetLogConstant.available.id },
  ];
  public override dataSubject: BehaviorSubject<any>;

  constructor(public translate: TranslateService) {
    super();
    this.data.forEach((dateType) => {
      dateType.name = this.translate.instant(`general.${dateType.key}`);
    });
    this.dataSubject = new BehaviorSubject<object>(this.data);
  }

  public getInitialData(): KeyValueDropdownInterface[] {
    return this.data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class LaborLogStatus extends FilterClass implements FilterClassInterface {
  public override data = [
    { key: laborLogConstant.busy.key, name: undefined, id: laborLogConstant.busy.id },
    { key: laborLogConstant.available.key, name: undefined, id: laborLogConstant.available.id },
  ];
  public override dataSubject: BehaviorSubject<any>;

  constructor(public translate: TranslateService) {
    super();
    this.data.forEach((dateType) => {
      dateType.name = this.translate.instant(`general.${dateType.key}`);
    });
    this.dataSubject = new BehaviorSubject<object>(this.data);
  }

  public getInitialData(): KeyValueDropdownInterface[] {
    return this.data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class WorkOrderProperty extends FilterClass implements FilterClassInterface {
  override data = [
    { key: workOrderPropertyConstant.workOrder.key, name: undefined },
    { key: workOrderPropertyConstant.productId.key, name: undefined },
    { key: workOrderPropertyConstant.productDescription.key, name: undefined },
    { key: workOrderPropertyConstant.workOrderQuantity.key, name: undefined },
    { key: workOrderPropertyConstant.scheduledLine.key, name: undefined },
  ];
  public override dataSubject: BehaviorSubject<any>;

  constructor(public translate: TranslateService) {
    super();
    this.data.forEach((dateType) => {
      dateType.name = this.translate.instant(`filterCard.workOrderProperty.${dateType.key}`);
    });
    this.dataSubject = new BehaviorSubject<object>(this.data);
  }

  public getInitialData(): KeyValueDropdownInterface[] {
    return this.data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class Shift extends FilterClass implements FilterClassInterface {
  override objectNameProp: string = ObjectNameProps.shiftName;

  constructor(public store: Store<oeeAppReducer.OeeAppState>) {
    super();
  }

  public override init(): void {
    this.subscribe = this.store.select('shiftFilter').subscribe((state: FilterShiftState) => {
      if (state.isLoaded && state.isLoading === false) {
        this.data = HelperService.cloneDeep(state.data);
        this.dataSubject.next({ data: this.data, isLoading: false });
      } else {
        this.dataSubject.next({ data: [], isLoading: true });
      }
    });
  }

  public getInitialData(): ShiftCRUDInterface[] {
    let data;
    this.store
      .select('shiftFilter')
      .pipe(take(1))
      .subscribe((state) => {
        data = HelperService.cloneDeep(state.data);
      });
    return data;
  }

  public override getAllData(): void {
    this.store.dispatch(new StartLoadFilterShifts(this.apiServer));
  }

  public override destroy(): void {
    this.store.dispatch(new ShiftFilterActions.ResetStore());
  }
}

@Injectable({
  providedIn: 'root',
})
export class BusinessDateShift extends FilterClass implements FilterClassInterface {
  override objectNameProp: string = ObjectNameProps.shiftName;

  constructor(public store: Store<oeeAppReducer.OeeAppState>) {
    super();
  }

  public override init(): void {
    this.subscribe = this.store.select('shiftFilter').subscribe((state: FilterShiftState) => {
      if (!state.isLoading && state.isLoaded) {
        this.data = HelperService.cloneDeep(state.data);
        this.dataSubject.next(this.data);
      }
    });
  }

  public getInitialData(): ShiftCRUDInterface[] {
    let data;
    this.store
      .select('shiftFilter')
      .pipe(take(1))
      .subscribe((state) => {
        data = HelperService.cloneDeep(state.data);
      });
    return data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class LaborLogsGroupBy extends FilterClass implements FilterClassInterface {
  public override data;
  public override dataSubject: BehaviorSubject<any>;

  constructor(public translate: TranslateService) {
    super();
    this.data = [
      { key: 'none', name: this.translate.instant('reports.checkInLogs.tableGroupBy.none') },
      { key: 'object', name: this.translate.instant('reports.checkInLogs.laborLogs.table.baseTypeOptionName') },
      { key: 'line', name: this.translate.instant('reports.checkInLogs.tableGroupBy.line') },
      { key: 'calendarDate', name: this.translate.instant('general.calendarDate') },
      { key: 'businessDate', name: this.translate.instant('general.businessDate') },
    ];
    this.dataSubject = new BehaviorSubject<object>(this.data);
  }

  getInitialData(): KeyValueDropdownInterface[] {
    return this.data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AssetLogsGroupBy extends FilterClass implements FilterClassInterface {
  public override data;
  public override dataSubject: BehaviorSubject<any>;

  constructor(public translate: TranslateService) {
    super();
    this.data = [
      { key: 'none', name: this.translate.instant('reports.checkInLogs.tableGroupBy.none') },
      { key: 'object', name: this.translate.instant('reports.checkInLogs.assetLogs.table.baseTypeOptionName') },
      { key: 'line', name: this.translate.instant('reports.checkInLogs.tableGroupBy.line') },
      { key: 'calendarDate', name: this.translate.instant('general.calendarDate') },
      { key: 'businessDate', name: this.translate.instant('general.businessDate') },
    ];
    this.dataSubject = new BehaviorSubject<object>(this.data);
  }

  getInitialData(): KeyValueDropdownInterface[] {
    return this.data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class CheckInLogSite extends FilterClass implements FilterClassInterface {
  public options: HttpOptionInterface = {
    page: 1,
    limit: 500,
  };

  constructor(public store: Store<oeeAppReducer.OeeAppState>, public helperService: HelperService) {
    super();
  }

  public override init(): void {
    this.subscribe = this.store.select('checkInLogSiteFilter').subscribe((state) => {
      if (state.isLoaded === true && state.isLoading === false) {
        this.data = _.cloneDeep(state.data).sort(this.helperService.dropdownOptionCompareFunction);
        this.dataSubject.next(this.data);
      }
    });
  }

  public getInitialData(): SiteCRUDInterface[] {
    let data;
    this.store
      .select('checkInLogSiteFilter')
      .pipe(take(1))
      .subscribe((state) => {
        data = HelperService.cloneDeep(state.data);
      });
    return data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class FilterCardUser extends FilterClass implements FilterClassInterface {
  public override objectNameProp: string = ObjectNameProps.fullName;

  constructor(public store: Store<oeeAppReducer.OeeAppState>) {
    super();
  }

  public override init(): void {
    this.subscribe = this.store.select('sourceObjectFilter').subscribe((state: SourceObjectFilterState) => {
      if (state.isLoaded && state.isLoading === false) {
        this.data = HelperService.cloneDeep(state.data);
        this.dataSubject.next(this.data);
      }
    });
  }

  public getInitialData(): IUserObject[] {
    let data;
    this.store
      .select('sourceObjectFilter')
      .pipe(take(1))
      .subscribe((state) => {
        data = HelperService.cloneDeep(state.data);
      });
    return data;
  }

  public override getAllData(): void {
    this.store.dispatch(new StartLoadFilterSourceObjects(SourceObjectTypes.user));
  }
}

@Injectable({
  providedIn: 'root',
})
export class FilterCardEquipment extends FilterClass implements FilterClassInterface {
  public override objectNameProp: string = ObjectNameProps.equipmentName;

  constructor(public store: Store<oeeAppReducer.OeeAppState>) {
    super();
  }

  public override init(): void {
    this.subscribe = this.store.select('sourceObjectFilter').subscribe((state) => {
      if (state.isLoaded && state.isLoading === false) {
        this.data = HelperService.cloneDeep(state.data);
        this.dataSubject.next(this.data);
      }
    });
  }

  public getInitialData(): IEquipmentObject[] {
    let data;
    this.store
      .select('sourceObjectFilter')
      .pipe(take(1))
      .subscribe((state) => {
        data = HelperService.cloneDeep(state.data);
      });
    return data;
  }

  public override getAllData(): void {
    this.store.dispatch(new StartLoadFilterSourceObjects(SourceObjectTypes.equipment));
  }
}

@Injectable({
  providedIn: 'root',
})
export class Activity extends FilterClass implements FilterClassInterface {
  public override objectNameProp: string = ObjectNameProps.activityTypeRelation;
  public options: HttpOptionInterface = {
    page: 1,
    limit: 1000,
    s: JSON.stringify({ activityType: { $ne: ActivityTypes.RUN_TIME } }),
  };

  constructor(public store: Store<oeeAppReducer.OeeAppState>, public translate: TranslateService) {
    super();
  }

  override init(): void {
    this.subscribe = this.store.select('activityFilter').subscribe((state: FilterActivityState) => {
      if (state.isLoaded && state.isLoading === false) {
        this.data = state.data.map((item) => {
          return {
            ...item,
            activityTypeText: this.translate.instant(`general.activityType.${item.activityType}`),
          };
        });
        this.dataSubject.next(this.data);
      }
    });
  }

  public getInitialData(): AlteredActivityCRUDInterface[] {
    let data;
    this.store
      .select('activityFilter')
      .pipe(take(1))
      .subscribe((state: FilterActivityState) => {
        data = state.data.map((item) => {
          return {
            ...item,
            activityTypeText: this.translate.instant(`general.activityType.${item.activityType}`),
          };
        });
      });
    return data;
  }

  public override getAllData(): void {
    this.store.dispatch(new StartLoadFilterActivities(this.options));
  }
}

@Injectable({
  providedIn: 'root',
})
export class Level extends FilterClass implements FilterClassInterface {
  public override objectNameProp: string = ObjectNameProps.level;

  constructor(
    public store: Store<oeeAppReducer.OeeAppState>,
    public helperService: HelperService,
    public userSettingsService: UserSettingsService,
  ) {
    super();
  }

  public override init(): void {
    this.subscribe = this.store.select('mainStore').subscribe((state) => {
      if (state.rolesLoaded === true && state.rolesLoading === false) {
        this.data = _.cloneDeep(state.roles);
        this.dataSubject.next(this.data);
      }
    });
  }

  public getInitialData(): IConstantLookUp[] {
    return this.userSettingsService.getLevels();
  }

  public override getAllData(options?: HttpOptionInterface) {
    this.store.dispatch(new MainActions.Roles());
  }
}

@Injectable({
  providedIn: 'root',
})
export class SystemLevel extends FilterClass implements FilterClassInterface {
  public override objectNameProp: string = ObjectNameProps.level;
  override data = this.userSettingsService.getSystemLevels();

  constructor(
    public store: Store<oeeAppReducer.OeeAppState>,
    public helperService: HelperService,
    public userSettingsService: UserSettingsService,
  ) {
    super();
  }

  public override init(): void {
    this.dataSubject.next(this.data);
  }

  public getInitialData(): IConstantLookUp[] {
    return this.data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class Status extends FilterClass implements FilterClassInterface {
  public override objectNameProp: string = ObjectNameProps.level;
  public statusData = this.helperService.getActiveInactive();

  constructor(public store: Store<oeeAppReducer.OeeAppState>, public helperService: HelperService) {
    super();
  }

  public override init(): void {
    this.data = this.statusData;
    this.dataSubject.next(this.data);
  }

  public getInitialData(): IConstantLookUp[] {
    return this.statusData;
  }
}

@Injectable({
  providedIn: 'root',
})
export class Statuses extends FilterClass implements FilterClassInterface {
  public override objectNameProp: string = ObjectNameProps.level;
  public statusData = this.helperService.getActiveInactiveOfStatus();

  constructor(public helperService: HelperService) {
    super();
  }

  public override init(): void {
    this.data = this.statusData;
    this.dataSubject.next(this.data);
  }

  public getInitialData(): IConstantLookUp[] {
    return this.statusData;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ActivityType extends FilterClass implements FilterClassInterface {
  public override data: DropdownOptionInterface[] = Object.values(ActivityTypes).map(
    (activityType: string): DropdownOptionInterface => {
      return { id: activityType, name: this.translate.instant(`general.activityType.${activityType}`) };
    },
  );

  public override dataSubject: BehaviorSubject<any>;

  constructor(public translate: TranslateService) {
    super();
    this.dataSubject = new BehaviorSubject<object>(this.data);
  }

  public getInitialData(): DropdownOptionInterface[] {
    return this.data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ActivityStatus extends FilterClass implements FilterClassInterface {
  public override data = [
    { id: 0, name: this.translate.instant('general.inactive') },
    { id: 1, name: this.translate.instant('general.active') },
  ];
  public override dataSubject: BehaviorSubject<any>;

  constructor(public translate: TranslateService) {
    super();
    this.dataSubject = new BehaviorSubject<object>(this.data);
  }

  public getInitialData(): IDefaultSelectItem[] {
    return this.data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class NotificationStatus extends FilterClass implements FilterClassInterface {
  public override data: DropdownOptionsInterface[] = [
    { id: 0, name: this.translate.instant('notificationLogs.status.undelivered') },
  ];
  public override dataSubject: BehaviorSubject<any>;

  constructor(public translate: TranslateService) {
    super();
    this.dataSubject = new BehaviorSubject<object>(this.data);
  }

  public getInitialData(): IDefaultSelectItem[] {
    return this.data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class KPICardPage extends FilterClass implements FilterClassInterface {
  public override data = [
    { key: kpiCardConstant.home.key, name: undefined },
    { key: kpiCardConstant.productionReview.key, name: undefined },
  ];
  public override dataSubject: BehaviorSubject<any>;

  constructor(private translate: TranslateService) {
    super();
    this.data.forEach((item) => {
      item.name = this.translate.instant(`filterCard.kpiCardPage.${item.key}`);
    });

    this.dataSubject = new BehaviorSubject<object>(this.data);
  }

  public getInitialData(): KeyValueDropdownInterface[] {
    return this.data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class CountType extends FilterClass implements FilterClassInterface {
  public override data = [
    { id: countTypeConstant.goodCount.id, name: undefined },
    { id: countTypeConstant.initialCount.id, name: undefined },
  ];
  public override dataSubject: BehaviorSubject<any>;

  constructor(private translate: TranslateService) {
    super();
    this.data.forEach((item) => {
      item.name = this.translate.instant(`filterCard.countType.${item.id}`);
    });

    this.dataSubject = new BehaviorSubject<object>(this.data);
  }

  public getInitialData() {
    return this.data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class DurationTypes extends FilterClass implements FilterClassInterface {
  public override data = [
    { id: durationTypesConstant.runTimeDuration.key, name: undefined },
    { id: durationTypesConstant.downTimeDuration.key, name: undefined },
  ];
  public override dataSubject: BehaviorSubject<any>;

  constructor(private translate: TranslateService) {
    super();
    this.data.forEach((item) => {
      item.name = this.translate.instant(`filterCard.durationCalculation.${item.id}`);
    });

    this.dataSubject = new BehaviorSubject<object>(this.data);
  }

  public getInitialData() {
    return this.data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class DateRange extends FilterClass implements FilterClassInterface {
  public override data: ISelectGeneric<DateRangeTypes, string>[] = [
    { id: DateRangeTypes.Today, name: undefined },
    { id: DateRangeTypes.Yesterday, name: undefined },
    { id: DateRangeTypes.ThisWeek, name: undefined },
    { id: DateRangeTypes.NextSevenDays, name: undefined },
    { id: DateRangeTypes.LastWeek, name: undefined },
    { id: DateRangeTypes.ThisMonth, name: undefined },
    { id: DateRangeTypes.NextThirtyDays, name: undefined },
    { id: DateRangeTypes.LastMonth, name: undefined },
    { id: DateRangeTypes.LastThreeMonths, name: undefined },
    { id: DateRangeTypes.ThisYear, name: undefined },
    { id: DateRangeTypes.LastYear, name: undefined },
  ];
  public override dataSubject: BehaviorSubject<any>;

  constructor(public translate: TranslateService) {
    super();
    this.data.forEach((dateType) => {
      dateType.name = this.translate.instant(`filterCard.dateRange.${dateType.id}`);
    });
    this.dataSubject = new BehaviorSubject<object>(this.data);
  }

  public getInitialData(): ISelectGeneric<DateRangeTypes, string>[] {
    return this.data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ActivityWithRunTime extends Activity {
  public override options: HttpOptionInterface = {
    page: 1,
    limit: 1000,
  };

  constructor(public override store: Store<oeeAppReducer.OeeAppState>, public override translate: TranslateService) {
    super(store, translate);
  }
}

@Injectable({
  providedIn: 'root',
})
export class StartOfDay extends FilterClass implements FilterClassInterface {
  public override objectNameProp: string = ObjectNameProps.startOfDay;

  constructor(public store: Store<oeeAppReducer.OeeAppState>) {
    super();
  }

  public override init(): void {
    this.subscribe = this.store.select('startOfDayFilter').subscribe((state) => {
      if (state.data.length === 0 && !state.isLoaded && !state.isLoading) {
        this.store.dispatch(new StartLoadFilterStartOfDay());
      }

      if (state.isLoaded && !state.isLoading) {
        this.data = HelperService.cloneDeep(state.data);
        this.dataSubject.next(this.data);
      }
    });
  }

  public getInitialData(): DropdownOptionsInterface[] {
    let data;
    this.store
      .select('startOfDayFilter')
      .pipe(take(1))
      .subscribe((state) => {
        data = HelperService.cloneDeep(state.data);
      });
    return data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class Sensor extends FilterClass implements FilterClassInterface {
  public override objectNameProp: string = ObjectNameProps.sensor;
  public now = moment().set('hour', 9).format(mysqlTimestampFormatZeroMinAndSec);
  public nowPlusOneDay = moment().set('hour', 9).add(1, 'days').format(mysqlTimestampFormatZeroMinAndSec);
  public options: HttpOptionInterface = {
    page: 1,
    limit: 1000,
    s: JSON.stringify({
      $and: [
        { startDate: { $lte: this.nowPlusOneDay } },
        { endDate: { $gte: this.now } },
        { sensorType: { $notin: ['card_reader', 'sub_sensor', 'temperature', 'humidity'] } },
      ],
    }),
  };

  constructor(public store: Store<oeeAppReducer.OeeAppState>, public translate: TranslateService) {
    super();
  }

  public override init(): void {
    this.subscribe = this.store.select('sensorFilter').subscribe((state) => {
      if (state.isLoaded && !state.isLoading) {
        this.data = this.formatSensorData(HelperService.cloneDeep(state.data));

        this.dataSubject.next(this.data);
      }
    });
  }

  private formatSensorData(data: SensorCRUDRequestInterface[]): SensorCRUDRequestInterface[] {
    return (data ?? [])?.map((sensor: SensorCRUDRequestInterface) => {
      const sensorType: string = this.translate.instant(sensorTypeTranslations[sensor.sensorType]);

      sensor.formattedSensorName = `${sensor.sensorId} (${sensorType}) - ${
        !sensor.lineStation ? sensor.line?.title : `${sensor.lineStation.name} (${sensor.line?.title})`
      }`;

      return sensor;
    });
  }

  public getInitialData(): SensorCRUDRequestInterface[] {
    let data;
    this.store
      .select('sensorFilter')
      .pipe(take(1))
      .subscribe((state) => {
        data = this.formatSensorData(HelperService.cloneDeep(state.data));
      });
    return data;
  }

  public override getAllData(options?: HttpOptionInterface): void {
    this.store.dispatch(new StartLoadFilterSensors({ ...this.options, ...options }));
  }
}

@Injectable({
  providedIn: 'root',
})
export class WorkOrder extends FilterClass implements FilterClassInterface {
  public override objectNameProp: string = ObjectNameProps.woNumber;
  public options: HttpOptionInterface = {
    page: 1,
    limit: 100,
    sort: 'id,DESC',
    join: 'site||decimalScaleLimit',
    fields: 'woNumber,siteId,description',
  };

  constructor(public store: Store<oeeAppReducer.OeeAppState>) {
    super();
  }

  public override init(): void {
    this.store.dispatch(new StartLoadFilterWorkOrders(HelperService.cloneDeep(this.options), this.apiServer));

    this.subscribe = this.store.select('workOrderFilter').subscribe((state: FilterWorkOrderState) => {
      if (state.isLoaded && !state.isLoading) {
        this.data = HelperService.cloneDeep(state.data);
        this.dataSubject.next(this.data);
      }
    });
  }

  public getInitialData(): WorkOrderCRUDInterface[] {
    let data;
    this.store
      .select('workOrderFilter')
      .pipe(take(1))
      .subscribe((state) => {
        data = HelperService.cloneDeep(state.data);
      });
    return data;
  }

  public override getFilterData(options: HttpOptionInterface): void {
    this.options.s = undefined;
    this.options = { ...this.options, ...options };
    this.store.dispatch(new StartLoadFilterWorkOrders(HelperService.cloneDeep(this.options)));
  }

  public override destroy(): void {
    this.store.dispatch(new ResetWorkOrderStore());
  }
}

@Injectable({
  providedIn: 'root',
})
export class User extends FilterClass implements FilterClassInterface {
  public options: HttpOptionInterface = {
    page: 1,
    limit: 50,
    fields: 'fullName',
    sort: 'fullName,ASC',
    s: JSON.stringify({ fullName: { $ne: '' } }),
  };
  public override objectNameProp: string = 'fullName';

  constructor(public store: Store<oeeAppReducer.OeeAppState>) {
    super();
  }

  public override init(): void {
    this.dataSubject.next([]);

    this.subscribe = this.store.select('userFilter').subscribe((state) => {
      if (state.isLoaded && state.isLoading === false) {
        this.data = HelperService.cloneDeep(state.data);
        this.dataSubject.next({
          data: this.data,
          totalCount: state.totalCount,
          allowNewRequest: state.allowNewRequest,
        });
      }
    });
  }

  public getInitialData() {
    let data;
    this.store
      .select('userFilter')
      .pipe(take(1))
      .subscribe((state) => {
        data = HelperService.cloneDeep(state.data);
      });
    return data;
  }

  public override getFilterData(options: HttpOptionInterface, isFetchMoreRequest?: boolean): void {
    this.options = { ...this.options, ...options };

    this.store.dispatch(new StartLoadFilterUsers(...HelperService.argumentClone(this.options, isFetchMoreRequest)));
  }

  public override destroy(): void {
    this.store.dispatch(new ResetUserStore());
  }

  public override getAllData(options?: HttpOptionInterface): void {
    this.store.dispatch(new StartLoadFilterUsers({ ...this.options, ...options }));
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProductFamily extends FilterClass implements FilterClassInterface {
  public options: HttpOptionInterface = {
    page: 1,
    limit: 100,
    sort: 'id,DESC',
  };
  public override data: ProductFamilyCRUDInterface[] = [];
  public override objectNameProp: string = ObjectNameProps.productFamily;

  constructor(public store: Store<oeeAppReducer.OeeAppState>) {
    super();
  }

  public override init(): void {
    this.dataSubject.next([]);

    this.subscribe = this.store.select('productFamilyFilter').subscribe((state) => {
      if (state.isLoaded && state.isLoading === false) {
        this.data = HelperService.cloneDeep(state.data);
        this.dataSubject.next(state.data);
      }
    });
  }

  public getInitialData(): ProductFamilyCRUDInterface[] {
    let data;
    this.store
      .select('productFamilyFilter')
      .pipe(take(1))
      .subscribe((state) => {
        data = HelperService.cloneDeep(state.data);
      });
    return data;
  }

  public override getFilterData(options: HttpOptionInterface, isFetchMoreRequest?: boolean): void {
    this.options = { ...this.options, ...options };

    this.store.dispatch(new StartLoadFilterProductFamily(HelperService.cloneDeep(this.options), isFetchMoreRequest));
  }

  public override getAllData(options?: HttpOptionInterface): void {
    this.store.dispatch(new StartLoadFilterProductFamily({ ...this.options, ...options }));
  }

  public override destroy(): void {
    this.store.dispatch(new ResetProductFamilyStore());
  }
}

@Injectable({
  providedIn: 'root',
})
export class CurrentSitesShift extends FilterClass implements FilterClassInterface {
  override objectNameProp: string = ObjectNameProps.shiftName;

  constructor(public store: Store<oeeAppReducer.OeeAppState>) {
    super();
  }

  public override init(): void {
    let lineId$: number;
    let timeZone: string;
    this.store
      .select('user')
      .pipe(take(1))
      .subscribe((state: IUser) => {
        lineId$ = state.lineId;
        timeZone = state.timezone;
      });
    this.subscribe = this.store.select('shiftFilter').subscribe((state) => {
      if (state.data.length === 0 && state.gotShifts === false && state.getShifts === false) {
        this.store.dispatch(
          new GetShifts({
            lineId: lineId$,
            selectedDate: moment().startOf('day').format(mysqlDateFormat),
          }),
        );
      }
      if (state.gotShifts && state.getShifts === false) {
        this.data = state.shiftsByDate;
        this.dataSubject.next(this.data);
      }
    });
  }

  public getInitialData(): ShiftCRUDInterface[] {
    let data;
    this.store
      .select('shiftFilter')
      .pipe(take(1))
      .subscribe((state) => {
        data = HelperService.cloneDeep(state.data);
      });
    return data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class Equipment extends FilterClass implements FilterClassInterface {
  public override objectNameProp: string = ObjectNameProps.equipmentName;
  public options: HttpOptionInterface = {
    page: 1,
    limit: 100,
    sort: 'id,DESC',
  };

  constructor(public store: Store<oeeAppReducer.OeeAppState>) {
    super();
  }

  public override init(): void {
    this.dataSubject.next([]);
    this.store.dispatch(new StartLoadFilterEquipments(HelperService.cloneDeep(this.options)));

    this.subscribe = this.store.select('equipmentFilter').subscribe((state: FilterEquipmentState) => {
      if (state.isLoaded && !state.isLoading) {
        this.data = HelperService.cloneDeep(state.data);
        this.dataSubject.next(this.data);
      }
    });
  }

  public getInitialData(): EquipmentListInterface[] {
    let data;
    this.store
      .select('equipmentFilter')
      .pipe(take(1))
      .subscribe((state: FilterEquipmentState) => {
        data = HelperService.cloneDeep(state.data);
      });
    return data;
  }

  public override getFilterData(options: HttpOptionInterface): void {
    this.options = { ...this.options, ...options };

    this.store.dispatch(new StartLoadFilterEquipments(HelperService.cloneDeep(this.options)));
  }

  public override getAllData(): void {
    this.store.dispatch(new StartLoadFilterEquipments(this.options));
  }

  public override destroy(): void {
    this.store.dispatch(new ResetEquipmentStore());
  }
}

@Injectable({
  providedIn: 'root',
})
export class Task extends FilterClass implements FilterClassInterface {
  public override objectNameProp: string = ObjectNameProps.task;
  public options: HttpOptionInterface = {
    fields: 'title,activityId,siteId,lineId,equipmentId',
    groupBy: 'title',
    limit: 500,
  };

  constructor(public store: Store<oeeAppReducer.OeeAppState>) {
    super();
  }

  public override init(): void {
    this.dataSubject.next([]);
    this.store.dispatch(new StartLoadFilterTasks(HelperService.cloneDeep(this.options)));

    this.subscribe = this.store.select('taskFilter').subscribe((state: FilterTaskState) => {
      if (state.isLoaded && !state.isLoading) {
        this.data = HelperService.cloneDeep(state.data);
        this.dataSubject.next(this.data);
      }
    });
  }

  public getInitialData(): TaskFilterCRUDInterface[] {
    let data;

    this.store
      .select('taskFilter')
      .pipe(take(1))
      .subscribe((state: FilterTaskState) => {
        data = HelperService.cloneDeep(state.data);
      });

    return data;
  }

  public override getFilterData(options?: HttpOptionInterface, isFetchMoreRequest?: boolean, searchInput?: string): void {
    this.options = { ...this.options, ...options };
    const searchProcess: boolean =  searchInput !== '';

    this.store.dispatch(new StartLoadFilterTasks(HelperService.cloneDeep(this.options), searchProcess));
  }

  public override getAllData(options?: HttpOptionInterface): void {
    this.store.dispatch(new StartLoadFilterTasks(HelperService.cloneDeep({ ...this.options, ...options })));
  }

  public override

  public override destroy(): void {
    this.data = [];

    this.store.dispatch(new ResetTaskStore());
  }
}

@Injectable({
  providedIn: 'root',
})
export class AnalysisMode extends FilterClass implements FilterClassInterface {
  public override data: DropdownOptionInterface[] = Object.values(ETaskPerformanceAnalysisMode).map(
    (analysisMode: string): DropdownOptionInterface => {
      return {
        id: analysisMode,
        name: this.translate.instant(`taskPerformance.analysisMode.${analysisMode}`),
      };
    },
  );

  public override dataSubject: BehaviorSubject<any>;

  constructor(public translate: TranslateService, public helperService: HelperService) {
    super();
    this.dataSubject = new BehaviorSubject<object>(this.data);
  }

  public getInitialData(): DropdownOptionInterface[] {
    return this.data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ScheduleAdherenceAnalysisMode extends FilterClass implements FilterClassInterface {
  public override data: DropdownOptionInterface[] = Object.values(EScheduleAdherenceAnalysisMode).map(
    (analysisMode: string): DropdownOptionInterface => {
      return {
        id: analysisMode,
        name: this.translate.instant(`scheduleAdherence.analysisMode.${analysisMode}`),
      };
    },
  );

  public override dataSubject: BehaviorSubject<any>;

  constructor(public translate: TranslateService, public helperService: HelperService) {
    super();
    this.dataSubject = new BehaviorSubject<object>(this.data);
  }

  public getInitialData(): DropdownOptionInterface[] {
    return this.data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class KpiViewGroupBy extends FilterClass implements FilterClassInterface {
  public override data = [
    {
      id: 'WEEK',
      name: `${this.translate.instant('kpiView.filterCard.groupBy.dropdownPlaceHolder')}: ${this.translate.instant(
        'general.week',
      )}`,
    },
    {
      id: 'MONTH',
      name: `${this.translate.instant('kpiView.filterCard.groupBy.dropdownPlaceHolder')}: ${this.translate.instant(
        'general.month',
      )}`,
    },
  ];
  public override dataSubject: BehaviorSubject<any>;

  constructor(public translate: TranslateService) {
    super();
    this.dataSubject = new BehaviorSubject<object>(this.data);
  }

  public getInitialData(): DropdownOptionInterface[] {
    return this.data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ActivityHistoryWorkOrderStatus extends FilterClass implements FilterClassInterface {
  public override objectNameProp: string = ObjectNameProps.woStatus;
  public statusData = [
    { id: 0, name: this.translate.instant('general.inProgress') },
    { id: 1, name: this.translate.instant('general.completed') },
    { id: 2, name: this.translate.instant('workOrder.completeStatus.canceled') },
  ];

  constructor(public store: Store<oeeAppReducer.OeeAppState>, public translate: TranslateService) {
    super();
  }

  public override init(): void {
    this.data = this.statusData;
    this.dataSubject.next(this.data);
  }

  public getInitialData(): IConstantLookUp[] {
    return this.statusData;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AlertFunction extends FilterClass implements FilterClassInterface {
  public override objectNameProp: string = ObjectNameProps.level;
  public alertFunctions = [
    {
      id: 'alerts_activity_running',
      name: this.translate.instant('alertSettings.functions.alerts_activity_running'),
    },
    {
      id: 'alerts_counter_positive',
      name: this.translate.instant('alertSettings.functions.alerts_counter_positive'),
    },
    { id: 'alerts_counter_zero', name: this.translate.instant('alertSettings.functions.alerts_counter_zero') },
    { id: 'alerts_time_triggered', name: this.translate.instant('alertSettings.functions.alerts_time_triggered') },
    {
      id: 'alerts_vibration_positive',
      name: this.translate.instant('alertSettings.functions.alerts_vibration_positive'),
    },
    { id: 'alerts_vibration_zero', name: this.translate.instant('alertSettings.functions.alerts_vibration_zero') },
  ];

  constructor(public translate: TranslateService) {
    super();
  }

  public override init(): void {
    this.data = this.alertFunctions;
    this.dataSubject.next(this.data);
  }

  public getInitialData(): IConstantLookUp[] {
    return this.alertFunctions;
  }
}

@Injectable({
  providedIn: 'root',
})
export class MyReportBreakdown extends FilterClass implements FilterClassInterface {
  public override data: DropdownOptionInterface[] = [
    {
      id: EMyReportMetricBreakdown.HOURLY,
      name: this.translate.instant('filterCard.dateBreakdown.type.hourly'),
    },
    {
      id: EMyReportMetricBreakdown.DAILY,
      name: this.translate.instant('filterCard.dateBreakdown.type.daily'),
    },
    {
      id: EMyReportMetricBreakdown.WEEKLY,
      name: this.translate.instant('filterCard.dateBreakdown.type.weekly'),
    },
    {
      id: EMyReportMetricBreakdown.MONTHLY,
      name: this.translate.instant('filterCard.dateBreakdown.type.monthly'),
    },
  ];

  constructor(public store: Store<oeeAppReducer.OeeAppState>, public translate: TranslateService) {
    super();
  }

  public override init(): void {
    this.store.dispatch(new FilterMyReportBreakdownActions.SetFilterBreakdownOptions(_.cloneDeep(this.data)));

    this.subscribe = this.store.select('myReportBreakdownFilter').subscribe((state: IFilterMyReportBreakdownState) => {
      this.data = _.cloneDeep(state.data);
      this.dataSubject.next(this.data);
    });
  }

  public getInitialData(): DropdownOptionInterface[] {
    let data: DropdownOptionInterface[];
    this.store
      .select('myReportBreakdownFilter')
      .pipe(take(1))
      .subscribe((state: IFilterMyReportBreakdownState) => {
        data = state.data;
      });
    return data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class CommentLogsObjectType extends FilterClass implements FilterClassInterface {
  public override data: DropdownOptionInterface[] = Object.values(ECommentLogsObjectType).map(
    (objectType: string): DropdownOptionInterface => {
      return { id: objectType, name: this.translate.instant(`commentLogs.body.objectType.${_.camelCase(objectType)}`) };
    },
  );

  public override dataSubject: BehaviorSubject<any>;

  constructor(public translate: TranslateService) {
    super();
    this.dataSubject = new BehaviorSubject<object>(this.data);
  }

  public getInitialData(): DropdownOptionInterface[] {
    return this.data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class CommentLogsCommentType extends FilterClass implements FilterClassInterface {
  public override data: ICommentTypeFilterCardOption[] = Object.values(ECommentLogsCommentType).map(
    (commentType: ECommentLogsCommentType): ICommentTypeFilterCardOption => {
      return {
        id: commentType,
        name: this.translate.instant(`commentLogs.body.commentType.${_.camelCase(commentType)}`),
        associatedTagType: commentTypeTagTypeRelation[commentType] ?? null,
      };
    },
  );

  public override dataSubject: BehaviorSubject<any>;

  constructor(public translate: TranslateService) {
    super();
    this.dataSubject = new BehaviorSubject<object>(this.data);
  }

  public getInitialData(): DropdownOptionInterface[] {
    return this.data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class CommentLogsObjectProperty extends FilterClass implements FilterClassInterface {
  public override data: DropdownOptionInterface[] = Object.values(ECommentLogsObjectProperty).map(
    (objectProperty: string): DropdownOptionInterface => {
      return {
        id: objectProperty,
        name: this.translate.instant(`commentLogs.body.objectProperty.${_.camelCase(objectProperty)}`),
      };
    },
  );

  public override dataSubject: BehaviorSubject<any>;

  constructor(public translate: TranslateService) {
    super();
    this.dataSubject = new BehaviorSubject<object>(this.data);
  }

  public getInitialData(): DropdownOptionInterface[] {
    return this.data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProductResourceGroupsResourceType extends FilterClass implements FilterClassInterface {
  public override data: DropdownOptionInterface[] = Object.values(EProductResourceGroupsResourceType).map(
    (resourceType: string): DropdownOptionInterface => {
      return {
        id: EProductResourceGroupsResourceTypeValue[resourceType],
        name: this.translate.instant(`general.${_.camelCase(resourceType)}`),
      };
    },
  );

  public override dataSubject: BehaviorSubject<any>;

  constructor(public translate: TranslateService) {
    super();
    this.dataSubject = new BehaviorSubject<object>(this.data);
  }

  public getInitialData(): DropdownOptionInterface[] {
    return this.data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class FloorPlan extends FilterClass implements FilterClassInterface {
  public options: HttpOptionInterface = {
    page: 1,
    limit: 500,
    sort: 'id,DESC',
  };

  constructor(public store: Store<oeeAppReducer.OeeAppState>, public helperService: HelperService) {
    super();
  }

  public override init(): void {
    this.subscribe = this.store.select('floorPlanFilter').subscribe((state: FilterFloorPlanState): void => {
      if (state.data.length === 0 && state.isLoaded === false && state.isLoading === false) {
        this.store.dispatch(new FilterFloorPlansLoading(this.options));
      }
      if (state.isLoaded === true && state.isLoading === false) {
        this.data = HelperService.cloneDeep(state.data).sort(GenericHelperService.dropdownOptionCompareFunction);
        this.dataSubject.next(this.data);
      }
    });
  }

  public getInitialData(): FloorPlanCRUDInterface[] {
    let data: FloorPlanCRUDInterface[];
    this.store
      .select('floorPlanFilter')
      .pipe(take(1))
      .subscribe((state: FilterFloorPlanState): void => {
        data = HelperService.cloneDeep(state.data).sort(GenericHelperService.dropdownOptionCompareFunction);
      });
    return data;
  }

  public override getAllData(options?: HttpOptionInterface): void {
    this.store.dispatch(new FilterFloorPlansLoading({ ...this.options, ...options }));
  }
}

@Injectable({
  providedIn: 'root',
})
export class FloorPlanSite extends FloorPlan {
  override options: HttpOptionInterface = {
    page: 1,
    limit: 500,
    sort: 'id,DESC',
    s: JSON.stringify({ categoryForId: { $eq: EFloorPlanCategoryForId.FLOOR_PLAN_FOR_SITE } }),
  };
}

@Injectable({
  providedIn: 'root',
})
export class FloorPlanLine extends FloorPlan {
  override options: HttpOptionInterface = {
    page: 1,
    limit: 500,
    sort: 'id,DESC',
    s: JSON.stringify({ categoryForId: { $eq: EFloorPlanCategoryForId.FLOOR_PLAN_FOR_LINE } }),
  };
}

@Injectable({
  providedIn: 'root',
})
export class CustomerSegment extends FilterClass implements FilterClassInterface {
  public options: HttpOptionInterface = {
    page: 1,
    limit: 500,
  };

  constructor(public store: Store<oeeAppReducer.OeeAppState>, public helperService: HelperService) {
    super();
  }

  public override init(): void {
    this.subscribe = this.store.select('customerSegmentFilter').subscribe((state) => {
      if (state.isLoaded === true && state.isLoading === false) {
        this.data = _.cloneDeep(state.data);
        this.dataSubject.next(this.data);
      }
    });
  }

  public getInitialData() {
    let data;
    this.store
      .select('customerSegmentFilter')
      .pipe(take(1))
      .subscribe((state) => {
        data = HelperService.cloneDeep(state.data);
      });
    return data;
  }

  public override getAllData(options?: HttpOptionInterface): void {
    this.store.dispatch(
      new CustomerSegmentFilterActions.StartLoadFilterCustomerSegments({ ...this.options, ...options }, this.apiServer),
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class Customer extends FilterClass implements FilterClassInterface {
  public options: HttpOptionInterface = {
    page: 1,
    limit: 500,
  };

  constructor(public store: Store<oeeAppReducer.OeeAppState>, public translate: TranslateService) {
    super();
  }

  override init(): void {
    this.subscribe = this.store.select('customerFilter').subscribe((state: FilterCustomerState) => {
      if (state.isLoaded && state.isLoading === false) {
        this.data = state.data.map((item: Partial<CustomerInterface>) => {
          return {
            ...item,
            customerSegmentName: item.customerSegmentId
              ? item.customerSegment.name
              : this.translate.instant('filterCard.customerFilter.nonSegmentedCustomers'),
          };
        }) as CustomerInterface[];
        this.dataSubject.next(this.data);
      }
    });
  }

  public getInitialData(): AlteredActivityCRUDInterface[] {
    let data;
    this.store
      .select('customerFilter')
      .pipe(take(1))
      .subscribe((state: FilterCustomerState) => {
        data = state.data.map((item: Partial<CustomerInterface>) => {
          return {
            ...item,
            customerSegmentName: item.customerSegmentId
              ? item.customerSegment.name
              : this.translate.instant('filterCard.customerFilter.nonSegmentedCustomers'),
          };
        });
      });
    return data;
  }

  public override getAllData(options?: HttpOptionInterface): void {
    this.store.dispatch(new FilterCustomerActions.StartLoadFilterCustomers({ ...this.options, ...options }));
  }
}

export class TagBase extends FilterClass implements FilterClassInterface {
  public override data: TagInterface[] = [];

  constructor(
    protected options: HttpOptionInterface,
    protected store: Store<oeeAppReducer.OeeAppState>,
    protected actionsSubject: ActionsSubject,
    protected translate: TranslateService,
    protected tagService: TagsService,
  ) {
    super();
    this.options = options;
  }

  public override init(): void {
    this.dataSubject.next([]);
    this.subscribe = this.actionsSubject
      .pipe(ofType(TagActions.ActionTypes.GetTagsLoaded))
      .subscribe((payload: TagActions.GetTagsLoaded) => {
        this.data = HelperService.cloneDeep(payload.response.data).map(this.tagService.assignTagName);
        this.dataSubject.next(this.data);
      });
  }

  public getInitialData(): TagInterface[] {
    return this.data;
  }

  public override getFilterData(options: HttpOptionInterface): void {
    const combinedSearch: { $and: any[] } = HelperService.cloneDeep(JSON.parse(options.s));

    if (this.options.s) {
      combinedSearch.$and.push(JSON.parse(this.options.s));
    }

    this.store.dispatch(
      new TagActions.GetTags(HelperService.cloneDeep({ ...this.options, s: JSON.stringify(combinedSearch) })),
    );
  }

  public override getAllData(options?: HttpOptionInterface): void {
    this.store.dispatch(new TagActions.GetTags({ ...this.options, ...options }));
  }

  public override destroy(): void {
    this.store.dispatch(new TagActions.ResetStore());
  }
}

@Injectable({
  providedIn: 'root',
})
export class CommentTags extends TagBase {
  constructor(
    protected override store: Store<oeeAppReducer.OeeAppState>,
    protected override actionsSubject: ActionsSubject,
    protected override translate: TranslateService,
    protected override tagService: TagsService,
  ) {
    super(
      {
        page: 1,
        limit: 100,
        sort: 'id,DESC',
        s: JSON.stringify({
          $or: [
            { objectType: { $eq: TagsObjectTypes.Comment } },
            { objectType: { $eq: TagsObjectTypes.ScrapCountComment } },
          ],
        }),
      },
      store,
      actionsSubject,
      translate,
      tagService,
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class OpcTag extends FilterClass implements FilterClassInterface {
  public options: HttpOptionInterface = {
    page: 1,
    limit: 500,
  };

  public override objectNameProp: string = ObjectNameProps.opcTagName;

  constructor(public store: Store<oeeAppReducer.OeeAppState>) {
    super();
  }

  public override init(): void {
    this.subscribe = this.store.select('opcTagFilter').subscribe((state: FilterOpcTagState) => {
      if (state.isLoaded && !state.isLoading) {
        this.data = _.cloneDeep(state.data);
        this.dataSubject.next(this.data);
      }
    });
  }

  public getInitialData(): IOpcTagList[] {
    let data;
    this.store
      .select('opcTagFilter')
      .pipe(take(1))
      .subscribe((state: FilterOpcTagState) => {
        data = _.cloneDeep(state.data);
      });
    return data;
  }

  public override getAllData(options?: HttpOptionInterface): void {
    this.options = { ...this.options, ...options };
    this.store.dispatch(new StartLoadFilterOpcTag(HelperService.cloneDeep(this.options)));
  }

  public override destroy(): void {
    this.store.dispatch(new ResetOpcTagStore());
  }
}

@Injectable({
  providedIn: 'root',
})
export class RootCauseAnalysisCommentTags extends TagBase {
  constructor(
    protected override store: Store<oeeAppReducer.OeeAppState>,
    protected override actionsSubject: ActionsSubject,
    protected override translate: TranslateService,
    protected override tagService: TagsService,
  ) {
    super(
      {
        page: 1,
        limit: 100,
        sort: 'id,DESC',
        s: JSON.stringify({ objectType: { $ne: TagsObjectTypes.ScrapCountComment } }),
      },
      store,
      actionsSubject,
      translate,
      tagService,
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProductTransitionDurationType extends FilterClass implements FilterClassInterface {
  public override objectNameProp: string = ObjectNameProps.productTransitionDuration;
  public durationOptions: DropdownOptionInterface[] = [
    {
      id: EProductOrFamilyDurationType.PreRun,
      name: this.translate.instant('productTransitionMatrix.preview.durationType.pre'),
    },
    {
      id: EProductOrFamilyDurationType.PostRun,
      name: this.translate.instant('productTransitionMatrix.preview.durationType.post'),
    },
    {
      id: EProductOrFamilyDurationType.TotalDuration,
      name: this.translate.instant('productTransitionMatrix.preview.durationType.total'),
    },
  ];

  constructor(public translate: TranslateService) {
    super();
  }

  public override init(): void {
    this.data = this.durationOptions;
    this.dataSubject.next(this.data);
  }

  public getInitialData(): DropdownOptionInterface[] {
    return this.durationOptions;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProductTransitionCompare extends FilterClass implements FilterClassInterface {
  public override objectNameProp: string = ObjectNameProps.productTransitionCompare;
  public transitionOptions: DropdownOptionInterface[] = [
    {
      id: EProductOrFamilyCompareOptions.productToProduct,
      name: this.translate.instant('productTransitionMatrix.preview.compareType.productToProduct'),
    },
    {
      id: EProductOrFamilyCompareOptions.productToProductFamily,
      name: this.translate.instant('productTransitionMatrix.preview.compareType.productToProductFamily'),
    },
    {
      id: EProductOrFamilyCompareOptions.productFamilyToProduct,
      name: this.translate.instant('productTransitionMatrix.preview.compareType.productFamilyToProduct'),
    },
    {
      id: EProductOrFamilyCompareOptions.productFamilyToProductFamily,
      name: this.translate.instant('productTransitionMatrix.preview.compareType.productFamilyToProductFamily'),
    },
  ];

  constructor(public translate: TranslateService) {
    super();
  }

  public override init(): void {
    this.data = this.transitionOptions;
    this.dataSubject.next(this.data);
  }

  public getInitialData(): DropdownOptionInterface[] {
    return this.transitionOptions;
  }
}

@Injectable({
  providedIn: 'root',
})
export class FromProductTransitionMatrixType extends FilterClass implements FilterClassInterface {
  public options: HttpOptionInterface = {
    page: 1,
    limit: 100,
    sort: 'id,DESC',
  };
  public override data: ProductDropdownItemInterface[] | ProductFamilyCRUDInterface[] = [];
  public override objectNameProp: string = ObjectNameProps.productOrProductFamily;
  public filterType: EProductOrFamilyFilterType;
  public compareType: EProductOrFamilyCompareOptions = EProductOrFamilyCompareOptions.productToProduct;

  constructor(
    public store: Store<oeeAppReducer.OeeAppState>,
    public helperService: HelperService,
    public translate: TranslateService,
  ) {
    super();
  }

  public override init(): void {
    this.dataSubject.next([]);
    this.subscribe = this.store
      .select('productOrProductFamilyFilter')
      .subscribe((state: FilterProductOrProductFamilyState) => {
        if (state.isLoaded && state.isLoading === false) {
          this.data = state.data.map((item) => {
            return {
              ...item,
              name:
                this.filterType === EProductOrFamilyFilterType.Product
                  ? `${item.productId} - ${item.description}`
                  : item.name,
              compareType: this.compareType,
            };
          });
          this.dataSubject.next({
            data: this.data,
            totalCount: state.totalCount,
            allowNewRequest: state.allowNewRequest,
          });
        }
      });
  }

  public getInitialData(): ProductDropdownItemInterface[] | ProductFamilyCRUDInterface[] {
    this.store.select('productOrProductFamilyFilter').subscribe((state: FilterProductOrProductFamilyState) => {
      if (state.isLoaded && state.isLoading === false) {
        this.data = state.data.map((item) => {
          return {
            ...item,
            name:
              this.filterType === EProductOrFamilyFilterType.Product
                ? `${item?.productId} - ${item?.description}`
                : item?.name,
            compareType: this.compareType,
          };
        });

        this.dataSubject.next({
          data: this.data,
          totalCount: state.totalCount,
          allowNewRequest: state.allowNewRequest,
        });
      }
    });
    return this.data;
  }

  public override getFilterData(options: HttpOptionInterface, isFetchMoreRequest?: boolean): void {
    this.options = { ...this.options, ...options };

    this.setFilterType(options);
    this.store.dispatch(
      new StartLoadFilterProductOrProductFamily(
        ...HelperService.argumentClone(this.options, isFetchMoreRequest, this.filterType),
      ),
    );
  }

  public setFilterType(options: HttpOptionInterface): void {
    try {
      let filterType: EProductOrFamilyFilterType = EProductOrFamilyFilterType.Product;
      let selectedCompareOption: EProductOrFamilyCompareOptions;
      const jsonObject: JSON = JSON.parse(options.s);
      const searchParams: string = jsonObject['$and'][0]['$or'];
      let compareType: string = jsonObject['$and'][0]['compareType'];

      if (searchParams) {
        compareType = jsonObject['$and'][1]['compareType'];
      }

      if (compareType) {
        try {
          selectedCompareOption = compareType['$in'][0];
        } catch (compareTypeError) {
          this.helperService.showToastMessage(false, null, this.translate.instant('general.error'));
        }
      }

      if (
        selectedCompareOption === EProductOrFamilyCompareOptions.productFamilyToProductFamily ||
        selectedCompareOption === EProductOrFamilyCompareOptions.productFamilyToProduct
      ) {
        filterType = EProductOrFamilyFilterType.ProductFamily;
      }

      this.compareType = selectedCompareOption;
      this.filterType = filterType;
    } catch (e) {
      this.helperService.showToastMessage(false, null, this.translate.instant('general.error'));
    }
  }

  public override destroy(): void {
    this.store.dispatch(new ResetProductOrProductFamilyStore());
  }
}

@Injectable({
  providedIn: 'root',
})
export class ToProductTransitionMatrixType extends FromProductTransitionMatrixType implements FilterClassInterface {
  public override setFilterType(options: HttpOptionInterface): void {
    try {
      let filterType: EProductOrFamilyFilterType = EProductOrFamilyFilterType.Product;
      let selectedCompareOption: EProductOrFamilyCompareOptions;
      const jsonObject: JSON = JSON.parse(options.s);
      const searchParams: string = jsonObject['$and'][0]['$or'];
      let compareType: string = jsonObject['$and'][0]['compareType'];

      if (searchParams) {
        compareType = jsonObject['$and'][1]['compareType'];
      }

      if (compareType) {
        try {
          selectedCompareOption = compareType['$in'][0];
        } catch (compareTypeError) {
          this.helperService.showToastMessage(false, null, this.translate.instant('general.error'));
        }
      }

      if (
        selectedCompareOption === EProductOrFamilyCompareOptions.productFamilyToProductFamily ||
        selectedCompareOption === EProductOrFamilyCompareOptions.productToProductFamily
      ) {
        filterType = EProductOrFamilyFilterType.ProductFamily;
      }

      this.compareType = selectedCompareOption;
      this.filterType = filterType;
    } catch (jsonParseError) {
      this.helperService.showToastMessage(false, null, this.translate.instant('general.error'));
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class LineFilterWithNullOption extends FilterClass implements FilterClassInterface {
  private nullLine: any = {
    id: 0,
    title: this.translate.instant('productTransitionMatrix.filter.noLine'),
  };
  private filterStoreSubscription: Subscription;

  public override objectNameProp: string = ObjectNameProps.title;
  public options: HttpOptionInterface = {
    page: 1,
    limit: 500,
  };

  constructor(
    public store: Store<oeeAppReducer.OeeAppState>,
    private helperService: HelperService,
    private translate: TranslateService,
  ) {
    super();
  }

  public override init(): void {
    this.subscribe = this.store.select('lineFilter').subscribe((state) => {
      if (state.isLoaded && state.isLoading === false) {
        this.data = _.cloneDeep(state.data).sort(Line.lineCompareFunction);
        this.setNullOption();
        this.dataSubject.next(this.data);
        this.filterStoreSubscription.unsubscribe();
      }
    });
  }

  private setNullOption(): void {
    try {
      this.filterStoreSubscription = this.store.select('filterStore').subscribe((state: IFilterState) => {
        const siteData: IDropdownData[] = state.data;
        const selectedSiteId: number = siteData
          .find((item: IDropdownData) => item.elementId === EFilterDropdownElements.siteSingleSelectDropdown)
          ?.values.find((site: IDependPropertyValue) => site.property === 'id')?.values[0] as number;

        if (this.options.s) {
          try {
            const jsonObject: JSON = JSON.parse(this.options.s);
            const searchParams: string = jsonObject['$and'][0]['$or'];

            if (!searchParams) {
              this.nullLine.siteId = selectedSiteId;
              this.data.unshift(this.nullLine as LineCRUDInterface);
            }
          } catch (jsonParseError) {
            this.helperService.showToastMessage(false, null, this.translate.instant('general.error'));
          }
        }
      });
    } catch (subscriptionError) {
      this.helperService.showToastMessage(false, null, this.translate.instant('general.error'));
    }
  }

  public getInitialData(): LineCRUDInterface[] {
    let data;
    this.store
      .select('lineFilter')
      .pipe(take(1))
      .subscribe((state) => {
        data = _.cloneDeep(state.data).sort(Line.lineCompareFunction);
      });
    return data;
  }

  public override getFilterData(options: HttpOptionInterface, isFetchMoreRequest?: boolean): void {
    this.options = { ...this.options, ...options };
    this.store.dispatch(new StartLoadFilterLines(...HelperService.argumentClone(this.options)));
  }
}

@Injectable({
  providedIn: 'root',
})
export class AutomationCenterRuleStatusType extends FilterClass implements FilterClassInterface {
  public override data = [
    {
      key: automationCenterRuleStatusConstant.active.key,
      name: undefined,
      id: automationCenterRuleStatusConstant.active.id,
    },
    {
      key: automationCenterRuleStatusConstant.inactive.key,
      name: undefined,
      id: automationCenterRuleStatusConstant.inactive.id,
    },
    {
      key: automationCenterRuleStatusConstant.draft.key,
      name: undefined,
      id: automationCenterRuleStatusConstant.draft.id,
    },
  ];
  public override dataSubject: BehaviorSubject<any>;

  constructor(public translate: TranslateService) {
    super();
    this.data.forEach((dateType) => {
      dateType.name = this.translate.instant(`general.${dateType.key}`);
    });
    this.dataSubject = new BehaviorSubject<object>(this.data);
  }

  public getInitialData(): DropdownOptionInterface[] {
    return this.data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class RoleType extends FilterClass implements FilterClassInterface {
  public override data: DropdownOptionInterface[] = [
    {
      id: 'custom',
      name: this.translate.instant('filterCard.roleType.custom'),
    },
    {
      id: 'system',
      name: this.translate.instant('filterCard.roleType.system'),
    },
  ];
  public override dataSubject: BehaviorSubject<DropdownOptionInterface[]>;

  constructor(public translate: TranslateService) {
    super();
    this.dataSubject = new BehaviorSubject<DropdownOptionInterface[]>(this.data);
  }

  public getInitialData(): DropdownOptionInterface[] {
    return this.data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class NotificationLogsNotificationType extends FilterClass implements FilterClassInterface {
  public override data: DropdownOptionInterface[] = Object.values(ENotificationType).map(
    (notificationType: string): DropdownOptionInterface => {
      return {
        id: notificationType,
        name: this.translate.instant(`notificationLogs.type.${_.camelCase(notificationType)}`),
      };
    },
  );

  public override dataSubject: BehaviorSubject<DropdownOptionInterface[]> = new BehaviorSubject<
    DropdownOptionInterface[]
  >(this.data);

  constructor(public translate: TranslateService) {
    super();
  }

  public getInitialData(): DropdownOptionInterface[] {
    return this.data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class NotificationRecipientUser extends FilterClass implements FilterClassInterface {
  public options: HttpOptionInterface = {
    page: 1,
    limit: 1000,
    sort: 'id,DESC',
  };

  constructor(public store: Store<oeeAppReducer.OeeAppState>, public helperService: HelperService) {
    super();
  }

  public override init(): void {
    this.subscribe = this.store
      .select('notificationRecipientUserFilter')
      .subscribe((state: FilterNotificationRecipientUserState): void => {
        if (state.data.length === 0 && !state.isLoaded && !state.isLoading) {
          this.store.dispatch(new FilterNotificationRecipientUsersLoading(this.options));
        }

        if (state.isLoaded && !state.isLoading) {
          this.data = HelperService.cloneDeep(state.data);
          this.dataSubject.next(this.data);
        }
      });
  }

  public getInitialData(): NotificationRecipientUserCRUDInterface[] {
    let data: NotificationRecipientUserCRUDInterface[];
    this.store
      .select('notificationRecipientUserFilter')
      .pipe(take(1))
      .subscribe((state: FilterNotificationRecipientUserState): void => {
        data = HelperService.cloneDeep(state.data);
      });
    return data;
  }

  public override getAllData(options?: HttpOptionInterface): void {
    this.store.dispatch(new FilterNotificationRecipientUsersLoading({ ...this.options, ...options }));
  }

  public override getFilterData(options: HttpOptionInterface): void {
    this.options = { ...this.options, ...options };
    this.store.dispatch(new FilterNotificationRecipientUsersLoading(this.options));
  }
}

@Injectable({
  providedIn: 'root',
})
export class LineStation extends FilterClass implements FilterClassInterface {
  public options: HttpOptionInterface = {
    page: 1,
    limit: 500,
  };

  constructor(public store: Store<oeeAppReducer.OeeAppState>) {
    super();
  }

  public override init(): void {
    this.subscribe = this.store.select('lineStationFilter').subscribe((state: FilterLineStationState) => {
      if (state.isLoaded && state.isLoading === false) {
        this.data = HelperService.cloneDeep(state.data);
        this.dataSubject.next(this.data);
      }
    });
  }

  public getInitialData(): LineStationCRUDInterface[] {
    let data: LineStationCRUDInterface[];

    this.store
      .select('lineStationFilter')
      .pipe(take(1))
      .subscribe((state: FilterLineStationState): void => {
        data = HelperService.cloneDeep(state.data);
      });
    return data;
  }

  public override getAllData(options?: HttpOptionInterface): void {
    this.store.dispatch(new StartLoadFilterLineStations({ ...this.options, ...options }));
  }
}

@Injectable({
  providedIn: 'root',
})
export class Job extends FilterClass implements FilterClassInterface {
  public override objectNameProp: string = ObjectNameProps.jobName;
  public options: HttpOptionInterface = {
    page: 1,
    limit: 1000,
    sort: 'id,DESC',
    fields: 'siteId,jobName',
  };

  constructor(public store: Store<OeeAppState>) {
    super();
  }

  public override init(): void {
    this.dataSubject.next([]);
    this.store.dispatch(new FilterJobsLoading(HelperService.cloneDeep(this.options)));

    this.subscribe = this.store.select('jobFilter').subscribe((state: FilterJobState) => {
      if (state.isLoaded && !state.isLoading) {
        this.data = HelperService.cloneDeep(state.data);
        this.dataSubject.next(this.data);
      }
    });
  }

  public getInitialData(): TaskFilterCRUDInterface[] {
    let data;

    this.store
      .select('jobFilter')
      .pipe(take(1))
      .subscribe((state: FilterJobState) => {
        data = HelperService.cloneDeep(state.data);
      });

    return data;
  }

  public override getAllData(options?: HttpOptionInterface): void {
    this.store.dispatch(new FilterJobsLoading({ ...this.options, ...options }));
  }

  public override getFilterData(options: HttpOptionInterface): void {
    this.options = { ...this.options, ...options };

    this.store.dispatch(new FilterJobsLoading(HelperService.cloneDeep(this.options)));
  }

  public override destroy() {
    this.store.dispatch(new ResetJobsStore());
  }
}

@Injectable({
  providedIn: 'root',
})
export class JobStatus extends FilterClass implements FilterClassInterface {
  public override data: DropdownOptionInterface<EJobStatus>[] = [
    {
      id: EJobStatus.notStarted,
      name: this.translate.instant('filterCard.jobStatus.notStarted'),
    },
    {
      id: EJobStatus.inProgress,
      name: this.translate.instant('filterCard.jobStatus.inProgress'),
    },
    {
      id: EJobStatus.completed,
      name: this.translate.instant('filterCard.jobStatus.completed'),
    },
    {
      id: EJobStatus.canceled,
      name: this.translate.instant('filterCard.jobStatus.canceled'),
    },
  ];
  public override dataSubject: BehaviorSubject<DropdownOptionInterface[]>;

  constructor(public translate: TranslateService) {
    super();
    this.dataSubject = new BehaviorSubject<DropdownOptionInterface[]>(this.data);
  }

  public getInitialData(): DropdownOptionInterface[] {
    return this.data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class BooleanFilter extends FilterClass implements FilterClassInterface {
  public override data: DropdownOptionInterface[] = [
    {
      id: true,
      name: this.translate.instant('general.yes'),
    },
    {
      id: false,
      name: this.translate.instant('general.no'),
    },
  ];
  public override dataSubject: BehaviorSubject<DropdownOptionInterface[]>;

  constructor(public translate: TranslateService) {
    super();
    this.dataSubject = new BehaviorSubject<DropdownOptionInterface[]>(this.data);
  }

  public getInitialData(): DropdownOptionInterface[] {
    return this.data;
  }
}
@Injectable({
  providedIn: 'root',
})
export class IotDeviceType extends FilterClass implements FilterClassInterface {
  public options: HttpOptionInterface = {};

  constructor(public store: Store<oeeAppReducer.OeeAppState>, public helperService: HelperService) {
    super();
  }

  public override init(): void {
    this.subscribe = this.store.select('iotDeviceTypeFilter').subscribe((state) => {
      if (state.isLoaded === true && state.isLoading === false) {
        this.data = _.cloneDeep(state.data);
        this.dataSubject.next(this.data);
      }
    });
  }

  public getInitialData() {
    let data;
    this.store
      .select('iotDeviceTypeFilter')
      .pipe(take(1))
      .subscribe((state) => {
        data = HelperService.cloneDeep(state.data);
      });
    return data;
  }

  public override getAllData(options?: HttpOptionInterface): void {
    this.store.dispatch(new StartLoadFilterIotDeviceReportDeviceTypes({ ...this.options, ...options }, this.apiServer));
  }
}

@Injectable({
  providedIn: 'root',
})
export class RfidCardObjectType extends FilterClass implements FilterClassInterface {
  public override data: DropdownOptionInterface<ECardAssignmentObjectTypeEnum>[] = [
    {
      id: ECardAssignmentObjectTypeEnum.WORK_ORDER,
      name: this.translate.instant('general.workOrder'),
    },
    {
      id: ECardAssignmentObjectTypeEnum.JOB,
      name: this.translate.instant('general.job'),
    },
  ];
  public override dataSubject: BehaviorSubject<DropdownOptionInterface[]>;

  constructor(public translate: TranslateService) {
    super();
    this.dataSubject = new BehaviorSubject<DropdownOptionInterface[]>(this.data);
  }

  public getInitialData(): DropdownOptionInterface[] {
    return this.data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class IotDeviceList extends FilterClass implements FilterClassInterface {
  public options: HttpOptionInterface = {};
  public override objectNameProp: string = ObjectNameProps.macAddress;

  constructor(public store: Store<oeeAppReducer.OeeAppState>, public helperService: HelperService) {
    super();
  }

  public override init(): void {
    this.subscribe = this.store.select('iotDeviceFilter').subscribe((state) => {
      if (state.isLoaded === true && state.isLoading === false) {
        const initialData = _.cloneDeep(state.data);
        let searchName: string = null;
        try {
          const parsedS = JSON.parse(this.options.s);
          searchName = _.get(
            _.find(parsedS.$and, (item) => _.get(item, '$or[0].name')),
            '$or[0].name.$cont',
            null,
          );
        } catch (error) {
          searchName = null;
        }

        if (searchName !== null) {
          this.data = initialData.data.filter((item) =>
            item.macAddress.toLowerCase().includes(searchName.toLowerCase()),
          );
        } else {
          this.data = initialData;
        }

        this.dataSubject.next(this.data);
      }
    });
  }

  public override getFilterData(options: HttpOptionInterface, isFetchMoreRequest?: boolean): void {
    this.options = { ...this.options, ...options };

    this.store.dispatch(new StartLoadFilterIotDevice(this.options, this.apiServer));
  }

  public getInitialData() {
    let data;
    this.store
      .select('iotDeviceFilter')
      .pipe(take(1))
      .subscribe((state) => {
        data = HelperService.cloneDeep(state.data);
      });
    return data;
  }

  public override getAllData(options?: HttpOptionInterface): void {
    this.store.dispatch(new StartLoadFilterIotDevice({ ...this.options, ...options }, this.apiServer));
  }
}

export type FilterableObjectTypes =
  | Site
  | Line
  | Shift
  | Product
  | ProductInput
  | SensorType
  | ProductMultiplierSensorType
  | DateType
  | WorkOrderStatus
  | WorkOrderProperty
  | Department
  | Observation
  | RootCauseType
  | RootCauseGroupFilter
  | LaborLogsGroupBy
  | AssetLogsGroupBy
  | CheckInLogSite
  | FilterCardUser
  | FilterCardEquipment
  | SchedulerScenarioPlanned
  | SchedulerScenarioDeployed
  | AssetLogStatus
  | LaborLogStatus
  | Activity
  | Level
  | Status
  | Statuses
  | ActivityType
  | CommentLogsObjectType
  | CommentLogsCommentType
  | ActivityStatus
  | StartOfDay
  | Sensor
  | KPICardPage
  | ProductFamily
  | User
  | DateRange
  | WorkOrder
  | Equipment
  | Task
  | KpiViewGroupBy
  | ActivityHistoryWorkOrderStatus
  | AlertFunction
  | CountType
  | DurationTypes
  | MyReportBreakdown
  | ProductResourceGroupsResourceType
  | FloorPlan
  | FloorPlanSite
  | FloorPlanLine
  | CommentTags
  | RootCauseAnalysisCommentTags
  | CustomerSegment
  | ProductTransitionCompare
  | FromProductTransitionMatrixType
  | ToProductTransitionMatrixType
  | ProductTransitionDurationType
  | LineFilterWithNullOption
  | OpcTag
  | AutomationCenterRuleStatusType
  | RoleType
  | SystemLevel
  | NotificationLogsNotificationType
  | LineStation
  | Job
  | JobStatus
  | BooleanFilter
  | IotDeviceType
  | IotDeviceList
  | RfidCardObjectType;

export type TypeFilterableObjects = Type<FilterableObjectTypes>;

export type FilterableComponents = DropdownComponent | FilterDateRangePickerComponent | SearchBoxComponent;
