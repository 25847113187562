import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MainComponent } from './layout/main/main.component';
import { SiteLineGuardService } from './shared/service/site-line-guard/site-line-guard.service';
import { AuthGuardService } from './shared/service/auth-guard/auth-guard.service';
import { ForbiddenComponent } from './view/error/forbidden/forbidden.component';
import { ForbiddenSchedulerComponent } from './view/error/forbidden-scheduler/forbidden-scheduler.component';
import { MaintenanceComponent } from './layout/maintenance/maintenance.component';
import { SchedulerScenarioGuard } from './shared/service/scheduler-scenario-guard/scheduler-scenario.guard';
import { RouteGuard } from './shared/guard/route.guard';
import { HiddenSettingsGuard } from './shared/service/hidden-settings/hidden-settings.guard';
import { CheckInTimelineComponent } from './view/reports/check-in-timeline/check-in-timeline.component';

const routes: Routes = [
  {
    path: 'error/forbidden',
    component: ForbiddenComponent,
  },
  {
    path: 'error/forbidden-scheduler',
    component: ForbiddenSchedulerComponent,
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent,
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuardService],
    canActivateChild: [RouteGuard.canActivate],
    children: [
      {
        path: '',
        redirectTo: 'redirector',
        pathMatch: 'full',
      },
      {
        path: 'redirector',
        loadChildren: () => import('./view/redirector/redirector.module').then((m) => m.RedirectorModule),
      },
      {
        path: 'home',
        loadChildren: () => import('./view/home/line-home/line-home.module').then((m) => m.LineHomeModule),
        canActivate: [SiteLineGuardService],
      },
      {
        path: 'select-site-and-line',
        loadChildren: () =>
          import('./view/select-site-and-line/select-site-and-line.module').then((m) => m.SelectSiteAndLineModule),
        canActivate: [],
      },
      {
        path: 'activity-history',
        loadChildren: () =>
          import('./view/activity-history/activity-history.module').then((m) => m.ActivityHistoryModule),
      },
      {
        path: 'station-activity-history/:stationId',
        loadChildren: () =>
          import('./view/station-activity-history/station-activity-history.module').then(
            (m) => m.StationActivityHistoryModule,
          ),
        canActivate: [SiteLineGuardService],
      },
      {
        path: 'production-review',
        loadChildren: () =>
          import('./view/shift-review/production-review/production-review.module').then(
            (m) => m.ProductionReviewModule,
          ),
      },
      {
        path: 'activity-review',
        loadChildren: () => import('./view/activity-review/activity-review.module').then((m) => m.ActivityReviewModule),
      },
      {
        path: 'work-orders',
        loadChildren: () =>
          import('./view/work-orders-new-design/work-orders-new-design.module').then(
            (m) => m.WorkOrdersNewDesignModule,
          ),
      },
      {
        path: 'line-observation',
        loadChildren: () =>
          import('./view/flexible-observation/flexible-observation.module').then((m) => m.FlexibleObservationModule),
        canActivate: [SiteLineGuardService],
      },
      {
        path: 'embed-page/:url',
        loadChildren: () => import('./view/embed-page/embed-page.module').then((m) => m.EmbedPageModule),
      },
      {
        path: 'line-view',
        loadChildren: () =>
          import('./view/dashboards/line-view-enhanced/line-view-enhanced.module').then(
            ({ LineViewEnhancedModule }) => LineViewEnhancedModule,
          ),
      },
      {
        path: 'line-view-extended',
        redirectTo: 'line-view',
      },
      {
        path: 'knowledge-base',
        loadChildren: () => import('./view/knowledge-base/knowledge-base.module').then((m) => m.KnowledgeBaseModule),
      },
      {
        path: 'layout-plan-view/:id',
        loadChildren: () =>
          import('./view/layout-plan-view/layout-plan-view.module').then((m) => m.LayoutPlanViewModule),
        canActivate: [],
      },
      {
        path: 'layout-plan-view/:id/:jobId',
        loadChildren: () =>
          import('./view/layout-plan-view/layout-plan-view.module').then((m) => m.LayoutPlanViewModule),
        canActivate: [],
      },
      {
        path: 'dashboards',
        children: [
          { path: '', redirectTo: '/home', pathMatch: 'full' },
          {
            path: 'line-view',
            loadChildren: () =>
              import('./view/dashboards/line-view-enhanced/line-view-enhanced.module').then(
                ({ LineViewEnhancedModule }) => LineViewEnhancedModule,
              ),
          },
          {
            path: 'line-view-extended',
            redirectTo: 'line-view',
          },
          {
            path: 'asset-view',
            loadChildren: () => import('./view/asset-view/asset-view.module').then((m) => m.AssetViewModule),
          },
          {
            path: 'activity-view',
            loadChildren: () =>
              import('./view/dashboards/activity-view/activity-view.module').then((m) => m.ActivityViewModule),
          },
          {
            path: 'activity-view/:lineIds',
            loadChildren: () =>
              import('./view/dashboards/activity-view/activity-view.module').then((m) => m.ActivityViewModule),
          },
          {
            path: 'kpi-view',
            loadChildren: () =>
              import('./view/dashboards/kpi-view/kpi-view.module').then(({ KpiViewModule }) => KpiViewModule),
          },
          {
            path: 'daily-production-performance',
            loadChildren: () =>
              import('./view/dashboards/daily-production-performance/daily-production-performance.module').then(
                ({ DailyProductionPerformanceModule }) => DailyProductionPerformanceModule,
              ),
          },
          {
            path: 'global-view',
            loadChildren: () =>
              import('./view/global-view/global-view.module').then(({ GlobalViewModule }) => GlobalViewModule),
          },
          {
            path: 'line-view-enhanced',
            redirectTo: 'line-view',
          },
          {
            path: 'labor-asset-view',
            loadChildren: () =>
              import('./view/dashboards/labor-asset-view/labor-asset-view.module').then(
                ({ LaborAssetViewModule }) => LaborAssetViewModule,
              ),
          },
          {
            path: 'production-view',
            loadChildren: () =>
              import('./view/dashboards/production-view/production-view.module').then(
                ({ ProductionViewModule }) => ProductionViewModule,
              ),
          },
          {
            path: 'production-view/:lineIds',
            loadChildren: () =>
              import('./view/dashboards/production-view/production-view.module').then((m) => m.ProductionViewModule),
          },
        ],
      },
      {
        path: 'reports',
        children: [
          { path: '', redirectTo: '/home', pathMatch: 'full' },
          {
            path: 'labor-performance',
            loadChildren: () =>
              import('./view/reports/labor-performance/labor-performance.module').then((m) => m.LaborPerformanceModule),
          },
          {
            path: 'labor-logs',
            loadChildren: () =>
              import('./view/reports/check-in-logs/labor-logs/labor-logs.module').then((m) => m.LaborLogsModule),
          },
          {
            path: 'asset-logs',
            loadChildren: () =>
              import('./view/reports/check-in-logs/asset-logs/asset-logs.module').then((m) => m.AssetLogsModule),
          },
          {
            path: 'labor-logs-elastic',
            loadChildren: () =>
              import('./view/reports/check-in-logs-elastic/labor-logs-elastic/labor-logs-elastic.module').then(
                (m) => m.LaborLogsElasticModule,
              ),
          },
          {
            path: 'skill-matrix',
            loadChildren: () =>
              import('./view/reports/skill-matrix/skill-matrix.module').then((m) => m.SkillMatrixModule),
          },
          {
            path: 'skill-matrix-elastic',
            loadChildren: () =>
              import('./view/reports/skill-matrix-elastic/skill-matrix-elastic.module').then(
                (m) => m.SkillMatrixElasticModule,
              ),
          },
          {
            path: 'root-cause-analysis',
            loadChildren: () =>
              import('./view/reports/root-cause-analysis/root-cause-analysis.module').then(
                (m) => m.RootCauseAnalysisModule,
              ),
          },
          {
            path: 'root-cause-analysis-elastic',
            loadChildren: () =>
              import('./view/reports/root-cause-analysis-elastic/root-cause-analysis-elastic.module').then(
                (m) => m.RootCauseAnalysisElasticModule,
              ),
          },
          {
            path: 'activity-logs',
            loadChildren: () =>
              import('./view/reports/activity-logs/activity-logs.module').then((m) => m.ActivityLogsModule),
          },
          {
            path: 'comment-logs',
            loadChildren: () =>
              import('./view/reports/comment-logs/comment-logs.module').then((m) => m.CommentLogsModule),
          },
          {
            path: 'activity-timeline',
            loadChildren: () =>
              import('./view/reports/activity-timeline/activity-timeline.module').then((m) => m.ActivityTimelineModule),
          },
          {
            path: 'sensor',
            loadChildren: () =>
              import('./view/reports/sensor-reports/sensor-reports.module').then((m) => m.SensorReportsModule),
          },
          {
            path: 'work-order-performance',
            loadChildren: () =>
              import('./view/reports/work-order-performance/work-order-performance.module').then(
                (m) => m.WorkOrderPerformanceModule,
              ),
          },
          {
            path: 'work-order-performance/:workOrderId',
            loadChildren: () =>
              import('./view/reports/work-order-performance/work-order-performance.module').then(
                (m) => m.WorkOrderPerformanceModule,
              ),
          },
          {
            path: 'product-speed-analysis',
            loadChildren: () =>
              import('./view/reports/product-speed-analysis/product-speed-analysis.module').then(
                (m) => m.ProductSpeedAnalysisModule,
              ),
          },
          {
            path: 'task-performance',
            loadChildren: () =>
              import('./view/reports/task-performance/task-performance.module').then((m) => m.TaskPerformanceModule),
          },
          {
            path: 'deep-dive-analysis',
            loadChildren: () =>
              import('./view/reports/deep-dive-analysis/deep-dive-analysis.module').then(
                (m) => m.DeepDiveAnalysisModule,
              ),
          },
          {
            path: 'capacity',
            loadChildren: () => import('./view/reports/capacity/capacity.module').then((m) => m.CapacityModule),
          },
          {
            path: 'daily-performance-summary',
            loadChildren: () =>
              import('./view/reports/daily-performance-summary/daily-performance-summary.module').then(
                (m) => m.DailyPerformanceSummaryModule,
              ),
          },
          {
            path: 'oee-calendar',
            loadChildren: () =>
              import('./view/reports/oee-calendar/oee-calendar.module').then((m) => m.OeeCalendarModule),
          },
          {
            path: 'work-order-count-review',
            loadChildren: () =>
              import('./view/reports/work-order-count-review/work-order-count-review.module').then(
                (m) => m.WorkOrderCountReviewModule,
              ),
          },
          {
            path: 'periodic-oee-calculation-review',
            loadChildren: () =>
              import('./view/reports/periodic-oee-calculation-review/periodic-oee-calculation-review.module').then(
                (m) => m.PeriodicOeeCalculationReviewModule,
              ),
          },
          {
            path: 'performance-deep-dive',
            loadChildren: () =>
              import('./view/reports/performance-deep-dive/performance-deep-dive.module').then(
                (m) => m.PerformanceDeepDiveModule,
              ),
          },
          {
            path: 'sensor-deep-dive',
            loadChildren: () =>
              import('./view/reports/sensor-deep-dive/sensor-deep-dive.module').then((m) => m.SensorDeepDiveModule),
          },
          {
            path: 'my-reports',
            loadChildren: () => import('./view/reports/my-reports/my-reports.module').then((m) => m.MyReportsModule),
          },
          {
            path: 'my-reports/:id',
            loadChildren: () =>
              import('./view/reports/my-reports/my-report-metrics/my-report-metrics.module').then(
                (m) => m.MyReportMetricsModule,
              ),
          },
          {
            path: 'maintenance-performance',
            loadChildren: () =>
              import('./view/reports/maintenance-performance/maintenance-performance.module').then(
                (m) => m.MaintenancePerformanceModule,
              ),
          },
          {
            path: 'oee-waterfall-analysis',
            loadChildren: () =>
              import('./view/reports/oee-waterfall-analysis/oee-waterfall-analysis.module').then(
                (m) => m.OeeWaterfallAnalysisModule,
              ),
          },
          {
            path: 'schedule-adherence',
            loadChildren: () =>
              import('./view/reports/schedule-adherence/schedule-adherence.module').then(
                (m) => m.ScheduleAdherenceModule,
              ),
          },
          {
            path: 'otif-analysis',
            loadChildren: () =>
              import('./view/reports/otif-analysis/otif-analysis.module').then((m) => m.OtifAnalysisModule),
          },
          {
            path: 'opc-message-inspection',
            loadChildren: () =>
              import('./view/reports/opc-message-inspection/opc-message-inspection.module').then(
                (m) => m.OpcMessageInspectionModule,
              ),
          },
          {
            path: 'changeover-analysis',
            loadChildren: () => import('./view/reports/changeover/changeover.module').then((m) => m.ChangeoverModule),
          },
          {
            path: 'oee-adherence',
            loadChildren: () =>
              import('./view/reports/oee-adherence/oee-adherence.module').then((m) => m.OeeAdherenceModule),
          },
          {
            path: 'job-performance',
            loadChildren: () =>
              import('./view/reports/job-performance/job-performance.module').then((m) => m.JobPerformanceModule),
          },
          {
            path: 'iot-device-report',
            loadChildren: () =>
              import('./view/reports/iot-device-report/iot-device-report.module').then(
                ({ IotDeviceReportModule }) => IotDeviceReportModule,
              ),
          },
          {
            path: 'check-in-timeline',
            component: CheckInTimelineComponent,
            loadChildren: () =>
              import('./view/reports/check-in-timeline/check-in-timeline-routing.module').then(
                (m) => m.CheckInsRoutingModule,
              ),
          },
        ],
      },
      {
        path: 'shift-tracker',
        children: [
          { path: '', redirectTo: '/home', pathMatch: 'full' },
          {
            path: 'shift-summary',
            loadChildren: () => import('./view/shift-summary/shift-summary.module').then((m) => m.ShiftSummaryModule),
          },
          {
            path: 'work-orders-summary',
            loadChildren: () =>
              import('./view/work-orders-summary/work-orders-summary.module').then((m) => m.WorkOrdersSummaryModule),
          },
        ],
      },
      {
        path: 'settings',
        children: [
          { path: '', redirectTo: '/home', pathMatch: 'full' },
          {
            path: 'equipment',
            loadChildren: () =>
              import('./view/settings/equipment-assets/equipment-assets.module').then((m) => m.EquipmentAssetsModule),
          },
          {
            path: 'product-multipliers',
            loadChildren: () =>
              import('./view/settings/product-multipliers/product-multipliers.module').then(
                (m) => m.ProductMultipliersModule,
              ),
          },
          {
            path: 'kpi-targets',
            loadChildren: () => import('./view/settings/kpi-targets/kpi-targets.module').then((m) => m.KpiTargetModule),
          },
          {
            path: 'task-groups',
            loadChildren: () => import('./view/task-groups/task-groups.module').then((m) => m.TaskGroupsModule),
          },
          {
            path: 'line-availability',
            loadChildren: () =>
              import('./view/line-availability/line-availability.module').then((m) => m.LineAvailabilityModule),
          },
          {
            path: 'root-cause-groups',
            loadChildren: () =>
              import('./view/settings/root-cause-group/root-cause-group.module').then((m) => m.RootCauseGroupModule),
          },
          {
            path: 'products',
            loadChildren: () =>
              import('./view/settings/product-settings/product-settings.module').then((m) => m.ProductSettingsModule),
          },
          {
            path: 'sites',
            loadChildren: () => import('./view/settings/sites/sites.module').then((m) => m.SitesModule),
          },
          {
            path: 'product-speeds',
            loadChildren: () =>
              import('./view/settings/product-speeds/product-speeds.module').then((m) => m.ProductSpeedsModule),
          },
          {
            path: 'tasks',
            loadChildren: () => import('./view/settings/tasks/tasks.module').then((m) => m.TasksModule),
          },
          {
            path: 'users',
            loadChildren: () => import('./view/settings/users/users.module').then((m) => m.UsersModule),
          },
          {
            path: 'activities',
            loadChildren: () =>
              import('./view/settings/activities/activities.module').then(({ ActivitiesModule }) => ActivitiesModule),
          },
          {
            path: 'customers',
            loadChildren: () =>
              import('./view/settings/customer-settings/customer-settings.module').then(
                (m) => m.CustomerSettingsModule,
              ),
          },
          {
            path: 'lines',
            loadChildren: () =>
              import('./view/settings/departments-lines-stations/departments-lines-stations.module').then(
                (m) => m.DepartmentsLinesStationsModule,
              ),
          },
          {
            path: 'account-settings',
            loadChildren: () => import('./view/settings/account/account.module').then((m) => m.AccountModule),
          },
          {
            path: 'sensor-management',
            loadChildren: () =>
              import('./view/settings/sensor-management/sensor-management.module').then(
                (m) => m.SensorManagementModule,
              ),
          },
          {
            path: 'tags',
            loadChildren: () => import('./view/settings/tags/tags.module').then((m) => m.TagsModule),
          },
          {
            path: 'notification-center',
            loadChildren: () =>
              import('./view/settings/notification-center/notification-center.module').then(
                ({ NotificationCenterModule }) => NotificationCenterModule,
              ),
          },
          {
            path: 'alerts',
            loadChildren: () => import('./view/settings/alerts/alerts.module').then(({ AlertsModule }) => AlertsModule),
          },
          {
            path: 'notification-logs',
            loadChildren: () =>
              import('./view/settings/notification-logs/notification-logs.module').then(
                ({ NotificationLogsModule }) => NotificationLogsModule,
              ),
          },
          {
            path: 'home-page-display-settings',
            loadChildren: () =>
              import('./view/settings/home-page-display-settings/home-page-display-settings.module').then(
                ({ HomePageDisplaySettingsModule }) => HomePageDisplaySettingsModule,
              ),
          },
          {
            path: 'product-transition-matrix',
            loadChildren: () =>
              import('./view/settings/product-transition-matrix/product-transition-matrix.module').then(
                (m) => m.ProductTransitionMatrixModule,
              ),
          },
          {
            path: 'automation-center',
            loadChildren: () =>
              import('./view/settings/automation-center/automation-center.module').then(
                ({ AutomationCenterModule }) => AutomationCenterModule,
              ),
          },
          {
            path: 'product-configurations',
            loadChildren: () =>
              import('./view/settings/product-configurations/product-configurations-tabs.module').then(
                (m) => m.ProductConfigurationsTabsModule,
              ),
          },
          {
            path: 'hidden-settings',
            loadChildren: () =>
              import('./view/settings/hidden-settings/hidden-settings.module').then(
                ({ HiddenSettingsModule }) => HiddenSettingsModule,
              ),
            canActivate: [HiddenSettingsGuard],
          },
          {
            path: 'rfid-card-assignments',
            loadChildren: () =>
              import('./view/settings/rfid-card-assignments/rfid-card-assignments.module').then(
                (m) => m.RfidCardAssignmentsModule,
              ),
          },
          {
            path: 'opc-automation-settings',
            loadChildren: () =>
              import('./view/settings/opc-tag-automation-settings/opc-tag-automation-settings.module').then(
                (m) => m.OpcTagAutomationSettingsModule,
              ),
          },
        ],
      },
      {
        path: 'scheduler',
        children: [
          { path: '', redirectTo: '/scheduler/scenarios', pathMatch: 'full' },
          {
            path: 'scenarios',
            loadChildren: () =>
              import('./view/scheduler-scenario/scheduler-scenario.module').then((m) => m.SchedulerScenarioModule),
          },
          {
            path: 'resource-settings',
            loadChildren: () =>
              import('./view/scheduler-resource-settings/scheduler-resource-settings.module').then(
                (m) => m.SchedulerResourceSettingsModule,
              ),
          },
          {
            path: 'line-availability',
            loadChildren: () =>
              import('./view/line-availability/line-availability.module').then((m) => m.LineAvailabilityModule),
          },
          {
            path: 'labor-scheduler/:id',
            loadChildren: () =>
              import('./view/labor-scheduler/labor-scheduler.module').then((m) => m.LaborSchedulerModule),
            canActivate: [SchedulerScenarioGuard],
          },
          {
            path: ':id',
            loadChildren: () => import('./view/scheduler/scheduler.module').then((m) => m.SchedulerModule),
            canActivate: [SchedulerScenarioGuard],
          },
        ],
      },
      {
        path: 'book-a-demo',
        loadChildren: () => import('./view/book-a-demo/book-a-demo.module').then((m) => m.BookADemoModule),
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
      canceledNavigationResolution: 'computed',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
