import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as HeartbeatActions from './heartbeat.actions';
import { SiteService } from '../../../../shared/service/filter/site.service';
import { LineService } from '../../../../shared/service/line/line.service';
import * as oeeAppReducer from '../../../oee.reducer';
import { ErrorMessageService } from '../../../../shared/service/error-message.service';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import * as AppActions from '../../../app/actions';
import { HeartbeatService } from './heartbeat.service';
import { LineCRUDInterface, SiteCRUDInterface } from '../../../../shared/component/filter/filter.class';
import { IHeartbeat } from '../../../../view/settings/opc-tag-automation-settings/heartbeats/heartbeats.model';
import { NotificationTemplateService } from '../../notification-center/notification-templates/notification-template.service';
import { NotificationGroupsService } from '../../nofication-center/notification-groups/notification-groups.service';
import { NotificationGroupInterface } from '../../nofication-center/notification-groups/notification-groups.model';
import { INotificationTemplate } from '../../notification-center/notification-templates/notification-templates.model';

@Injectable()
export class HeartbeatEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: HeartbeatService,
    private readonly siteService: SiteService,
    private readonly lineService: LineService,
    private readonly notificationTemplateService: NotificationTemplateService,
    private readonly notificationGroupsService: NotificationGroupsService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly errorMessageService: ErrorMessageService,
  ) {}

  getDataForDatatable = createEffect(() =>
    this.actions$.pipe(
      ofType(HeartbeatActions.ActionTypes.HEARTBEATS_DATA_LOADING),
      switchMap((payload: HeartbeatActions.HeartbeatsLoading) => {
        let httpParams: HttpParams = new HttpParams()
          .append('sort', 'id,DESC')
          .append('limit', String(payload.params.perPage || 1000))
          .append('page', String(payload.params.page));

        if (payload.params.siteIds && payload.params.siteIds.length > 0) {
          httpParams = httpParams.append('siteId', payload.params.siteIds.join(','));
        }

        if (payload.params.lineIds && payload.params.lineIds.length > 0) {
          httpParams = httpParams.append('lineId', payload.params.lineIds.join(','));
        }

        const searchParamObject = {
          $and: [
            {
              $or: [
                {
                  name: {
                    $cont: `${payload.params?.searchText ?? ''}`,
                  },
                },
                {
                  listeningTags: {
                    $cont: `${payload.params?.searchText ?? ''}`,
                  },
                },
                {
                  waitTimeInSeconds: {
                    $cont: `${payload.params?.searchText ?? ''}`,
                  },
                },
              ],
            },
          ],
        };

        httpParams = httpParams.set('s', JSON.stringify(searchParamObject));

        if (payload.params.orderBy) {
          httpParams = httpParams.set('sort', `${payload.params.orderBy},${payload.params.orderDesc}`);
        }

        return this.service.getData(httpParams).pipe(
          switchMap((response) => {
            return of(new HeartbeatActions.HeartbeatsLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new HeartbeatActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new HeartbeatActions.FetchError(errorRes));
      }),
    ),
  );

  getOne = createEffect(() =>
    this.actions$.pipe(
      ofType(HeartbeatActions.ActionTypes.GET_HEARTBEAT),
      switchMap((objectData: HeartbeatActions.GetHeartbeat) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getOneWithDropdowns(objectData.recordId, new HttpParams()).pipe(
          switchMap(
            (response: {
              heartbeat: BaseOneResponseInterface<IHeartbeat>;
              sites: SiteCRUDInterface[];
              lines: GetManyResponseInterface<LineCRUDInterface>;
              notificationGroups: GetManyResponseInterface<Partial<NotificationGroupInterface>>;
              notificationTemplates: GetManyResponseInterface<Partial<INotificationTemplate>>;
            }) => {
              return of(
                new HeartbeatActions.GetHeartbeatCompleted(response.heartbeat),
                new HeartbeatActions.SiteDataLoaded(response.sites),
                new HeartbeatActions.LineDataCompleted(response.lines),
                new HeartbeatActions.MailGroupsDataLoaded(response.notificationGroups),
                new HeartbeatActions.MailTemplatesDataCompleted(response.notificationTemplates),
                new AppActions.HideLoader(),
              );
            },
          ),
          catchError((error) => {
            return of(new HeartbeatActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new HeartbeatActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  addOne = createEffect(() =>
    this.actions$.pipe(
      ofType(HeartbeatActions.ActionTypes.ADD_HEARTBEAT),
      switchMap((objectData: HeartbeatActions.AddHeartbeat) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addOne(objectData.heartbeat).pipe(
          switchMap((response: BaseOneResponseInterface<IHeartbeat>) => {
            const actions = [
              new HeartbeatActions.AddHeartbeatCompleted(response),
              ...(objectData.hideLoaderAfterSuccess ? [new AppActions.HideLoader()] : []),
            ];

            return of(...actions);
          }),
          catchError((error) => {
            return of(new HeartbeatActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new HeartbeatActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  editOne = createEffect(() =>
    this.actions$.pipe(
      ofType(HeartbeatActions.ActionTypes.EDIT_HEARTBEAT),
      switchMap((objectData: HeartbeatActions.EditHeartbeat) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editOne(objectData.recordId, objectData.record).pipe(
          switchMap((response: BaseOneResponseInterface<IHeartbeat>) => {
            const actions = [
              new HeartbeatActions.EditHeartbeatCompleted(response),
              ...(objectData.hideLoaderAfterSuccess ? [new AppActions.HideLoader()] : []),
            ];

            return of(...actions);
          }),
          catchError((error) => {
            return of(new HeartbeatActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new HeartbeatActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  deleteOnes = createEffect(() =>
    this.actions$.pipe(
      ofType(HeartbeatActions.ActionTypes.DELETE_HEARTBEATS),
      switchMap((objectData: HeartbeatActions.DeleteHeartbeats) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.deleteOnes(objectData.payload).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            this.errorMessageService.getTranslatedErrorMessage(response.data);
            return of(
              new HeartbeatActions.DeleteHeartbeatsCompleted(objectData.payload.length > 1, response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new HeartbeatActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new HeartbeatActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getSiteData = createEffect(() =>
    this.actions$.pipe(
      ofType(HeartbeatActions.ActionTypes.SITES_DATA_LOADING),
      switchMap((objectData: HeartbeatActions.SiteDataLoading) => {
        const httpParams: HttpParams = new HttpParams().set('limit', 1000);

        return from(this.siteService.getData(httpParams)).pipe(
          switchMap((response) => {
            return of(new HeartbeatActions.SiteDataLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new HeartbeatActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new HeartbeatActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getLines = createEffect(() =>
    this.actions$.pipe(
      ofType(HeartbeatActions.ActionTypes.LINES_DATA_LOADING),
      switchMap((objectData: HeartbeatActions.LineDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const search = {
          statusId: 1,
          siteId: objectData.siteId,
        };
        const httpParams = new HttpParams()
          .set('s', JSON.stringify(search))
          .set('limit', '1000')
          .set('join', 'lineTypeName');

        return this.lineService.getLines(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<Partial<LineCRUDInterface>>) => {
            return of(new HeartbeatActions.LineDataCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new HeartbeatActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new HeartbeatActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getNotificationGroupsData = createEffect(() =>
    this.actions$.pipe(
      ofType(HeartbeatActions.ActionTypes.MAIL_GROUPS_DATA_LOADING),
      switchMap((objectData: HeartbeatActions.MailGroupsDataLoading) => {
        const httpParams: HttpParams = new HttpParams().set('limit', 1000);

        return from(this.notificationGroupsService.getNotificationGroups(httpParams)).pipe(
          switchMap((response) => {
            return of(new HeartbeatActions.MailGroupsDataLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new HeartbeatActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new HeartbeatActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getNotificationTemplateData = createEffect(() =>
    this.actions$.pipe(
      ofType(HeartbeatActions.ActionTypes.MAIL_TEMPLATES_DATA_LOADING),
      switchMap((objectData: HeartbeatActions.MailTemplatesDataLoading) => {
        const httpParams: HttpParams = new HttpParams().set('limit', 1000);

        return from(this.notificationTemplateService.getNotificationTemplates(httpParams)).pipe(
          switchMap((response) => {
            return of(new HeartbeatActions.MailTemplatesDataCompleted(response));
          }),
          catchError((errorRes) => {
            return of(new HeartbeatActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new HeartbeatActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
