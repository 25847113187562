import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, take } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { ActivityTimelineService } from '../../../shared/service/reports/activity-timeline/activity-timeline.service';
import * as ActivityTimelineActions from '../../reports/activity-timeline/activity-timeline.actions';
import { ResponseArrayInterface } from '../../../shared/model/interface/generic-api-response.model';
import { IActivityHistory } from '../../../shared/service/activity-history/activity-history.model';
import { User } from '../../user/model';
import { ActivityTimelineInterface } from '../../../view/reports/activity-timeline/activity-timeline.model';
import * as AppActions from '../../app/actions';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { IStationActivityHistoryCrudResponse } from '../../station-activity-history/station-activity-history.model';
import { IActivityTimelineFetchData, IActivityTimelineStationActivityHistory } from './activity-timeline.model';

@Injectable()
export class ActivityTimelineEffects {
  private timezone$: string;

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly activityTimelineService: ActivityTimelineService,
  ) {}

  getActivityTimelineData = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityTimelineActions.ActivityTimelineActionTypes.ActivityTimeLineDataLoading),
      switchMap((objectData: ActivityTimelineActions.ActivityTimeLineDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        this.store
          .select('user')
          .pipe(take(1))
          .subscribe((user: User) => {
            this.timezone$ = user.timezone;
          });

        return from(this.activityTimelineService.loadInitialActivityData(objectData.activityTimeline)).pipe(
          switchMap(
            (response: {
              activityHistories: ResponseArrayInterface<IActivityHistory>;
              stationActivityHistories: GetManyResponseInterface<IStationActivityHistoryCrudResponse>;
            }) => {
              return of(
                new ActivityTimelineActions.ActivityTimeLineFetchData(
                  objectData.activityTimeline,
                  response.activityHistories.total,
                  response.stationActivityHistories.total,
                ),
              );
            },
          ),
          catchError((error) => {
            return of(new ActivityTimelineActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  fetchData = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityTimelineActions.ActivityTimelineActionTypes.ActivityTimeLineFetchData),
      switchMap((objectData: ActivityTimelineActions.ActivityTimeLineFetchData) => {
        return this.activityTimelineService
          .fetchActivityTimelineData(
            objectData.activityTimeline,
            objectData.activityHistoryTotalCount,
            objectData.stationActivityHistoryTotalCount,
          )
          .pipe(
            switchMap((response: IActivityTimelineFetchData) => {
              const formattedActivityHistoryData: ActivityTimelineInterface[] =
                this.activityTimelineService.getModifiedActivityHistories(
                  response.activityHistories,
                  response.allLines,
                );

              const formattedLineData: ActivityTimelineInterface[] = this.activityTimelineService.getModifiedLines(
                response.lines,
                this.timezone$,
                response.allLines,
              );

              const formattedStationHistories: IActivityTimelineStationActivityHistory[] =
                this.activityTimelineService.getModifiedStationActivityHistories(
                  response.stationActivityHistories,
                  this.timezone$,
                );

              const formattedOngoingStationHistories: IActivityTimelineStationActivityHistory[] =
                this.activityTimelineService.getModifiedStationActivityHistories(
                  response.ongoingStations,
                  this.timezone$,
                  true,
                );

              return of(
                new ActivityTimelineActions.ActivityTimeLineDataLoaded({
                  activityTimeline: formattedActivityHistoryData.concat(formattedLineData),
                  stationActivityHistories: formattedStationHistories.concat(formattedOngoingStationHistories),
                }),
                new AppActions.HideLoader(),
              );
            }),
            catchError((error) => {
              return of(new ActivityTimelineActions.FetchError(error), new AppActions.HideLoader());
            }),
          );
      }),
      catchError((errorRes) => {
        return of(new ActivityTimelineActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
