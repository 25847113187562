import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { forkJoin, map, Observable } from 'rxjs';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { switchMap } from 'rxjs/operators';
import { ActivityService } from '../../../../shared/service/filter/activity.service';
import { TaskService } from '../../../../shared/service/filter/task.service';

@Injectable({
  providedIn: 'root',
})
export class WorkOrderSettingService {
  private readonly routes = {
    workOrderSetting: `${this.baseUrl}/opc-tag-automation-settings/work-order-setting`,
  };

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly activityService: ActivityService,
    private readonly tasksService: TaskService,
  ) {}

  public getData(params: HttpParams): Observable<any> {
    return this.http.get<GetManyResponseInterface<any>>(this.routes.workOrderSetting, {
      params,
    });
  }

  public getOne(recordId: number, params?: HttpParams): Observable<any> {
    return this.http.get<BaseOneResponseInterface<any>>(`${this.routes.workOrderSetting}/${recordId}`, { params }).pipe(
      switchMap((response) => {
        const httpParamsActivity = new HttpParams()
          .set('s', JSON.stringify({ active: 1, id: response.data.targetActivityId }))
          .set('limit', '1000');
        const httpParamsTask = new HttpParams()
          .set('s', JSON.stringify({ statusId: 1, id: response.data.targetTaskId }))
          .set('limit', '1000');

        const activityRequest = this.activityService.getData(httpParamsActivity);
        const taskRequest = this.tasksService.getData(httpParamsTask);

        return forkJoin([activityRequest, taskRequest]).pipe(
          map(([activityResponse, taskResponse]) => {
            return {
              ...response.data,
              activity: activityResponse,
              task: taskResponse,
            };
          }),
        );
      }),
    );
  }

  public addOne(record: any): Observable<BaseOneResponseInterface<any>> {
    return this.http.post<BaseOneResponseInterface<any>>(`${this.routes.workOrderSetting}`, record);
  }

  public editOne(recordId: number, record: any): Observable<BaseOneResponseInterface<any>> {
    return this.http.patch<BaseOneResponseInterface<any>>(`${this.routes.workOrderSetting}/${recordId}`, record);
  }

  public deleteOnes(recordIds: number[]): Observable<BulkResponseDataInterface> {
    if (recordIds.length > 1) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        body: {
          workOrderSettings: recordIds,
        },
      };
      return this.http.delete<BulkResponseDataInterface>(`${this.routes.workOrderSetting}/bulk/delete`, httpOptions);
    }
    return this.http.delete<BulkResponseDataInterface>(`${this.routes.workOrderSetting}/${recordIds[0]}`);
  }
}
