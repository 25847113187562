import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { of, from } from 'rxjs';
import { UserSettingsService } from './user.service';
import { SiteService } from '../../../shared/service/filter/site.service';
import * as UsersActions from './users.actions';
import * as oeeAppReducer from '../../oee.reducer';
import { ErrorMessageService } from '../../../shared/service/error-message.service';
import { ExcelHelperService } from '../../../shared/service/excel/excel-helper.service';
import * as AppActions from '../../app/actions';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import {
  IAnonymousCard,
  ISearchParam,
  LanguageResponseInterface,
  UserInterface,
  IOngoingUserCheckinsAndActivityInLine,
  IHexboxList,
} from './users.model';
import { FieldTypes, TargetEndpoints } from '../../../shared/component/filter/advanced-filter/advanced-filter.model';
import { AdvancedFilterService } from '../../../shared/component/filter/advanced-filter/advanced-filter.service';
import * as _ from 'lodash';
import { LineService } from '../../../shared/service/filter/line.service';
import { IAddUser } from '../../../view/settings/users/users.model';
import { UsersCRUDInterface } from '../../../shared/component/filter/filter.class';
import { emptyAction } from '../../../../constants';

@Injectable()
export class UserSettingsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: UserSettingsService,
    private readonly siteService: SiteService,
    private readonly lineService: LineService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly errorMessageService: ErrorMessageService,
    private readonly excelHelperService: ExcelHelperService,
    private readonly advancedFilterService: AdvancedFilterService,
  ) {}

  getSitesData = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.USERS_SITES_DATA_LOADING),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowLoader());
        const httpParams: HttpParams = new HttpParams().set('limit', '1000');

        return from(this.siteService.getData(httpParams)).pipe(
          switchMap((response) => {
            return of(new UsersActions.UsersSiteDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new UsersActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new UsersActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getLinesData = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.USERS_LINES_DATA_LOADING),
      switchMap((payload: UsersActions.UsersLineDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        let httpParams: HttpParams = new HttpParams().set('limit', '1000');
        if (payload.siteId) {
          httpParams = httpParams.set('s', JSON.stringify({ siteId: { $in: payload.siteId } }));
        }

        return from(this.lineService.getData(httpParams)).pipe(
          switchMap((response) => {
            return of(new UsersActions.UsersLineDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new UsersActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new UsersActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getUserData = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.USERS_DATA_LOADING),
      switchMap((payload: UsersActions.UsersDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const httpParams: HttpParams = new HttpParams()
          .set('siteId', String(payload.siteId))
          .append('limit', String(payload.limit || 1000));
        return this.service.getUsers(httpParams).pipe(
          switchMap((response) => {
            return of(new UsersActions.UsersDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new UsersActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new UsersActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getUserDataForDatatable = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.USERS_DATA_FOR_DATATABLE_LOADING),
      switchMap((payload: UsersActions.UsersDataForDatatableLoading) => {
        let httpParams: HttpParams = new HttpParams()
          .append('sort', 'id,DESC')
          .append('limit', String(payload.tableQuery.pageSize || 1000))
          .append('page', String(payload.tableQuery.page));

        if (payload.tableQuery.selectedSiteId) {
          httpParams = httpParams.append('currentSiteIds', payload.tableQuery.selectedSiteId.join(','));
        }

        if (payload.tableQuery.selectedLineId) {
          httpParams = httpParams.append('currentLineIds', payload.tableQuery.selectedLineId.join(','));
        }
        const searchParamObject = this.getSearchStringForUser(payload);
        if (searchParamObject.contactTypeParams) {
          httpParams = httpParams.set('contactTypes', searchParamObject.contactTypeParams);
        }
        httpParams = httpParams.set('s', searchParamObject.searchParams);

        if (payload.tableQuery.orderBy) {
          const direction = payload.tableQuery.orderDesc ? 'DESC' : 'ASC';
          httpParams = httpParams.set('sort', `${payload.tableQuery.orderBy},${direction}`);
        }

        return this.service.getUsersForDatatable(httpParams).pipe(
          switchMap((response) => {
            return of(new UsersActions.UsersDataForDatatableLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new UsersActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new UsersActions.FetchError(errorRes));
      }),
    ),
  );

  downloadUserExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.DOWNLOAD_USERS_EXCEL),
      switchMap((objectData: UsersActions.DownloadUsersExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());
        this.service.downloadUserExcel(objectData.withData, objectData.filters);
        return emptyAction;
      }),
      catchError((error) => {
        return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  uploadExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.UPLOAD_USER_EXCEL),
      switchMap((objectData: UsersActions.UploadUsersExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.uploadExcel(objectData.users).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            this.errorMessageService.replaceErrorMessageProperties(
              response.data,
              ['decimalSeparator', 'thousandSeparator'],
              ['numberFormat', 'numberFormat'],
            );
            this.errorMessageService.getTranslatedErrorMessage(response.data);
            const mergedArray = this.excelHelperService.mergeBulkResponseWithRequestData<UserInterface>(
              response,
              objectData.users.users,
            );
            return of(
              new UsersActions.UploadUsersExcelCompleted(mergedArray),
              new AppActions.GetCurrentUser(),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  addUser = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.ADD_USER),
      switchMap((objectData: UsersActions.AddUser) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addUser(objectData.user).pipe(
          switchMap((response: BaseOneResponseInterface<UsersCRUDInterface>) => {
            const actions = [
              new UsersActions.AddUserCompleted(response),
              ...(objectData.hideLoaderAfterSuccess ? [new AppActions.HideLoader()] : []),
            ];

            return of(...actions);
          }),
          catchError((error) => {
            return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  deleteUsers = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.DELETE_USERS),
      switchMap((objectData: UsersActions.DeleteUsers) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.deleteUsers(objectData.payload).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            this.errorMessageService.getTranslatedErrorMessage(response.data);
            return of(
              new UsersActions.DeleteUsersCompleted(objectData.payload.length > 1, response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getUser = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.GET_USER),
      switchMap((objectData: UsersActions.GetUser) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getUser(objectData.userId, new HttpParams().set('followedLines', true)).pipe(
          switchMap((response: BaseOneResponseInterface<UserInterface>) => {
            return of(new UsersActions.GetUserCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getAnonymousCards = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.GET_ANONYMOUS_CARDS),
      switchMap((objectData: UsersActions.GetAnonymousCards) => {
        this.store.dispatch(new AppActions.ShowLoader());
        let httpParams = new HttpParams().set('limit', '1000');

        if (objectData.search) {
          httpParams = httpParams.set('search', String(objectData.search));
        }

        httpParams = httpParams.set('sites', String(objectData.sites));
        return this.service.getAnonymousCards(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<IAnonymousCard>) => {
            return of(new UsersActions.GetAnonymousCardsCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getLanguages = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.GET_LANGUAGES),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getLanguages().pipe(
          switchMap((response: LanguageResponseInterface) => {
            return of(new UsersActions.GetLanguagesCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  editUser = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.EDIT_USER),
      switchMap((objectData: UsersActions.EditUser) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editUser(objectData.user, objectData.userId).pipe(
          switchMap((response: BaseOneResponseInterface<UsersCRUDInterface>) => {
            const actions = [
              new UsersActions.EditUserCompleted(response),
              ...(objectData.isSelf ? [new AppActions.GetCurrentUser()] : []),
              ...(objectData.hideLoaderAfterSuccess ? [new AppActions.HideLoader()] : []),
            ];

            return of(...actions);
          }),
          catchError((error) => {
            return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new UsersActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  bulkEditUser = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.BULK_EDIT_USER),
      switchMap((objectData: UsersActions.BulkEditUser) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.bulkEditUser(objectData.users).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            return of(new UsersActions.BulkEditUserCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  sendVerificationEmail = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.SEND_VERIFICATION_EMAIL),
      switchMap((objectData: UsersActions.SendVerificationEmail) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.sendVerificationEmail(objectData.id).pipe(
          switchMap((response) => {
            return of(new UsersActions.SendVerificationEmailCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new UsersActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  unverifyUser = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.UNVERIFY_USER),
      switchMap((objectData: UsersActions.UnverifyUser) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.unverifyUser(objectData.id).pipe(
          switchMap((response: BaseOneResponseInterface<IAddUser>) => {
            return of(new UsersActions.UnverifyUserCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  public getSearchStringForUser(payload: UsersActions.UsersDataForDatatableLoading): ISearchParam {
    let contactTypeHttpParam: string;
    const searchParams: { [key: string]: object[] } = {
      $and: [
        {
          $or: [
            {
              fullName: {
                $cont: `${payload.tableQuery?.search ?? ''}`,
              },
            },
            {
              userName: {
                $cont: `${payload.tableQuery?.search ?? ''}`,
              },
            },
            {
              email: {
                $cont: `${payload.tableQuery?.search ?? ''}`,
              },
            },
          ],
        },
      ],
    };

    if (payload.tableQuery.selectedLevelId) {
      searchParams['$and'].push({ levelId: { $in: payload.tableQuery.selectedLevelId } });
    }

    if (payload.tableQuery.selectedStatusId) {
      searchParams['$and'].push({ isActive: { $in: payload.tableQuery.selectedStatusId } });
    }

    if (payload.tableQuery.advancedFilter) {
      const advancedFilter = payload.tableQuery.advancedFilter.filters;

      for (const filter of advancedFilter) {
        switch (filter.type) {
          case FieldTypes.predefined:
            if (filter.path !== 'contactType') {
              searchParams['$and'].push(
                this.advancedFilterService.generateQuery(
                  filter.path,
                  filter.type,
                  filter.operator.name,
                  filter.operator.type,
                  payload.tableQuery.advancedFilter.target,
                  _.get(filter.value, `[0][${filter.searchBy}]`, ''),
                ),
              );
            } else {
              contactTypeHttpParam = JSON.stringify(
                this.advancedFilterService.generateQuery(
                  filter.path,
                  filter.type,
                  filter.operator.name,
                  filter.operator.type,
                  TargetEndpoints.Custom,
                  _.get(filter.value, `[0][${filter.searchBy}]`, ''),
                ),
              );
            }
            break;
          case FieldTypes.dateFormat:
            searchParams['$and'].push(
              this.advancedFilterService.generateQuery(
                filter.path.split('-')[0],
                filter.type,
                filter.operator.name,
                filter.operator.type,
                payload.tableQuery.advancedFilter.target,
                _.get(filter.value, `[0][${filter.searchBy}]`, '').split('|')[1],
              ),
            );
            searchParams['$and'].push(
              this.advancedFilterService.generateQuery(
                filter.path.split('-')[1],
                filter.type,
                filter.operator.name,
                filter.operator.type,
                payload.tableQuery.advancedFilter.target,
                _.get(filter.value, `[0][${filter.searchBy}]`, '').split('|')[0],
              ),
            );
            break;
          default:
            searchParams['$and'].push(
              this.advancedFilterService.generateQuery(
                filter.path,
                filter.type,
                filter.operator.name,
                filter.operator.type,
                payload.tableQuery.advancedFilter.target,
                filter.value,
              ),
            );
            break;
        }
      }
    }

    return { searchParams: JSON.stringify(searchParams), contactTypeParams: contactTypeHttpParam };
  }

  getAvatar = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.GET_AVATAR_LOADING, UsersActions.GET_AVATAR_FOR_LOGS_LOADING),
      mergeMap((objectData: UsersActions.GetAvatarLoading | UsersActions.GetAvatarForLogsLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getAvatar(objectData.avatarPath, objectData.options).pipe(
          mergeMap((response: BaseOneResponseInterface<string>) => {
            return of(
              new UsersActions.GetAvatarLoaded(response, objectData.avatarPath),
              new UsersActions.GetAvatarForLogsLoaded(response, objectData.avatarPath),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new UsersActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  uploadAvatar = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.UPLOAD_AVATAR_LOADING),
      switchMap((objectData: UsersActions.UploadAvatarLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.uploadAvatar(objectData.id, objectData.base64ImageContent).pipe(
          switchMap(() => {
            return of(new UsersActions.UploadAvatarLoaded(), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new UsersActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  deleteAvatar = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.DELETE_AVATAR_LOADING),
      switchMap((objectData: UsersActions.DeleteAvatarLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.deleteAvatar(objectData.id).pipe(
          switchMap(() => {
            return of(new UsersActions.DeleteAvatarLoaded(), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new UsersActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getOngoingUserCheckinsAndActivityInLine = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.GET_ONGOING_USER_CHECKINS_AND_ACTIVITY_IN_LINE_LOADING),
      switchMap((objectData: UsersActions.getOngoingUserCheckinsAndActivityInLineLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const params: { userIds: number[] } = { userIds: objectData.userIds };

        return this.service.getOngoingUserCheckinsAndActivityInLine(params).pipe(
          switchMap((response: GetManyResponseInterface<IOngoingUserCheckinsAndActivityInLine>) => {
            return of(
              new UsersActions.getOngoingUserCheckinsAndActivityInLineLoaded(response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getHexboxList = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.GET_HEXBOX_LIST),
      switchMap((objectData: UsersActions.GetHexboxList) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.getHexboxList(objectData.siteId).pipe(
          switchMap((response: GetManyResponseInterface<IHexboxList>) => {
            return of(new UsersActions.GetHexboxListCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new UsersActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );
}
