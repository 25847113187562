import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ScenarioItemsDeployRequestInterface, SchedulerScenarioHorizonInterface } from './scheduler-service.model';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import {
  ScenarioAdvancedFilterAddRequestInterface,
  ScenarioItemsResponseInterface,
  ISchedulerKpiMetricInfo,
  IKpiCardDataProperties,
  ScenarioResponseInterface,
  IUpdateSchedulerOptimization,
  ICheckForDeployItemsResponse,
  IScenarioUncreatedWorkOrderResponse,
  IScenarioGanttAllItemsResponse,
  IScenarioUncreatedWorkOrder,
  ISchedulerOptimizationResponse,
  ILinePathDetailsBySiteId,
  IGroupedJobsForUncreatedWorkOrdersRequestParams,
  ScenarioInterface,
  ISaveManySchedulerWorkOrderRules,
  ISchedulerListViewDownloadExcelPayload,
  ListViewHeaderInterface,
  ISchedulerListViewItems,
  ISchedulerShift,
  IOptimizationAlgorithms,
} from '../../../store/scheduler/scheduler.model';
import * as _ from 'lodash';
import * as moment from 'moment';
import { mysqlDateFormat, mysqlTimestampFormat } from '../../helper/date';
import { ActivitiesService } from '../activities/activities.service';
import { ActivitiesInterface } from '../../model/interface/activities.model';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../model/interface/crud-response-interface.model';
import { IFilter } from '../../component/filter/advanced-filter/advanced-filter.model';
import { HelperService } from '../helper.service';
import { AdvancedFilterService } from '../../component/filter/advanced-filter/advanced-filter.service';
import { ScenarioBulkResponseDataInterface } from '../../../store/scheduler-scenario/scheduler-scenario.model';
import { ResponseInterface } from '../../model/interface/generic-api-response.model';
import { TranslateService } from '@ngx-translate/core';
import { ICheckForDeployItemsRequest } from '../../../view/scheduler/gantt-view/gantt-view.component.model';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../store/oee.reducer';
import { ValueType } from 'exceljs';
import * as AppActions from '../../../store/app/actions';
import * as SchedulerActions from '../../../store/scheduler/scheduler.actions';
import { DecimalHelper } from '../../helper/decimal/decimal-helper';
import {
  CellTypes,
  CreateExcelInterface,
  CreateExcelSheetInterface,
  ExcelColumnDefinitionInterface,
  ExcelColumnWidthEnum,
  ExcelDateFormatInformationInterface,
  ExcelHelperService,
  ExcelSheetTypeEnum,
} from '../excel/excel-helper.service';

@Injectable({
  providedIn: 'root',
})
export class SchedulerService {
  private readonly routes = {
    scenarios: '/scheduler/scenarios',
    scenarioItems: '/scheduler/scenario-items',
    scenarioItemsBulkCreate: '/scheduler/scenario-items/bulk/create',
    deploy: '/scheduler/scenario-items/deploy',
    scenarioShiftPlans: '/scenario-shift',
    scenarioWorkOrderRules: '/scheduler/scenarios/work-order-rules',
    optimizeScheduler: '/optimize-scheduler',
    getSchedulerOptimizations: '/scheduler-optimizations',
    updateSchedulerOptimization: '/update-scheduler-optimization',
    uncreatedWorkOrders: '/scheduler/scenario-uncreated-work-orders',
    uncreatedWorkOrdersBulkCreate: '/scheduler/scenario-uncreated-work-orders/bulk/create',
    uncreatedWorkOrdersBulkDelete: '/scheduler/scenario-uncreated-work-orders/bulk/delete',
    groupedJobsForUncreatedWorkOrders:
      '/scheduler/scenario-uncreated-work-orders/get-grouped-jobs-of-uncreated-work-orders',
    linePathDetails: '/line-paths/line-path-details-by-site-id',
  };

  public scenarioItemsLoaded: boolean = false;
  public scenarioItemsLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();
  private timezone: string = 'utc';
  private dateFormatInformation: ExcelDateFormatInformationInterface;

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly api: string,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    public activityService: ActivitiesService,
    private readonly helperService: HelperService,
    private readonly advancedFilterService: AdvancedFilterService,
    private readonly excelHelperService: ExcelHelperService,
    private readonly decimalHelper: DecimalHelper,
    public translate: TranslateService,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.timezone = state.timezone;
          this.dateFormatInformation = {
            timezone: state.timezone,
            dateFormat$: state.dateFormat,
            timeFormat$: state.timeFormat,
            locale$: state.locale,
            dateFormatRaw$: state.dateFormat,
            dateTimeFormatRaw$: state.dateTimeFormatWithSecond,
          };

          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  public loadScenario(scenarioId: number): Observable<ScenarioResponseInterface> {
    const queryParams: HttpParams = new HttpParams()
      .append('join', 'schSetting')
      .append('join', 'schSetting.schSettingItem')
      .append('join', 'schSetting.schSettingLine')
      .append('join', 'schSetting.schSettingLine.line||title,lineType,siteId,activityIds,statusId,laborCapacity')
      .append('join', 'schSetting.schSettingLine.line.lineTypeName||id,lineType')
      .append(
        's',
        JSON.stringify({
          'schSetting.schSettingLine.line.lineTypeName.status': { $eq: true },
          'schSetting.schSettingLine.line.statusId': { $eq: 1 },
        }),
      )
      .append('join', 'site||decimalScaleLimit,preRunPhaseName,postRunPhaseName');
    return this.http.get<ScenarioResponseInterface>(`${this.api}${this.routes.scenarios}/${scenarioId}`, {
      params: queryParams,
    });
  }

  public loadScenarioItems(
    scenarioId: number,
    startDate: string = null,
    endDate: string = null,
    lineIds: number[] = [],
  ): Observable<ScenarioItemsResponseInterface> {
    let params: HttpParams = new HttpParams();

    const searchObj = {};

    _.set(searchObj, '$or', [{ scenarioId: { $eq: scenarioId } }, { scenarioId: { $isnull: true } }]);

    if (startDate !== null && endDate !== null) {
      _.set(searchObj, 'startDate.$lt', [moment(endDate).format(mysqlDateFormat)]);
      _.set(searchObj, 'endDate.$gt', [moment(startDate).format(mysqlDateFormat)]);
    }

    if (lineIds.length > 0) {
      _.set(searchObj, 'lineId.$in', lineIds);
    }

    params = params.set('s', JSON.stringify(searchObj)).set('withActual', 'true').set('limit', '15000');

    return this.http.get<ScenarioItemsResponseInterface>(`${this.api}${this.routes.scenarioItems}`, {
      params,
    });
  }

  public saveScenarioItems(scenarioId: number, scenarioItems): Observable<ScenarioItemsResponseInterface> {
    return this.http.post<ScenarioItemsResponseInterface>(`${this.api}${this.routes.scenarioItemsBulkCreate}`, {
      scenarioId,
      scenarioItems,
    });
  }

  public getDownTimeActivities(activityIds?: string[]): Observable<GetManyResponseInterface<ActivitiesInterface>> {
    const searchObj = {};
    _.set(searchObj, 'activityType.$eq', 'downTimePlanned');
    _.set(searchObj, 'active.$eq', true);
    if (activityIds !== undefined) {
      _.set(searchObj, 'id.$in', activityIds);
    }

    let param: HttpParams = new HttpParams().set('limit', '1000');
    param = param.set('s', JSON.stringify(searchObj));

    return this.activityService.getActivities(param);
  }

  public deploy(
    horizon: SchedulerScenarioHorizonInterface,
    deployedItems: ScenarioItemsDeployRequestInterface[],
    siteId: number,
    uncreatedWorkOrders?: IScenarioUncreatedWorkOrder[],
  ): Observable<BulkResponseDataInterface> {
    return this.http.post<BulkResponseDataInterface>(`${this.api}${this.routes.deploy}`, {
      horizonStart: horizon.startDate.format(mysqlTimestampFormat),
      horizonEnd: horizon.endDate.format(mysqlTimestampFormat),
      lineItemGroups: deployedItems,
      siteId,
      uncreatedWorkOrders,
    });
  }

  public loadPlans(id: number): Observable<BaseOneResponseInterface<ISchedulerShift[]>> {
    return this.http.get<BaseOneResponseInterface<ISchedulerShift[]>>(
      `${this.api}${this.routes.scenarios}/${id}${this.routes.scenarioShiftPlans}`,
    );
  }

  public saveManyScenarioWorkOrderRules(
    payload: ISaveManySchedulerWorkOrderRules,
  ): Observable<ScenarioBulkResponseDataInterface> {
    return this.http.post<ScenarioBulkResponseDataInterface>(
      `${this.api}${this.routes.scenarioWorkOrderRules}`,
      payload,
    );
  }

  public setAsDefault(
    advancedFilter: ScenarioAdvancedFilterAddRequestInterface,
  ): Observable<ScenarioResponseInterface> {
    const normalizedFilters: IFilter[] = advancedFilter.filters.map((filter: IFilter) => ({
      ...filter,
      value: this.advancedFilterService.prepareValue(filter),
    }));
    return this.http.patch<ScenarioResponseInterface>(
      `${this.api}${this.routes.scenarios}/${advancedFilter.scenarioId}`,
      {
        advancedFilterConfig: {
          [advancedFilter.pageName]: normalizedFilters,
        },
      },
    );
  }

  public getSchedulerKpiMetricInfo(id: number): Observable<BaseOneResponseInterface<ISchedulerKpiMetricInfo>> {
    return this.http.get<BaseOneResponseInterface<ISchedulerKpiMetricInfo>>(
      `${this.api}${this.routes.scenarios}/${id}/kpi-metrics`,
    );
  }

  public saveSchedulerKpiCardConfigs(scenarioId: number, config: any): Observable<BaseOneResponseInterface<void>> {
    return this.http.patch<BaseOneResponseInterface<void>>(
      `${this.api}${this.routes.scenarios}/${scenarioId}/kpi-metrics/save-configuration`,
      config,
    );
  }

  public saveSchedulerKpiCardData(
    scenarioId: number,
    kpiCardData: IKpiCardDataProperties,
  ): Observable<ScenarioResponseInterface> {
    return this.http.patch<ScenarioResponseInterface>(
      `${this.api}${this.routes.scenarios}/${scenarioId}/kpi-metrics/save-scheduler-kpi-card-data`,
      kpiCardData,
    );
  }

  public optimizeScheduler(scenarioId: number, optimizationType: string): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.api}${this.routes.scenarios}/${scenarioId}${this.routes.optimizeScheduler}`,
      { optimizationType },
    );
  }

  public getSchedulerOptimizations(
    scenarioId: number,
  ): Observable<BaseOneResponseInterface<ISchedulerOptimizationResponse[]>> {
    return this.http.get<BaseOneResponseInterface<ISchedulerOptimizationResponse[]>>(
      `${this.api}${this.routes.scenarios}/${scenarioId}${this.routes.getSchedulerOptimizations}`,
    );
  }

  public updateSchedulerOptimization(
    scenarioId: number,
    params: IUpdateSchedulerOptimization,
  ): Observable<ResponseInterface<boolean>> {
    return this.http.patch<ResponseInterface<boolean>>(
      `${this.api}${this.routes.scenarios}/${scenarioId}${this.routes.updateSchedulerOptimization}`,
      {
        ...params,
      },
    );
  }

  public checkForDeployItems(
    checkForDeployItems: ICheckForDeployItemsRequest[],
  ): Observable<GetManyResponseInterface<ICheckForDeployItemsResponse>> {
    return this.http.post<GetManyResponseInterface<ICheckForDeployItemsResponse>>(
      `${this.api}${this.routes.scenarioItems}/check-for-deploy`,
      {
        checkForDeployItems,
      },
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public loadScenarioGanttAllItems(
    scenario: ScenarioInterface,
    startDate: string = null,
    endDate: string = null,
    lineIds: number[] = [],
  ): Observable<IScenarioGanttAllItemsResponse> {
    const observables: {
      scenarioItems: Observable<ScenarioItemsResponseInterface>;
      uncreatedWorkOrders: Observable<GetManyResponseInterface<IScenarioUncreatedWorkOrderResponse>>;
      groupedJobsForUncreatedWorkOrders: Observable<BaseOneResponseInterface<{ [key: number]: string }>>;
    } = {
      scenarioItems: this.loadScenarioItems(scenario.id, startDate, endDate, lineIds),
      uncreatedWorkOrders: this.loadUncreatedWorkOrders(scenario.id),
      groupedJobsForUncreatedWorkOrders: this.loadGroupedJobsForUncreatedWorkOrders({
        startDate: moment(startDate).format(mysqlDateFormat),
        endDate: moment(endDate).format(mysqlDateFormat),
        siteId: scenario.siteId,
        scenarioId: scenario.id,
        resourceSettingId: scenario.resourceSettingId,
      }),
    };

    return forkJoin(observables);
  }

  public loadUncreatedWorkOrders(
    scenarioId: number,
  ): Observable<GetManyResponseInterface<IScenarioUncreatedWorkOrderResponse>> {
    let params: HttpParams = new HttpParams();

    params = params
      .set('limit', '5000')
      .append('join', 'line')
      .append('join', 'workOrderSchedule')
      .append('join', 'workOrderSchedule.product')
      .append('join', 'workOrderSchedule.product.customer')
      .append('join', 'workOrderSchedule.site')
      .append('join', 'workOrderSchedule.lineType')
      .append('join', 'workOrderSchedule.job')
      .append('joinSchSettingLines', true)
      .append(
        's',
        JSON.stringify({
          scenarioId: { $eq: scenarioId },
        }),
      );

    return this.http.get<GetManyResponseInterface<IScenarioUncreatedWorkOrderResponse>>(
      `${this.api}${this.routes.uncreatedWorkOrders}`,
      {
        params,
      },
    );
  }

  public loadGroupedJobsForUncreatedWorkOrders(
    parameters: IGroupedJobsForUncreatedWorkOrdersRequestParams,
  ): Observable<BaseOneResponseInterface<{ [key: number]: string }>> {
    const params: HttpParams = new HttpParams()
      .append('siteId', parameters.siteId)
      .append('scenarioId', parameters.scenarioId)
      .append('resourceSettingId', parameters.resourceSettingId)
      .append('startDate', parameters.startDate)
      .append('endDate', parameters.endDate);

    return this.http.get<BaseOneResponseInterface<{ [key: number]: string }>>(
      `${this.api}${this.routes.groupedJobsForUncreatedWorkOrders}`,
      {
        params,
      },
    );
  }

  public saveUncreatedWorkOrders(
    scenarioId: number,
    uncreatedWorkOrders: IScenarioUncreatedWorkOrder[],
  ): Observable<ScenarioBulkResponseDataInterface> {
    return this.http.post<ScenarioBulkResponseDataInterface>(
      `${this.api}${this.routes.uncreatedWorkOrdersBulkCreate}`,
      {
        scenarioId,
        uncreatedWorkOrders,
      },
    );
  }

  public deleteUncreatedWorkOrders(uncreatedWorkOrderIds: number[]): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(`${this.api}${this.routes.uncreatedWorkOrdersBulkDelete}`, {
      body: { uncreatedWorkOrderIds },
    });
  }

  public getLinePathData(siteId: number): Observable<GetManyResponseInterface<ILinePathDetailsBySiteId>> {
    const params: HttpParams = new HttpParams().append('siteId', siteId);

    return this.http.get<GetManyResponseInterface<ILinePathDetailsBySiteId>>(
      `${this.api}${this.routes.linePathDetails}`,
      {
        params,
      },
    );
  }

  public getOptimizationAlgorithms(): Observable<BaseOneResponseInterface<IOptimizationAlgorithms[]>> {
    return this.http.get<BaseOneResponseInterface<IOptimizationAlgorithms[]>>(
      `${this.api}${this.routes.scenarios}/client-optimization-algorithms`,
    );
  }

  public downloadListViewExcel(payload: ISchedulerListViewDownloadExcelPayload) {
    const sheetTitle: string = this.translate.instant('scheduler.listView.excelTitle');
    const excelName: string = `${sheetTitle} ${moment()
      .tz(this.dateFormatInformation.timezone)
      .format(this.dateFormatInformation.dateFormat$)}`;

    const excelTemplateFormatOptions: CreateExcelInterface = {
      data: this.formatExcelData(payload.data, false),
      columns: this.getExcelColumns(payload.headers, false),
    };
    const excelDataAnalysisFormatOptions: CreateExcelInterface = {
      data: this.formatExcelData(payload.data, true),
      columns: this.getExcelColumns(payload.headers, true),
    };

    const workSheets: CreateExcelSheetInterface[] = [
      {
        sheetTitle: this.translate.instant('activityLogs.excel.readme.worksheetName'),
        sheetType: ExcelSheetTypeEnum.README,
      },
      {
        sheetTitle: this.translate.instant('activityLogs.excel.readme.templateFormatTitle'),
        sheetType: ExcelSheetTypeEnum.TABLE,
        params: excelTemplateFormatOptions,
        withData: true,
        isDisabledColumnsFirstLine: true,
        addDateTimeFormula: undefined,
        excelRowFormatLimit: 1001,
      },
      {
        sheetTitle: this.translate.instant('activityLogs.excel.readme.dataAnalysisFormatTitle'),
        sheetType: ExcelSheetTypeEnum.TABLE,
        params: excelDataAnalysisFormatOptions,
        withData: true,
        isDisabledColumnsFirstLine: true,
        addDateTimeFormula: undefined,
        excelRowFormatLimit: 1001,
      },
    ];

    this.excelHelperService
      .createExcel(
        excelName,
        { name: 'schedulerListView' },
        workSheets,
        this.dateFormatInformation.timezone,
        this.dateFormatInformation.dateFormat$,
        this.dateFormatInformation.timeFormat$,
      )
      .then(
        () => {
          this.store.dispatch(new SchedulerActions.SchedulerListViewDownloadExcelCompleted());
          this.store.dispatch(new AppActions.HideTopLoader());
        },
        () => {
          this.store.dispatch(new SchedulerActions.FetchError({}));
          this.store.dispatch(new AppActions.HideLoader());
        },
      );
  }

  private formatExcelData(data: ISchedulerListViewItems[], isDataAnalysis: boolean) {
    return data.map((item: ISchedulerListViewItems) => ({
      ...item,
      actualEndDate: item.actualEndDate
        ? isDataAnalysis
          ? moment(item.actualEndDate).format(mysqlTimestampFormat)
          : this.helperService.setUserDateTimeFormat(item.actualEndDate)
        : null,
      actualStartDate: item.actualStartDate
        ? isDataAnalysis
          ? moment(item.actualStartDate).format(mysqlTimestampFormat)
          : this.helperService.setUserDateTimeFormat(item.actualStartDate)
        : null,
      releaseDate: item.releaseDate
        ? isDataAnalysis
          ? moment(item.releaseDate).format(mysqlTimestampFormat)
          : this.helperService.setUserDateTimeFormat(item.releaseDate)
        : null,
      woDueDate: item.woDueDate
        ? isDataAnalysis
          ? moment(item.woDueDate).format(mysqlTimestampFormat)
          : this.helperService.setUserDateTimeFormat(item.woDueDate)
        : null,
      startDate: item.startDate
        ? isDataAnalysis
          ? moment(item.startDate).format(mysqlTimestampFormat)
          : this.helperService.setUserDateTimeFormat(item.startDate)
        : null,
      endDate: item.endDate
        ? isDataAnalysis
          ? moment(item.endDate).format(mysqlTimestampFormat)
          : this.helperService.setUserDateTimeFormat(item.endDate)
        : null,
      goodCount: item.goodCount ? this.decimalHelper.formatDecimalValueForExcel(item.goodCount, isDataAnalysis) : null,
      quantityOrdered: item.quantityOrdered
        ? this.decimalHelper.formatDecimalValueForExcel(item.quantityOrdered, isDataAnalysis)
        : null,
    }));
  }

  private getExcelColumns(
    headers: ListViewHeaderInterface[],
    isDataAnalysis: boolean,
  ): ExcelColumnDefinitionInterface[] {
    return headers.reduce((excelColumns: any[], column: ListViewHeaderInterface) => {
      excelColumns.push({
        header: column.name,
        key: column.value,
        width: ExcelColumnWidthEnum.DEFAULT,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: {
          type: CellTypes.CUSTOM,
          formulae: [],
          showErrorMessage: false,
          showInputMessage: false,
        },
        ...(isDataAnalysis ? this.excelHelperService.getExcelColumnInfo(column.value, { decimalFields: [] }) : {}),
      });
      return excelColumns;
    }, []);
  }
}
