import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { SiteService } from '../../../../shared/service/filter/site.service';
import { LineService } from '../../../../shared/service/line/line.service';
import * as oeeAppReducer from '../../../oee.reducer';
import { ErrorMessageService } from '../../../../shared/service/error-message.service';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import * as AppActions from '../../../app/actions';
import * as WorkOrderSettingActions from './work-order-setting.actions';
import { WorkOrderSettingService } from './work-order-setting.service';
import {
  ActivityCRUDInterface,
  LineCRUDInterface,
  SiteCRUDInterface,
  TaskFilterCRUDInterface,
} from '../../../../shared/component/filter/filter.class';
import { ActivityService } from '../../../../shared/service/filter/activity.service';
import { TaskService } from '../../../../shared/service/filter/task.service';

@Injectable()
export class WorkOrderSettingEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: WorkOrderSettingService,
    private readonly siteService: SiteService,
    private readonly lineService: LineService,
    private readonly activityService: ActivityService,
    private readonly tasksService: TaskService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly errorMessageService: ErrorMessageService,
  ) {}

  getDataForDatatable = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderSettingActions.ActionTypes.WORK_ORDER_SETTING_DATA_LOADING),
      switchMap((payload: WorkOrderSettingActions.WorkOrderSettingLoading) => {
        const andConditions = [];
        let httpParams: HttpParams = new HttpParams()
          .append('sort', 'id,DESC')
          .append('limit', String(payload.params.perPage || 1000))
          .append('page', String(payload.params.page));

        if (payload.params.siteIds && payload.params.siteIds.length > 0) {
          andConditions.push({ siteId: { $in: payload.params.siteIds } });
        }

        if (payload.params.lineIds && payload.params.lineIds.length > 0) {
          andConditions.push({ lineId: { $in: payload.params.lineIds } });
        }

        if (payload.params?.searchText) {
          andConditions.push({
            $or: [
              {
                tagName: {
                  $cont: `${payload.params?.searchText ?? ''}`,
                },
              },
            ],
          });
        }

        if (andConditions.length > 0) {
          httpParams = httpParams.set('s', JSON.stringify({ $and: andConditions }));
        }

        if (payload.params.orderBy && payload.params.orderDesc !== undefined) {
          httpParams = httpParams.set('sort', `${payload.params.orderBy},${payload.params.orderDesc}`);
        }

        return this.service.getData(httpParams).pipe(
          switchMap((response: any) => {
            return of(new WorkOrderSettingActions.WorkOrderSettingLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new WorkOrderSettingActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new WorkOrderSettingActions.FetchError(errorRes));
      }),
    ),
  );

  getOne = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderSettingActions.ActionTypes.GET_WORK_ORDER_SETTING),
      switchMap((objectData: WorkOrderSettingActions.GetWorkOrderSetting) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getOne(objectData.recordId, new HttpParams()).pipe(
          switchMap((response: BaseOneResponseInterface<any>) => {
            return of(new WorkOrderSettingActions.GetWorkOrderSettingCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new WorkOrderSettingActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new WorkOrderSettingActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  addOne = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderSettingActions.ActionTypes.ADD_WORK_ORDER_SETTING),
      switchMap((objectData: WorkOrderSettingActions.AddWorkOrderSetting) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addOne(objectData.workOrderSetting).pipe(
          switchMap((response: BaseOneResponseInterface<any>) => {
            const actions = [
              new WorkOrderSettingActions.AddWorkOrderSettingCompleted(response),
              ...(objectData.hideLoaderAfterSuccess ? [new AppActions.HideLoader()] : []),
            ];

            return of(...actions);
          }),
          catchError((error) => {
            return of(new WorkOrderSettingActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new WorkOrderSettingActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  editOne = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderSettingActions.ActionTypes.EDIT_WORK_ORDER_SETTING),
      switchMap((objectData: WorkOrderSettingActions.EditWorkOrderSetting) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editOne(objectData.recordId, objectData.record).pipe(
          switchMap((response: BaseOneResponseInterface<any>) => {
            const actions = [
              new WorkOrderSettingActions.EditWorkOrderSettingCompleted(response),
              ...(objectData.hideLoaderAfterSuccess ? [new AppActions.HideLoader()] : []),
            ];

            return of(...actions);
          }),
          catchError((error) => {
            return of(new WorkOrderSettingActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new WorkOrderSettingActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  deleteOnes = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderSettingActions.ActionTypes.DELETE_WORK_ORDER_SETTING),
      switchMap((objectData: WorkOrderSettingActions.DeleteWorkOrderSetting) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.deleteOnes(objectData.payload).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            this.errorMessageService.getTranslatedErrorMessage(response.data);
            return of(
              new WorkOrderSettingActions.DeleteWorkOrderSettingCompleted(objectData.payload.length > 1, response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new WorkOrderSettingActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new WorkOrderSettingActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getSiteData = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderSettingActions.ActionTypes.SITES_DATA_LOADING),
      switchMap((objectData: WorkOrderSettingActions.SiteDataLoading) => {
        const httpParams: HttpParams = new HttpParams().set('limit', 1000);

        return from(this.siteService.getData(httpParams)).pipe(
          switchMap((response: SiteCRUDInterface[]) => {
            return of(new WorkOrderSettingActions.SiteDataLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new WorkOrderSettingActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new WorkOrderSettingActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getLines = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderSettingActions.ActionTypes.LINES_DATA_LOADING),
      switchMap((objectData: WorkOrderSettingActions.LineDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const search = {
          statusId: 1,
          siteId: objectData.siteId,
        };
        const httpParams = new HttpParams()
          .set('s', JSON.stringify(search))
          .set('limit', '1000')
          .set('join', 'lineTypeName');

        return this.lineService.getLines(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<Partial<LineCRUDInterface>>) => {
            return of(new WorkOrderSettingActions.LineDataCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new WorkOrderSettingActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new WorkOrderSettingActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getActivities = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderSettingActions.ActionTypes.ACTIVITIES_DATA_LOADING),
      switchMap((objectData: WorkOrderSettingActions.ActivitiesDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const search = {
          active: 1,
          id: { $in: objectData.activityIds },
        };

        const httpParams = new HttpParams().set('s', JSON.stringify(search)).set('limit', '1000');

        return from(this.activityService.getData(httpParams)).pipe(
          switchMap((response: ActivityCRUDInterface[]) => {
            return of(new WorkOrderSettingActions.ActivitiesDataCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new WorkOrderSettingActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new WorkOrderSettingActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getTasks = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderSettingActions.ActionTypes.TASKS_DATA_LOADING),
      switchMap((objectData: WorkOrderSettingActions.TasksDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const search = {
          statusId: 1,
          activityId: objectData.activityId,
        };

        const httpParams = new HttpParams().set('s', JSON.stringify(search)).set('limit', '1000');

        return from(this.tasksService.getData(httpParams)).pipe(
          switchMap((response: TaskFilterCRUDInterface[]) => {
            return of(new WorkOrderSettingActions.TasksDataCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new WorkOrderSettingActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new WorkOrderSettingActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
