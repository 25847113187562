import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { combineLatest, Observable } from 'rxjs';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import {
  ITagDefinition,
  ITagDefinitionRow,
} from '../../../../view/settings/opc-tag-automation-settings/tag-definitions/tag-definitions.model';
import { LineStationService } from '../../../../shared/service/line-station/line-station.service';
import { LineCRUDInterface, SiteCRUDInterface } from '../../../../shared/component/filter/filter.class';
import { ResponseArrayInterface } from '../../../../shared/model/interface/generic-api-response.model';
import { LineStationGetOneCRUDDataInterface } from '../../../line-station/line-station.model';
import { exhaustMap, map } from 'rxjs/operators';
import { SiteService } from '../../../../shared/service/filter/site.service';
import { LineService } from '../../../../shared/service/line/line.service';

@Injectable({
  providedIn: 'root',
})
export class TagDefinitionService {
  private readonly TAG_DEFINITION_URL = `${this.baseUrl}/opc-tag-automation-settings/tag-definition`;

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly siteService: SiteService,
    private readonly lineService: LineService,
    private readonly stationService: LineStationService,
  ) {}

  public getData(params: HttpParams): Observable<GetManyResponseInterface<ITagDefinition>> {
    return this.http.get<GetManyResponseInterface<ITagDefinition>>(this.TAG_DEFINITION_URL, {
      params,
    });
  }

  public getOne(recordId: number, params?: HttpParams): Observable<BaseOneResponseInterface<ITagDefinition>> {
    return this.http.get<BaseOneResponseInterface<ITagDefinition>>(`${this.TAG_DEFINITION_URL}/${recordId}`, {
      params,
    });
  }
  public getOneWithDropdowns(
    recordId: number,
    params?: HttpParams,
  ): Observable<{
    tagDefinitionRecord: BaseOneResponseInterface<ITagDefinition>;
    sites: SiteCRUDInterface[];
    lines: GetManyResponseInterface<LineCRUDInterface>;
    stations: ResponseArrayInterface<LineStationGetOneCRUDDataInterface>;
  }> {
    return combineLatest([
      this.http.get<BaseOneResponseInterface<ITagDefinition>>(`${this.TAG_DEFINITION_URL}/${recordId}`, {
        params,
      }),
    ]).pipe(
      exhaustMap(([tagDefinitionRecord]) => {
        const siteHttpParams: HttpParams = new HttpParams().set('limit', 1000);

        const lineSearch = {
          statusId: 1,
          siteId: tagDefinitionRecord['data']['siteId'],
        };
        const lineHttpParams = new HttpParams()
          .set('s', JSON.stringify(lineSearch))
          .set('limit', '1000')
          .set('join', 'lineTypeName');

        return combineLatest([
          this.siteService.getData(siteHttpParams),
          this.lineService.getLines(lineHttpParams),
          this.stationService.getLineStationData(tagDefinitionRecord['data']['lineId']),
        ]).pipe(
          map(([siteRecords, lineRecords, stationsRecords]) => {
            return {
              tagDefinitionRecord: tagDefinitionRecord,
              sites: siteRecords,
              lines: lineRecords,
              stations: stationsRecords,
            };
          }),
        );
      }),
    );
  }

  public addOne(record: ITagDefinitionRow): Observable<BaseOneResponseInterface<ITagDefinition>> {
    return this.http.post<BaseOneResponseInterface<ITagDefinition>>(`${this.TAG_DEFINITION_URL}`, record);
  }

  public editOne(recordId: number, record: ITagDefinitionRow): Observable<BaseOneResponseInterface<ITagDefinition>> {
    return this.http.patch<BaseOneResponseInterface<ITagDefinition>>(`${this.TAG_DEFINITION_URL}/${recordId}`, record);
  }

  public deleteOnes(recordIds: number[]): Observable<BulkResponseDataInterface> {
    if (recordIds.length > 1) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        body: {
          tagDefinitions: recordIds,
        },
      };
      return this.http.delete<BulkResponseDataInterface>(`${this.TAG_DEFINITION_URL}/bulk/delete`, httpOptions);
    }
    return this.http.delete<BulkResponseDataInterface>(`${this.TAG_DEFINITION_URL}/${recordIds[0]}`);
  }
}
